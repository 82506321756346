<template>
  <div>
    <transition
      enter-active-class="animate__animated animate__fadeInUp animate__faster"
      leave-active-class="animate__animated animate__fadeOutUp animate__faster"
    >
      <div v-show="show" class="red b f6">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ValidationError',
  props: {
    show: Boolean
  }
}
</script>
