<template>
  <div class="offer-action-bar">
    <h1>Create New Opportunity for Collaboration</h1>
    <save-reminder :dirty="$v.$anyDirty"></save-reminder>
    <input
      placeholder="Opportunity for Collaboration name..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setOfferName($event.target.value)"
    />
    <label for="offer-name" class="b mv2 dib"
      >Opportunity for Collaboration Name</label
    >
    <validation-error :show="$v.name.$error">
      Name requires 4 or more characters.
    </validation-error>
    <label for="offer-topics" class="b mv2 dib"
      >Areas of Specialization (Choose 0 or more)</label
    >
    <multiselect
      id="offer-topics"
      v-model.trim="selectedTopics"
      :options="topicOptions"
      :multiple="true"
      :close-on-select="true"
      placeholder="Areas of Specialization"
      label="name"
      track-by="id"
      class="mb3"
      @input="setOfferTopicsToCreate(selectedTopics)"
    >
    </multiselect>
    <label for="offer-expiry" class="b mv2 dib"
      >Opportunity for Collaboration Expiration (in Weeks)</label
    >
    <p class="i">
      Opportunities will automatically expire at given number of weeks.
    </p>
    <div class="w-100">
      <input
        v-model="weeks"
        min="1"
        max="10"
        type="number"
        class="pa2 mr2 mb3 w3"
      />
      <span class="">Expires after {{ expiry.toLocaleDateString() }}</span>
    </div>
    <label for="offer-description" class="b mv2 dib"
      >Opportunity for Collaboration Description</label
    >
    <validation-error :show="$v.content.$invalid">
      Description cannot be empty
    </validation-error>
    <vue-editor
      v-model.trim="content"
      placeholder="Opportunity for Collaboration description..."
      class="offer-name-input"
      rows="7"
      cols="40"
      @input="setOfferDescToCreate(content)"
    ></vue-editor>
    <save-changes
      :invalid="$v.$invalid"
      @save-changes="saveChanges()"
    ></save-changes>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import ValidationError from '@/components/validationError'
import SaveReminder from '@/components/saveReminder'
import SaveChanges from '@/components/saveChanges'
import { topicOptions } from '@/mixins/options'
import quillEditor from '@/mixins/quillEditor'
import imageHandler from '@/mixins/imageHandler'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  components: {
    Multiselect,
    ValidationError,
    SaveReminder,
    SaveChanges
  },
  mixins: [topicOptions, quillEditor, imageHandler],

  data() {
    return {
      content: '',
      name: '',
      weeks: 3,
      expiry: '',
      selectedTopics: []
    }
  },
  computed: {
    ...mapState('offers', [
      'offerNameToCreate',
      'offerDescToCreate',
      'offerTopicsToCreate',
      'offerExpiryToCreate',
      'offerCreationPending'
    ]),
    ...mapState('authentication', ['user'])
  },
  watch: {
    weeks: {
      immediate: true,
      handler() {
        const week = 1000 * 60 * 60 * 24 * 7
        this.expiry = new Date(new Date().getTime() + week * this.weeks)
        this.setOfferExpiryToCreate(this.expiry)
      }
    }
  },
  methods: {
    ...mapMutations('offers', [
      'setOfferNameToCreate',
      'setOfferOwner',
      'setOfferDescToCreate',
      'setOfferTopicsToCreate',
      'setOfferExpiryToCreate'
    ]),
    ...mapActions('offers', ['triggerAddOfferAction']),
    setOfferName(val) {
      this.name = val
      this.setOfferNameToCreate(val)
      this.$v.name.$touch()
    },
    saveChanges() {
      this.setOfferOwner(this.user.id)
      this.triggerAddOfferAction()
      this.$router.push({
        name: 'offers'
      })
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    content: {
      required
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>
