export default {
  universities: null,
  universityPictureToEdit: '',
  universityPictureToCreate: '',
  universityNameToCreate: '',
  universityDescToCreate: '',
  universityCityToCreate: '',
  universityCountryToCreate: '',
  universityRegionToCreate: '',
  universityContactToCreate: '',
  universityWebsiteToCreate: '',
  universityTopicsToCreate: '',
  universityNameToEdit: '',
  universityDescToEdit: '',
  universityCityToEdit: '',
  universityCountryToEdit: '',
  universityRegionToEdit: '',
  universityContactToEdit: '',
  universityWebsiteToEdit: '',
  universityDeletionPending: [],
  universityCreationPending: false,
  universityEditPending: []
}
