<template>
  <div class="mw8 center">
    <edit-user-permissions
      v-if="currentUser"
      :user="currentUser"
    ></edit-user-permissions>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditUserPermissions from '@/components/user/EditUserPermissions'

export default {
  components: { EditUserPermissions },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('users', ['getUserById']),
    currentUser() {
      return this.getUserById(this.id)
    }
  }
}
</script>
