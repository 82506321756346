import Users from '@/views/user/Users'
import User from '@/views/user/User'
import AddUser from '@/views/user/AddUser'
import EditUser from '@/views/user/EditUser'
import EditUserPermissions from '@/views/user/EditUserPermissions'
import { isAdmin, isChair, isMemberOwner } from '@/router/helpers'

const routes = [
  {
    path: '/members',
    name: 'members',
    component: Users
  },
  {
    path: '/members/:id',
    name: 'member',
    props: true,
    component: User
  },
  {
    path: '/members/edit/:id',
    name: 'edit-member',
    props: true,
    component: EditUser,
    beforeEnter: (to, from, next) => {
      if (isChair() || isAdmin() || isMemberOwner(to)) return next()
      return next('/unauthorized')
    }
  },
  {
    path: '/members/edit-permissions/:id',
    name: 'edit-member-permissions',
    props: true,
    component: EditUserPermissions,
    meta: {
      reqAdmin: true
    }
  },
  {
    path: '/members/add',
    name: 'add-member',
    props: true,
    component: AddUser
  }
]

export default routes
