<template>
  <div class="center mw8">
    <h1 class="tc">{{ appTitle }}</h1>

    <img alt="IAJES Logo" class="logo w5 db center" src="@/assets/logo.png" />
    <div class="flex justify-center flex-wrap pt3">
      <router-link
        v-for="l in links"
        v-show="showLink(l)"
        :key="l.route"
        class="pa2 db no-underline black ba bw1 b shadow-1 b--gray br1 grow bgh-light-grey ma2"
        :class="{ 'w-30': !small, 'w-100': small }"
        :to="l.route"
      >
        <h2 class="b mt0">{{ l.name }}</h2>
        <p class="i mid-gray">
          {{ l.desc }}
        </p>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import resizeMixin from '@/mixins/resizeMixin'
import navLinks from '@/assets/navLinks'

export default {
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  },
  mixins: [resizeMixin],
  data() {
    return {
      currentUser: null,
      links: navLinks
    }
  },
  computed: {
    ...mapState('app', ['appTitle']),
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapState('authentication', ['user']),
    ...mapGetters('users', ['getUserById']),
    ...mapGetters('admins', ['getAdminById']),
    ...mapState('admins', ['admins']),
    showNav() {
      return (
        this.isUserLoggedIn &&
        !this.$route.name.includes('baduser') &&
        !this.$route.name.includes('login')
      )
    }
  },
  methods: {
    showLink(l) {
      if (this.showNav) {
        if (!l.adminOnly || this.getAdminById(this.user.id)) {
          return true
        }
      }

      return false
    },
    dismissNotification(idx) {
      this.currentUser.notifications.splice(idx, 1)
      this.editUser(this.currentUser)
    }
  }
}
</script>

<style lang="scss" scoped></style>
