<template>
  <div class="request-action-bar">
    <save-reminder :dirty="dirty"></save-reminder>
    <input
      v-model.trim="localRequest.name"
      placeholder="request name..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setRequestNameToEdit($event.target.value)"
    />
    <vue-editor
      v-model.trim="localRequest.description"
      :editor-options="editorSettings"
      placeholder="request description..."
      class="request-name-input"
      rows="7"
      cols="40"
      @input="setRequestDescToEdit(localRequest.description)"
    ></vue-editor>
    <div
      :class="{ disabled: requestEditPending }"
      class="pa2 b-grey bw1 ba br1 dim pointer dib mv3"
      @click="saveChanges()"
    >
      Save Changes
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { isEqual } from 'lodash'
import SaveReminder from '@/components/saveReminder'
import quillEditor from '@/mixins/quillEditor'

export default {
  components: {
    SaveReminder
  },
  mixins: [quillEditor],
  props: {
    request: Object
  },
  data() {
    return {
      content: '',
      localRequest: {}
    }
  },
  computed: {
    ...mapState('requests', [
      'requestNameToEdit',
      'requestDescToEdit',
      'requestEditPending'
    ]),
    dirty() {
      return !isEqual(this.request, this.localRequest)
    }
  },
  created() {
    this.setRequestNameToEdit(this.request.name)
    this.setRequestDescToEdit(this.request.description)
    this.content = this.request.description
    Object.assign(this.localRequest, this.request)
  },

  methods: {
    ...mapMutations('requests', ['setRequestNameToEdit']),
    ...mapMutations('requests', ['setRequestDescToEdit']),
    ...mapActions('requests', ['triggerEditRequestAction', 'editRequest']),

    saveChanges() {
      this.editRequest(this.localRequest)
      this.$router.push({
        name: 'request',
        params: { id: this.request.id }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
