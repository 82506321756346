<template>
  <div class="mw8 center">
    <edit-topic v-if="currentTopic" :topic="currentTopic"></edit-topic>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditTopic from '@/components/topic/EditTopic'

export default {
  components: { EditTopic },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('topics', ['getTopicById']),
    currentTopic() {
      return this.getTopicById(this.id)
    }
  }
}
</script>
