export default {
  resources: null,
  resourceNameToCreate: '',
  resourceLanguageToCreate: '',
  resourceDescToCreate: '',
  resourceTaskforceToCreate: '',
  resourceAudienceToCreate: '',
  resourceTopicsToCreate: '',
  resourceTypeToCreate: '',
  resourceNameToEdit: '',
  resourceLanguageToEdit: '',
  resourceDescToEdit: '',
  resourceTaskforceToEdit: '',
  resourceAudienceToEdit: '',
  resourceTopicsToEdit: '',
  resourceTypeToEdit: '',
  resourceDeletionPending: [],
  resourceCreationPending: false,
  resourceEditPending: []
}
