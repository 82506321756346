import Requests from '@/views/request/Requests'
import Request from '@/views/request/Request'
import EditRequest from '@/views/request/EditRequest'

const routes = [
  {
    path: '/requests',
    name: 'requests',
    component: Requests,
    meta: {
      reqAdmin: true
    }
  },
  {
    path: '/requests/:id',
    name: 'request',
    props: true,
    component: Request,
    meta: {
      reqAdmin: true
    }
  },
  {
    path: '/requests/edit/:id',
    name: 'edit-request',
    props: true,
    component: EditRequest,
    meta: {
      reqAdmin: true
    }
  }
]

export default routes
