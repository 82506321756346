<template>
  <div class="flex flex-wrap justify-between">
    <p v-if="topics === null" class="i">Loading...</p>
    <p v-if="topics && !topics.length" class="i">
      You don't have any areas of specialization yet
    </p>
    <div class="mb0 relative w-100">
      <i
        class="las la-search absolute left-1 black z-max mt3 pt1 f3 dn db-ns"
      ></i>
      <input
        id="topic-search"
        v-model.trim="query"
        type="text"
        placeholder="Search all topics"
        class="1 b--mid-gray pl5 pa3 w-100 f3"
      />
      <i
        v-show="query.length > 0"
        class="las la-backspace absolute right-1 highlight pointer dim z-max mt3 pt1 f3 dn db-ns"
        @click="clearQuery()"
      ></i>
      <label for="topic-search"></label>
    </div>
    <filter-bar
      :current="filteredTopics.length"
      :total="topics.length"
      name="area"
      :an="true"
    ></filter-bar>
    <topic-item
      v-for="(topic, index) in filteredTopics"
      :key="topic.id"
      class="w-40 ph2"
      :query="query"
      :index="index"
      :is-topic-deletion-pending="isTopicDeletionPending(topic.id)"
      :disable-actions="!networkOnLine"
      :data="topic"
      @deleteTopic="deleteTopic"
    ></topic-item>
    <div v-if="filteredTopics.length === 0" class="mid-gray i center tc f3">
      <i class="las la-exclamation-triangle"></i> No Search Results Found
    </div>
  </div>
</template>

<script>
import TopicItem from '@/components/topic/TopicItem'
import { mapState, mapActions, mapGetters } from 'vuex'
import elasticlunr from 'elasticlunr'
import FilterBar from '@/components/filterBar'

export default {
  components: { TopicItem, FilterBar },
  data() {
    return {
      query: '',
      searchIndex: null,
      searchRes: []
    }
  },
  computed: {
    ...mapGetters('topics', ['isTopicDeletionPending']),
    ...mapState('topics', ['topics']),
    ...mapState('app', ['networkOnLine']),
    filteredTopics() {
      if (this.topics) {
        const arr = this.topics.filter(p => {
          return this.visible(p)
        })

        return arr
      }

      return []
    }
  },
  watch: {
    topics() {
      this.buildIndex()
    },
    query() {
      if (this.searchIndex) {
        this.searchRes = this.searchIndex.search(this.query, { expand: true })
      }
    }
  },
  created() {
    this.buildIndex()
  },
  methods: {
    ...mapActions('topics', ['deleteTopic']),
    buildIndex() {
      if (this.topics) {
        this.searchIndex = elasticlunr(function f() {
          this.addField('name')
          this.addField('description')
          this.setRef('id')
        })

        this.searchIndex.saveDocument(false)
        const i = this.topics.map(x => {
          return {
            name: x.name,
            description: x.description,
            id: x.id
          }
        })

        i.forEach(x => {
          this.searchIndex.addDoc(x)
        })
      }
    },
    clearQuery() {
      this.query = ''
    },
    visible(t) {
      let inSearch = false

      if (this.query.length < 4) {
        inSearch = true
      } else {
        this.searchRes.forEach(r => {
          if (r.ref === t.id) {
            inSearch = true
          }
        })
      }

      return inSearch
    }
  }
}
</script>

<style lang="scss" scoped></style>
