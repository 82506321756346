<template>
  <div class="mw8 center">
    <div class="flex justify-between flex-wrap mt3">
      <div
        class="flex flex-column pr4 pb3"
        :class="{ 'w-30': !small, 'w-100': small }"
      >
        <div>
          <img
            v-if="university.picture"
            class="w-100"
            :src="
              'https://res.cloudinary.com/iajesdb/image/upload/f_auto,q_auto:good,c_scale,w_400/' +
                university.picture
            "
            :alt="university.name + ' Logo'"
            :title="university.name"
          />
        </div>
        <h1 class="">{{ university.name }}</h1>
        <h2 class="f3 bw1 bb b-grey">
          {{ university.city }}, {{ university.country }}
        </h2>
        <h3 class="f4">
          {{ region.name }} <br />
          <span class="i f5"> {{ region.desc }}</span>
        </h3>
        <div>
          <span class="b mr3">Website:</span>
          <a :href="university.website" target="_blank">{{
            university.website
          }}</a>
        </div>
        <div v-if="university.contact">
          <span class="b mr3">Contact:</span>
          <user-item-small
            v-if="university.contact"
            class="db"
            :data="getUserById(university.contact)"
          ></user-item-small>
        </div>
        <div class="mt4">
          <router-link
            v-if="networkOnLine && (isTaskforceChairOrAbove || isContact)"
            class="bw1 ba br-1 b--blue link white bg-navy pa2 dim pointer dib"
            :to="{ name: 'edit-university', params: { id: university.id } }"
          >
            <i class="las la-edit"></i>
            {{
              isUniversityEditPending(university.id)
                ? 'edit in progress...'
                : 'edit'
            }}
          </router-link>
          <div
            v-if="networkOnLine && (isTaskforceChairOrAbove || isContact)"
            class="bw1 ba br-1 b--red link white bg-dark-red pa2 dim pointer dib ml2"
            @click="saveChanges(university.id)"
          >
            <i class="las la-trash"></i>
            {{
              isUniversityDeletionPending(university.id)
                ? 'delete in progress...'
                : 'delete'
            }}
          </div>
        </div>
      </div>
      <div :class="{ 'w-60': !small, 'w-100': small }">
        <user-list-export :users="filteredUsers"></user-list-export>
        <div v-if="filteredUsers.length > 0">
          <div class="b mr3">
            Associated Members:
            <span v-if="filteredUsers.length === 0" class="pl2 i"
              >None at this time</span
            >
          </div>
          <div class="flex flex-wrap">
            <user-item-small
              v-for="(user, index) in filteredUsers"
              :key="user.id"
              class="db"
              :class="{ 'w-third': !small, 'w-100': small }"
              :index="index"
              :data="user"
            ></user-item-small>
          </div>
        </div>
        <!-- eslint-disable -->
        <div v-html="highlightedDescription" class="ws-normal"></div>
        <!-- eslint-enable -->
        <div class="b mv2">
          Areas of Specialization Covered by Degree Programs
        </div>
        <div v-if="sortedTopics.length === 0" class="mid-gray i center tc f3">
          <i class="las la-exclamation-triangle"></i> No areas of specialization
          have been added to this university
        </div>
        <table v-if="sortedTopics.length > 0" class="w-100 center">
          <thead>
            <tr class="">
              <th class="tc"></th>
              <th class="relative tc rotate">
                <div class="absolute bottom-0 left-0 tl w-100">
                  <span
                    class="absolute left-0 bottom-0 bw1 bb b--moon-gray lh-copy"
                    >Undergraduate</span
                  >
                </div>
              </th>
              <th class="relative tc rotate" style="min-width: 18px">
                <div class="absolute bottom-0 left-0 tl w-100">
                  <span
                    class="absolute left-0 bottom-0 bw1 bb b--moon-gray lh-copy"
                    >Graduate</span
                  >
                </div>
              </th>
              <th class="relative tc rotate" style="min-width: 18px">
                <div class="absolute bottom-0 left-0 tl w-100">
                  <span
                    class="absolute left-0 bottom-0 bw1 bb b--moon-gray lh-copy"
                    >Doctorate</span
                  >
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="key in sortedTopics"
              :key="key"
              class="bw1 bt b--moon-gray bgh-grey bg-bg-animate"
            >
              <td class="">
                <router-link
                  class="pa1 no-underline navy dim flex flex-nowrap"
                  :to="{ name: 'topic', params: { id: key } }"
                  :v-if="getTopicById(key)"
                >
                  <div class="">
                    {{ getTopicById(key).name }}
                  </div>
                  <i class="las la-angle-right db f4 b"></i>
                </router-link>
              </td>
              <td class="tc bw1 br b--moon-gray" style="padding: 10px 5px;">
                <span
                  v-if="university.topics[key].includes('undergraduate')"
                  class="dark-green b"
                  >&#10004;</span
                >
              </td>
              <td class="tc bw1 br b--moon-gray" style="padding: 10px 5px;">
                <span
                  v-if="university.topics[key].includes('graduate')"
                  class="dark-green b"
                  >&#10004;</span
                >
              </td>

              <td class="tc bw1 br b--moon-gray" style="padding: 10px 5px;">
                <span
                  v-if="university.topics[key].includes('doctorate')"
                  class="dark-green b"
                  >&#10004;</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { isNil } from 'lodash'
import UserItemSmall from '@/components/user/UserItemSmall'
import Regions from '@/assets/regions'
import csReplace from 'preserve-case'
import resizeMixin from '@/mixins/resizeMixin'
import adminHelper from '@/mixins/adminHelper'
import UserListExport from '@/components/user/UserListExport'

export default {
  components: { UserItemSmall, UserListExport },
  mixins: [resizeMixin, adminHelper],
  props: {
    university: Object
  },
  computed: {
    ...mapGetters('universities', [
      'isUniversityDeletionPending',
      'isUniversityEditPending'
    ]),
    ...mapGetters('users', ['getUserById']),
    ...mapGetters('topics', ['getTopicById']),

    ...mapState('universities', ['universities']),
    ...mapState('users', ['users']),
    ...mapState('app', ['networkOnLine']),
    ...mapState('authentication', ['user']),
    isContact() {
      return this.university.contact === this.user.id
    },
    sortedTopics() {
      const tops = Object.keys(this.university.topics)
      const sortedTopics = tops.sort((a, b) => {
        if (this.getTopicById(a).name > this.getTopicById(b).name) return 1
        if (this.getTopicById(a).name < this.getTopicById(b).name) return -1
        return 0
      })
      return sortedTopics
    },
    filteredUsers() {
      const { id } = this.university
      const arr = Object.values(this.users)
      return arr.filter(u => {
        if (!isNil(u.university)) {
          return u.university.id === id
        }

        return false
      })
    },
    region() {
      const { region: id } = this.university
      return Regions.find(r => r.id === id)
    },
    query() {
      return this.$route.params.q || ''
    },
    highlightedDescription() {
      if (this.query.length < 4) {
        return this.university.description
      }

      let hDesc = csReplace.all(
        this.university.description,
        this.query,
        `<::${this.query}::>`
      )

      hDesc = hDesc.replaceAll('<::', `<span class='bg-yellow'>`)

      hDesc = hDesc.replaceAll('::>', '</span>')

      return hDesc
    }
  },
  methods: {
    ...mapActions('universities', ['deleteUniversity']),
    saveChanges(id) {
      this.$dialog
        .confirm('Are you sure? Deleted records cannot be recovered.')
        .then(() => {
          this.deleteUniversity(id)
          this.$router.push({
            name: 'universities'
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped></style>
