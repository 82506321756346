<template>
  <div class="mw8 center">
    <h1 class="users-page-title">Registered Members</h1>
    <user-list class="user-list"></user-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UserList from '@/components/user/UserList'

export default {
  components: { UserList },
  computed: mapState('app', ['networkOnLine'])
}
</script>

<style lang="scss" scoped></style>
