export default {
  projects: null,
  projectNameToCreate: '',
  projectDescToCreate: '',
  projectTaskforcesToCreate: '',
  projectLeadsToCreate: '',
  projectTopicsToCreate: '',
  projectStatusToCreate: '',
  projectNameToEdit: '',
  projectDescToEdit: '',
  projectTaskforcesToEdit: '',
  projectLeadsToEdit: '',
  projectTopicsToEdit: '',
  projectStatusToEdit: '',
  projectDeletionPending: [],
  projectCreationPending: false,
  projectEditPending: []
}
