export default {
  /* Offer input name */
  setOfferNameToCreate: (state, offerNameToCreate) =>
    (state.offerNameToCreate = offerNameToCreate),
  setOfferOwner: (state, offerOwner) => (state.offerOwner = offerOwner),
  setOfferDescToCreate: (state, offerDescToCreate) =>
    (state.offerDescToCreate = offerDescToCreate),
  setOfferTopicsToCreate: (state, offerTopicsToCreate) =>
    (state.offerTopicsToCreate = offerTopicsToCreate),
  setOfferExpiryToCreate: (state, offerExpiryToCreate) =>
    (state.offerExpiryToCreate = offerExpiryToCreate),
  setOfferNameToEdit: (state, offerNameToEdit) =>
    (state.offerNameToEdit = offerNameToEdit),
  setOfferDescToEdit: (state, offerDescToEdit) =>
    (state.offerDescToEdit = offerDescToEdit),
  setOfferTopicsToEdit: (state, offerTopicsToEdit) =>
    (state.offerTopicsToEdit = offerTopicsToEdit),
  setOfferExpiryToEdit: (state, offerExpiryToEdit) =>
    (state.offerExpiryToEdit = offerExpiryToEdit),

  /* Offers */
  setOffers: (state, offers) => (state.offers = offers),
  addOffer: (state, offer) => state.offers.push(offer),
  removeOfferById: (state, offerId) => {
    const index = state.offers.findIndex(offer => offer.id === offerId)
    state.offers.splice(index, 1)
  },

  /* Offers deletion */
  addOfferDeletionPending: (state, offerId) =>
    state.offerDeletionPending.push(offerId),
  removeOfferDeletionPending: (state, offerId) => {
    const index = state.offers.findIndex(offer => offer.id === offerId)
    state.offerDeletionPending.splice(index, 1)
  },

  /* Offers edit */
  addOfferEditPending: (state, offerId) => state.offerEditPending.push(offerId),
  removeOfferEditPending: (state, offerId) => {
    const index = state.offers.findIndex(offer => offer.id === offerId)
    state.offerEditPending.splice(index, 1)
  }
}
