<template>
  <div class="mw8 center">
    <edit-taskforce
      v-if="currentTaskforce"
      :taskforce="currentTaskforce"
    ></edit-taskforce>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditTaskforce from '@/components/taskforce/EditTaskforce'

export default {
  components: { EditTaskforce },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('taskforces', ['getTaskforceById']),
    currentTaskforce() {
      return this.getTaskforceById(this.id)
    }
  }
}
</script>
