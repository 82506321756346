<template>
  <div class="page-wrapper">
    <resource-detail
      v-if="currentResource"
      :resource="currentResource"
    ></resource-detail>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ResourceDetail from '@/components/resource/ResourceDetail'

export default {
  components: { ResourceDetail },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('resources', ['getResourceById']),
    currentResource() {
      return this.getResourceById(this.id)
    }
  }
}
</script>
