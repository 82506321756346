<template>
  <div class="topic-action-bar">
    <save-reminder :dirty="dirty"></save-reminder>
    <h1>Edit Area of Specialization</h1>
    <label for="topic-name" class="b mv2 dib"
      >Area of Specialization Name</label
    >
    <validation-error :show="$v.localTopic.name.$invalid">
      Name requires 4 or more characters.
    </validation-error>
    <input
      id="topic-name"
      v-model.trim="localTopic.name"
      placeholder="topic name..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setTopicNameToEdit($event.target.value)"
    />
    <label for="topic-description" class="b mv2 dib"
      >Area of Specialization Description</label
    >
    <validation-error :show="$v.localTopic.description.$invalid">
      Description cannot be empty
    </validation-error>
    <vue-editor
      id="topic-description"
      v-model.trim="localTopic.description"
      :editor-options="editorSettings"
      placeholder="Area of Specialization description..."
      class="topic-name-input"
      rows="7"
      cols="40"
      use-custom-image-handler
      @image-added="handleImageAdded"
      @input="setTopicDescToEdit(localTopic.description)"
    ></vue-editor>
    <save-changes
      :invalid="$v.$invalid"
      @save-changes="saveChanges()"
    ></save-changes>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import imageHandler from '@/mixins/imageHandler'
import { isEqual } from 'lodash'
import SaveReminder from '@/components/saveReminder'
import ValidationError from '@/components/validationError'
import SaveChanges from '@/components/saveChanges'
import quillEditor from '@/mixins/quillEditor'

export default {
  components: {
    SaveReminder,
    ValidationError,
    SaveChanges
  },
  mixins: [imageHandler, quillEditor],
  props: {
    topic: Object
  },
  data() {
    return {
      content: '',
      localTopic: {
        name: '',
        description: ''
      }
    }
  },
  computed: {
    ...mapState('topics', [
      'topicNameToEdit',
      'topicDescToEdit',
      'topicEditPending'
    ]),
    dirty() {
      return !isEqual(this.topic, this.localTopic)
    }
  },
  created() {
    this.setTopicNameToEdit(this.topic.name)
    this.setTopicDescToEdit(this.topic.description)
    this.content = this.topic.description
    Object.assign(this.localTopic, this.topic)
  },

  methods: {
    ...mapMutations('topics', ['setTopicNameToEdit', 'setTopicDescToEdit']),
    ...mapActions('topics', ['triggerEditTopicAction', 'editTopic']),

    saveChanges() {
      this.editTopic(this.localTopic)
      this.$router.push({
        name: 'topic',
        params: { id: this.topic.id }
      })
    }
  },
  validations: {
    localTopic: {
      name: {
        required,
        minLength: minLength(4)
      },
      description: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
