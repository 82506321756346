<template>
  <router-link
    class="db mv2 black no-underline"
    :to="{ name: 'member', params: { id: data.id, q: query } }"
  >
    <div class="flex flex-column justify-between">
      <div class="mr2 shadow-1 bg-white hover-bg-navy hover-white bg-animate">
        <div class="bb bw1 b--navy w-100">
          <img
            v-if="data.picture"
            class="db w-100 overflow-hidden"
            :src="
              'https://res.cloudinary.com/iajesdb/image/upload/ar_1,c_crop,f_auto,g_face,q_auto:good,z_0.7/c_scale,w_248,ar_1/' +
                data.picture
            "
            :alt="data.name + ' - ' + 'profile picture'"
            :title="data.name + ' - ' + data.title"
          />
          <img
            v-if="!data.picture"
            class="db"
            src="/img/default.png"
            :alt="data.name + ' - ' + 'profile picture'"
            :title="data.name + ' - ' + data.title"
          />
        </div>
        <div class="tc pt3 pb2 h3">
          {{ data.name }}
          <span v-if="uni" class="i"
            ><br />
            {{ data.university.name }}</span
          >
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    data: Object,
    index: Number,
    query: String,
    uni: Boolean
  }
}
</script>

<style lang="scss" scoped></style>
