<template>
  <div class="mw8 center">
    <edit-user v-if="currentUser" :user="currentUser"></edit-user>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditUser from '@/components/user/EditUser'

export default {
  components: { EditUser },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('users', ['getUserById']),
    currentUser() {
      return this.getUserById(this.id)
    }
  }
}
</script>
