<template>
  <div class="mw7 center">
    <add-offer></add-offer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddOffer from '@/components/offer/AddOffer'

export default {
  components: { AddOffer },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('offers', ['getOfferById']),
    currentOffer() {
      return this.getOfferById(this.id)
    }
  }
}
</script>
