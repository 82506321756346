import Topics from '@/views/topic/Topics'
import Topic from '@/views/topic/Topic'
import AddTopic from '@/views/topic/AddTopic'
import EditTopic from '@/views/topic/EditTopic'

const routes = [
  {
    path: '/topics',
    name: 'topics',
    component: Topics
  },
  {
    path: '/topics/:id',
    name: 'topic',
    props: true,
    component: Topic
  },
  {
    path: '/topics/edit/:id',
    name: 'edit-topic',
    props: true,
    component: EditTopic,
    meta: {
      reqTaskforceChair: true
    }
  },
  {
    path: '/topics/add',
    name: 'add-topic',
    props: true,
    component: AddTopic,
    meta: {
      reqTaskforceChair: true
    }
  }
]

export default routes
