<template>
  <div class="page-wrapper">
    <offer-detail v-if="currentOffer" :offer="currentOffer"></offer-detail>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OfferDetail from '@/components/offer/OfferDetail'

export default {
  components: { OfferDetail },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('offers', ['getOfferById']),
    currentOffer() {
      return this.getOfferById(this.id)
    }
  }
}
</script>
