export default {
  /* Topic input name */
  setTopicNameToCreate: (state, topicNameToCreate) =>
    (state.topicNameToCreate = topicNameToCreate),
  setTopicDescToCreate: (state, topicDescToCreate) =>
    (state.topicDescToCreate = topicDescToCreate),
  setTopicNameToEdit: (state, topicNameToEdit) =>
    (state.topicNameToEdit = topicNameToEdit),
  setTopicDescToEdit: (state, topicDescToEdit) =>
    (state.topicDescToEdit = topicDescToEdit),

  /* Topics */
  setTopics: (state, topics) => (state.topics = topics),
  addTopic: (state, topic) => state.topics.push(topic),
  removeTopicById: (state, topicId) => {
    const index = state.topics.findIndex(topic => topic.id === topicId)
    state.topics.splice(index, 1)
  },

  /* Topics deletion */
  addTopicDeletionPending: (state, topicId) =>
    state.topicDeletionPending.push(topicId),
  removeTopicDeletionPending: (state, topicId) => {
    const index = state.DeletionPending.indexOf(topicId)
    state.topicDeletionPending.splice(index, 1)
  },

  /* Topics edit */
  addTopicEditPending: (state, topicId) => state.topicEditPending.push(topicId),
  removeTopicEditPending: (state, topicId) => {
    const index = state.topicEditPending.indexOf(topicId)
    state.topicEditPending.splice(index, 1)
  }
}
