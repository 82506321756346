import { find } from 'lodash'

export default {
  /**
   * Check if a topic has deletion pending
   */
  isTopicDeletionPending: state => topicId =>
    state.topicDeletionPending.includes(topicId),

  isTopicEditPending: state => topicId =>
    state.topicEditPending.includes(topicId),

  /**
   * Get topic by id
   */
  getTopicById: state => topicId => {
    const res = find(state.topics, topic => topic.id === topicId)

    if (res) {
      return res
    }

    return false
  }
}
