<template>
  <router-link
    class="flex justify-between w-100 mv2 pa2 f4 bgh-light-grey pointer db black no-underline"
    :to="{ name: 'project', params: { id: data.id, q: query } }"
  >
    <div class="">
      {{ data.name }} -
      <span class="mid-gray i"> {{ capitalizeStatus }}</span> -
      <span class="mid-gray i"> {{ formatTs }}</span>
    </div>
    <i class="las la-angle-right db f4 b dark-blue"></i>
  </router-link>
</template>

<script>
export default {
  props: {
    data: Object,
    index: Number,
    query: String
  },
  computed: {
    formatTs() {
      const t = this.data.createTimestamp
      const y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(t)
      const m = new Intl.DateTimeFormat('en', { month: 'long' }).format(t)
      return `${m} ${y}`
    },
    capitalizeStatus() {
      const s = this.data.status
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
  }
}
</script>

<style lang="scss" scoped></style>
