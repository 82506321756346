import { find } from 'lodash'

export default {
  /**
   * Check if a project has deletion pending
   */
  isProjectDeletionPending: state => projectId =>
    state.projectDeletionPending.includes(projectId),

  isProjectEditPending: state => projectId =>
    state.projectEditPending.includes(projectId),

  /**
   * Get project by id
   */
  getProjectById: state => projectId => {
    const res = find(state.projects, project => project.id === projectId)

    if (res) {
      return res
    }

    return false
  }
}
