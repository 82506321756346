<template>
  <div>
    <p v-if="requests === null" class="i">Loading...</p>
    <p v-if="requests && !requests.length" class="i">
      You don't have any requests yet
    </p>
    <request-item
      v-for="(request, index) in requests"
      :key="request.id"
      class="request-row"
      :index="index"
      :is-request-deletion-pending="isRequestDeletionPending(request.id)"
      :disable-actions="!networkOnLine"
      :data="request"
      @deleteRequest="deleteRequest"
    ></request-item>
  </div>
</template>

<script>
import RequestItem from '@/components/request/RequestItem'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: { RequestItem },
  computed: {
    ...mapGetters('requests', ['isRequestDeletionPending']),
    ...mapState('requests', ['requests']),
    ...mapState('app', ['networkOnLine'])
  },
  methods: mapActions('requests', ['deleteRequest'])
}
</script>

<style lang="scss" scoped></style>
