<template>
  <div class="university-action-bar">
    <h1>Create New University</h1>
    <save-reminder :dirty="$v.$anyDirty"></save-reminder>
    <label for="university-name" class="b mv2 dib">University Name</label>
    <validation-error :show="$v.name.$error">
      Name requires 4 or more characters.
    </validation-error>
    <input
      id="university-name"
      placeholder="university name..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setUniversityName($event.target.value)"
    />
    <cloudinary-upload
      :src="localUniversityPicture"
      descr="High quality PNG files with a transparent background work best."
      name="University"
      @uploaded="setUniversityPicture($event)"
    ></cloudinary-upload>
    <label for="university-city" class="b mv2 db">University City</label>
    <validation-error :show="$v.city.$error">
      City requires 4 or more characters.
    </validation-error>
    <input
      id="university-city"
      placeholder="university city..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setUniversityCity($event.target.value)"
    />
    <label for="university-country" class="b mv2 dib">University Country</label>
    <validation-error :show="$v.country.$error">
      Country requires 3 or more characters.
    </validation-error>
    <input
      id="university-country"
      placeholder="university country..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setUniversityCountry($event.target.value)"
    />
    <label for="university-contact" class="b mv2 dib">University Contact</label>
    <multiselect
      id="university-contact"
      v-model.trim="selectedContact"
      :options="userOptions"
      :multiple="false"
      :close-on-select="true"
      placeholder="Contact"
      label="name"
      track-by="id"
      class="mb3"
      @input="setUniversityContactToCreate(selectedContact)"
    ></multiselect>
    <label for="university-region" class="b mv2 db">University Region</label>
    <validation-error :show="$v.region.$invalid">
      Region is required.
    </validation-error>
    <select
      id="university-region"
      v-model.trim="region"
      class="mv3"
      @change="setUniversityRegion(region)"
    >
      <option value="">--Please choose an option--</option>
      <option v-for="r in regions" :key="r.id" :value="r.id"
        >{{ r.name }} - {{ r.desc }}</option
      >
    </select>
    <label for="university-website" class="b mv2 db">University Website</label>
    <validation-error :show="$v.website.$error">
      Website must be a valid URL starting with "http://" or "https://".
    </validation-error>
    <input
      id="university-website"
      placeholder="university website..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setUniversityWebsite($event.target.value)"
    />
    <label for="university-description" class="b mv2 dib"
      >University Description</label
    >
    <validation-error :show="$v.content.$invalid">
      Description cannot be empty
    </validation-error>
    <vue-editor
      id="university-description"
      v-model.trim="content"
      :editor-options="editorSettings"
      placeholder="university description..."
      class="university-name-input"
      rows="7"
      cols="40"
      @input="setUniversityDescToCreate(content)"
    ></vue-editor>
    <div class="b mv2">Areas of Specialization Covered by Degree Programs</div>
    <validation-error :show="$v.universityTopics.$invalid">
      Areas of Specialization cannot be empty
    </validation-error>
    <table class="w-100">
      <thead>
        <tr class="">
          <th class="tc"></th>
          <th class="relative tc rotate">
            <div class="absolute bottom-0 left-0 tl w-100">
              <span class="absolute left-0 bottom-0 bw1 bb b--moon-gray lh-copy"
                >Undergraduate</span
              >
            </div>
          </th>
          <th class="relative tc  rotate">
            <div class="absolute bottom-0 left-0 tl w-100">
              <span class="pabsolute left-0 bottom-0 w1 bb b--moon-gray lh-copy"
                >Graduate</span
              >
            </div>
          </th>
          <th class="relative tc  rotate">
            <div class="absolute bottom-0 left-0 tl w-100">
              <span class="pabsolute left-0 bottom-0 w1 bb b--moon-gray lh-copy"
                >Doctorate</span
              >
            </div>
          </th>
          <th class="tc"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="topic in topics"
          :key="topic.id"
          class="bw1 bt b--moon-gray bgh-grey bg-bg-animate"
        >
          <td class="">{{ topic.name }}</td>
          <td class="tc bw1 br b--moon-gray" style="padding: 10px 5px;">
            <input
              :id="topic.id + '-u'"
              type="checkbox"
              :name="topic.id + '-u'"
              value="undergraduate"
              :checked="isChecked(topic.id, 'undergraduate')"
              @input="toggleTopic(topic.id, 'undergraduate')"
            />
          </td>
          <td class="tc bw1 br b--moon-gray" style="padding: 10px 5px;">
            <input
              :id="topic.id + '-g'"
              type="checkbox"
              :name="topic.id + '-g'"
              value="graduate"
              :checked="isChecked(topic.id, 'graduate')"
              @input="toggleTopic(topic.id, 'graduate')"
            />
          </td>
          <td class="tc bw1 br b--moon-gray" style="padding: 10px 5px;">
            <input
              :id="topic.id + '-d'"
              type="checkbox"
              :name="topic.id + '-d'"
              value="doctorate"
              :checked="isChecked(topic.id, 'doctorate')"
              @input="toggleTopic(topic.id, 'doctorate')"
            />
          </td>
          <td class="tc">
            <a class="mr2 underline pointer" @click="checkAllTopics(topic.id)"
              >Check All</a
            >
            <a class="mr2 underline pointer" @click="uncheckAllTopics(topic.id)"
              >Uncheck All</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <save-changes
      :invalid="$v.$invalid"
      @save-changes="saveChanges()"
    ></save-changes>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { required, minLength, url, helpers } from 'vuelidate/lib/validators'
import CloudinaryUpload from '@/components/ImgUpload'
import ValidationError from '@/components/validationError'
import SaveReminder from '@/components/saveReminder'
import SaveChanges from '@/components/saveChanges'
import Regions from '@/assets/regions'
import quillEditor from '@/mixins/quillEditor'
import { userOptions } from '@/mixins/options'
import Multiselect from 'vue-multiselect'

const objNotEmpty = helpers.withParams({ type: 'objNotEmpty' }, value => {
  return Object.keys(value).length > 0
})

export default {
  components: {
    CloudinaryUpload,
    ValidationError,
    SaveReminder,
    SaveChanges,
    Multiselect
  },
  mixins: [userOptions, quillEditor],
  data() {
    return {
      content: '',
      name: '',
      city: '',
      country: '',
      website: '',
      region: '',
      regions: Regions,
      universityTopics: {},
      selectedContact: {},
      localUniversityPicture: null
    }
  },
  computed: {
    ...mapState('universities', [
      'universityNameToCreate',
      'universityPictureToCreate',
      'universityDescToCreate',
      'universityCityToCreate',
      'universityCountryToCreate',
      'universityRegionToCreate',
      'universityWebsiteToCreate',
      'universityTopicsToCreate',
      'universityContactToCreate',
      'universityCreationPending'
    ]),
    ...mapGetters('topics', ['getTopicById']),
    ...mapState('topics', ['topics'])
  },
  methods: {
    ...mapMutations('universities', [
      'setUniversityNameToCreate',
      'setUniversityPictureToCreate',
      'setUniversityDescToCreate',
      'setUniversityCityToCreate',
      'setUniversityCountryToCreate',
      'setUniversityRegionToCreate',
      'setUniversityTopicsToCreate',
      'setUniversityContactToCreate',
      'setUniversityWebsiteToCreate'
    ]),

    ...mapActions('universities', ['triggerAddUniversityAction']),
    isChecked(id, level) {
      if (id in this.universityTopics) {
        return this.universityTopics[id].includes(level)
      }

      return false
    },
    toggleTopic(id, level) {
      if (!(id in this.universityTopics)) {
        this.$set(this.universityTopics, id, [level])
      } else {
        const idx = this.universityTopics[id].indexOf(level)

        if (idx !== -1) {
          this.universityTopics[id].splice(idx, 1)
          if (this.universityTopics[id].length === 0) {
            this.$delete(this.universityTopics, id)
          }
        } else {
          this.universityTopics[id].push(level)
        }
      }
    },
    checkAllTopics(id) {
      this.$set(this.universityTopics, id, [
        'undergraduate',
        'graduate',
        'doctorate'
      ])
    },
    uncheckAllTopics(id) {
      this.$delete(this.universityTopics, id)
    },
    setUniversityName(val) {
      this.name = val
      this.setUniversityNameToCreate(val)
      this.$v.name.$touch()
    },
    setUniversityPicture(val) {
      this.setUniversityPictureToCreate(val)
    },
    setUniversityCity(val) {
      this.city = val
      this.setUniversityCityToCreate(val)
      this.$v.city.$touch()
    },
    setUniversityCountry(val) {
      this.country = val
      this.setUniversityCountryToCreate(val)
      this.$v.country.$touch()
    },
    setUniversityRegion(val) {
      this.region = val
      this.setUniversityRegionToCreate(val)
      this.$v.region.$touch()
    },
    setUniversityWebsite(val) {
      this.website = val
      this.setUniversityWebsiteToCreate(val)
      this.$v.website.$touch()
    },
    saveChanges() {
      Object.filter = (obj, predicate) =>
        Object.keys(obj)
          .filter(key => predicate(obj[key]))
          // eslint-disable-next-line no-sequences
          .reduce((res, key) => ((res[key] = obj[key]), res), {})
      const lTopics = Object.filter(this.universityTopics, t => t.length > 0)
      this.setUniversityTopicsToCreate(lTopics)
      this.triggerAddUniversityAction()
      this.$router.push({
        name: 'universities'
      })
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    city: {
      required,
      minLength: minLength(4)
    },
    country: {
      required,
      minLength: minLength(3)
    },
    region: {
      required,
      minLength: minLength(1)
    },
    website: {
      required,
      url
    },
    universityTopics: {
      required,
      objNotEmpty
    },
    content: {
      required
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>
