<template>
  <div class="mw8 center">
    <div class="flex justify-end mt3">
      <router-link
        v-if="networkOnLine && isTaskforceChairOrAbove"
        class="bw1 ba br-1 b--blue link white bg-navy pa2 dim pointer dib"
        :to="{ name: 'edit-topic', params: { id: topic.id } }"
      >
        <i class="las la-edit"></i>
        {{ isTopicEditPending(topic.id) ? 'edit in progress...' : 'edit' }}
      </router-link>
      <div
        v-if="networkOnLine && isTaskforceChairOrAbove"
        class="bw1 ba br-1 b--red link white bg-dark-red pa2 dim pointer dib ml2"
        @click="saveChanges(topic.id)"
      >
        <i class="las la-trash"></i>
        {{
          isTopicDeletionPending(topic.id) ? 'delete in progress...' : 'delete'
        }}
      </div>
    </div>
    <h1 class="">{{ topic.name }}</h1>
    <div class="db b mb2">
      Projects involving {{ topic.name }}:
      <span v-if="filteredProjects.length === 0" class="pl2 i"
        >None at this time</span
      >
    </div>
    <div class="flex">
      <ul class="list flex flex-wrap pa0 ma0">
        <li v-for="p in filteredProjects" :key="p" class="ml2 mb3">
          <router-link
            class="pa1 no-underline bb bw1 b--blue navy hover-bg-navy hover-white bg-animate flex flex-nowrap"
            :to="{ name: 'project', params: { id: p } }"
            :v-if="getProjectById(p.id)"
          >
            <div class="">
              {{ getProjectById(p).name }}
            </div>
            <i class="las la-angle-right db f4 b"></i>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="db b mb2">
      Universities offering courses in {{ topic.name }}:
      <span v-if="filteredUniversities.length === 0" class="pl2 i"
        >None at this time</span
      >
    </div>
    <div class="flex">
      <ul class="list flex flex-wrap pa0 ma0">
        <li v-for="uni in filteredUniversities" :key="uni" class="ml2 mb3">
          <router-link
            class="pa1 no-underline bb bw1 b--blue navy hover-bg-navy hover-white bg-animate flex flex-nowrap"
            :to="{ name: 'university', params: { id: uni } }"
            :v-if="getUniversityById(uni)"
          >
            <div class="">
              {{ getUniversityById(uni).name }}
            </div>
            <i class="las la-angle-right db f4 b"></i>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="db b mb2">
      Resources about {{ topic.name }}:
      <span v-if="filteredResources.length === 0" class="pl2 i"
        >None at this time</span
      >
    </div>
    <div class="flex">
      <ul class="list flex flex-wrap pa0 ma0">
        <li v-for="r in filteredResources" :key="r" class="ml2 mb3">
          <router-link
            class="pa1 no-underline bb bw1 b--blue navy hover-bg-navy hover-white bg-animate flex flex-nowrap"
            :to="{ name: 'resource', params: { id: r } }"
            :v-if="getResourceById(r)"
          >
            <div class="">
              {{ getResourceById(r).name }}
            </div>
            <i class="las la-angle-right db f4 b"></i>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="db b mb2">
      Opportunities involving {{ topic.name }}:
      <span v-if="filteredOffers.length === 0" class="pl2 i"
        >None at this time</span
      >
    </div>
    <div class="flex">
      <ul class="list flex flex-wrap pa0 ma0">
        <li v-for="o in filteredOffers" :key="o" class="ml2 mb3">
          <router-link
            class="pa1 no-underline bb bw1 b--blue navy hover-bg-navy hover-white bg-animate flex flex-nowrap"
            :to="{ name: 'offer', params: { id: o } }"
            :v-if="getOfferById(o)"
          >
            <div class="">
              {{ getOfferById(o).name }}
            </div>
            <i class="las la-angle-right db f4 b"></i>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="db b mb2">
      Members interested in {{ topic.name }}:
      <span v-if="filteredUsers.length === 0" class="pl2 i"
        >None at this time</span
      >
    </div>
    <div class="">
      <user-list-export :users="filteredUsers"></user-list-export>
      <div class="flex flex-wrap">
        <user-item-small
          v-for="(user, index) in filteredUsers"
          :key="user.id"
          class="db"
          :class="{ 'w-25': !small, 'w-100': small }"
          :index="index"
          :data="user"
        ></user-item-small>
      </div>
    </div>
    <!-- eslint-disable -->
    <div v-html="highlightedDescription"></div>
    <!-- eslint-enable -->
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import csReplace from 'preserve-case'
import UserItemSmall from '@/components/user/UserItemSmall'
import adminHelper from '@/mixins/adminHelper'
import resizeMixin from '@/mixins/resizeMixin'
import UserListExport from '@/components/user/UserListExport'

export default {
  components: { UserItemSmall, UserListExport },
  mixins: [adminHelper, resizeMixin],
  props: {
    topic: Object
  },
  computed: {
    ...mapGetters('topics', ['isTopicDeletionPending', 'isTopicEditPending']),
    ...mapGetters('users', ['getUserById']),
    ...mapGetters('offers', ['getOfferById']),
    ...mapGetters('projects', ['getProjectById']),
    ...mapGetters('universities', ['getUniversityById']),
    ...mapGetters('resources', ['getResourceById']),
    ...mapState('topics', ['topics']),
    ...mapState('users', ['users']),
    ...mapState('offers', ['offers']),
    ...mapState('projects', ['projects']),
    ...mapState('resources', ['resources']),
    ...mapState('universities', ['universities']),
    ...mapState('app', ['networkOnLine']),
    filteredProjects() {
      const { id } = this.topic
      const arr = Object.values(this.projects)
      const proj = []

      arr.forEach(p => {
        const fProj = p.topics.filter(t => {
          return t.id === id
        })

        if (fProj.length > 0) {
          proj.push(p.id)
        }
      })

      return proj
    },
    filteredResources() {
      const { id } = this.topic
      const arr = Object.values(this.resources)
      const resrc = []

      arr.forEach(r => {
        const fRsrc = r.topics.filter(t => {
          return t.id === id
        })

        if (fRsrc.length > 0) {
          resrc.push(r.id)
        }
      })

      return resrc
    },
    filteredUsers() {
      const { id } = this.topic
      const arr = Object.values(this.users)
      const user = []

      arr.forEach(u => {
        if (!u.topics) return
        const fUser = u.topics.filter(usr => {
          return usr.id === id
        })

        if (fUser.length > 0) {
          user.push(this.getUserById(u.id))
        }
      })

      return user
    },
    filteredOffers() {
      const { id } = this.topic
      const arr = Object.values(this.offers)
      const offers = []

      arr.forEach(o => {
        if (!o.topics) return
        const fOffer = o.topics.filter(t => {
          return t.id === id
        })

        if (fOffer.length > 0) {
          offers.push(o.id)
        }
      })

      return offers
    },
    filteredUniversities() {
      const { id } = this.topic
      const arr = Object.values(this.universities)
      const uni = []

      arr.forEach(u => {
        const fUni = Object.keys(u.topics).filter(t => {
          return t === id
        })

        if (fUni.length > 0) {
          uni.push(u.id)
        }
      })

      return uni
    },
    query() {
      return this.$route.params.q || ''
    },
    highlightedDescription() {
      if (this.query.length < 4) {
        return this.topic.description
      }

      let hDesc = csReplace.all(
        this.topic.description,
        this.query,
        `<::${this.query}::>`
      )

      hDesc = hDesc.replaceAll('<::', `<span class='bg-yellow'>`)

      hDesc = hDesc.replaceAll('::>', '</span>')

      return hDesc
    }
  },
  methods: {
    ...mapActions('topics', ['deleteTopic']),
    saveChanges(id) {
      this.$dialog
        .confirm('Are you sure? Deleted records cannot be recovered.')
        .then(() => {
          this.deleteTopic(id)
          this.$router.push({
            name: 'topics'
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped></style>
