import Universities from '@/views/university/Universities'
import University from '@/views/university/University'
import AddUniversity from '@/views/university/AddUniversity'
import EditUniversity from '@/views/university/EditUniversity'
import { isChair, isAdmin, isUnversityContact } from '@/router/helpers'

const routes = [
  {
    path: '/universities',
    name: 'universities',
    component: Universities
  },
  {
    path: '/universities/:id',
    name: 'university',
    props: true,
    component: University
  },
  {
    path: '/universities/edit/:id',
    name: 'edit-university',
    props: true,
    component: EditUniversity,
    beforeEnter: (to, from, next) => {
      if (isUnversityContact(to) || isChair() || isAdmin()) return next()
      return next('/unauthorized')
    }
  },
  {
    path: '/universities/add',
    name: 'add-university',
    props: true,
    component: AddUniversity,
    meta: {
      reqTaskforceChair: true
    }
  }
]

export default routes
