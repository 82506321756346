import RequestsDB from '@/firebase/requests-db'

export default {
  /**
   * Fetch requests of current loggedin user
   */
  getRequests: async ({ commit }) => {
    const RequestDb = new RequestsDB()
    const requests = await RequestDb.readAll()
    commit('setRequests', requests)
  },

  /**
   * Create a request for current loggedin user
   */
  createRequest: async ({ commit }, request) => {
    const RequestDb = new RequestsDB()

    commit('setRequestCreationPending', true)
    const createdRequest = await RequestDb.create(request, request.id)
    commit('addRequest', createdRequest)
    commit('setRequestCreationPending', false)
  },
  editRequest: async ({ dispatch, commit }, request) => {
    const RequestDb = new RequestsDB()

    commit('addRequestEditPending', request.id)
    await RequestDb.update(request)
    commit('removeRequestEditPending', request.id)
    dispatch('getRequests')
  },

  /**
   * Create a new request for current loggedin user and reset request name input
   */
  triggerAddRequestAction: ({ dispatch, state, commit }, params) => {
    if (state.requestNameToCreate === '') return

    const request = {
      name: state.requestNameToCreate,
      email: state.requestEmailToCreate,
      id: params.id
    }

    commit('setRequestNameToCreate', '')
    commit('setRequestEmailToCreate', '')
    dispatch('createRequest', request)
  },

  /**
   * Delete a user request from its id
   */
  deleteRequest: async ({ commit, getters }, requestId) => {
    if (getters.isRequestDeletionPending(requestId)) return

    const RequestDb = new RequestsDB()

    commit('addRequestDeletionPending', requestId)
    await RequestDb.delete(requestId)
    commit('removeRequestById', requestId)
    commit('removeRequestDeletionPending', requestId)
  }
}
