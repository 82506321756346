<template>
  <div class="resource-action-bar">
    <h1>Create New Resource</h1>
    <save-reminder :dirty="$v.$anyDirty"></save-reminder>

    <label for="resource-name" class="b mv2 dib">Resource Name</label>
    <validation-error :show="$v.name.$error">
      Name requires 4 or more characters.
    </validation-error>
    <input
      id="resource-name"
      placeholder="resource name..."
      type="text"
      class="pa2 w-100 mb3"
      @input="setResourceName($event.target.value)"
    />
    <label for="resource-audience" class="b mv2 dib">Resource Audience</label>
    <validation-error :show="$v.selectedAudience.$invalid">
      Audience is required
    </validation-error>
    <select
      id="resource-audience"
      v-model.trim="selectedAudience"
      @change="setResourceAudienceToCreate(selectedAudience)"
    >
      <option value="">--Please choose an option--</option>
      <option v-for="a in audiences" :key="a" :value="a">{{
        keyToName(a)
      }}</option>
    </select>

    <label for="resource-type" class="b mv2 db">Resource Type</label>
    <validation-error :show="$v.selectedType.$invalid">
      Type is required.
    </validation-error>
    <select
      id="resource-type"
      v-model.trim="selectedType"
      @change="setResourceTypeToCreate(selectedType)"
    >
      <option value="">--Please choose an option--</option>
      <option v-for="t in types" :key="t" :value="t">{{ keyToName(t) }}</option>
    </select>

    <label for="resource-taskforce" class="b mv2 db">Task Force</label>
    <validation-error :show="$v.localResourceTaskforce.$invalid">
      Please select a Task Force
    </validation-error>
    <multiselect
      id="resource-taskforce"
      v-model.trim="localResourceTaskforce"
      :options="taskforceOptions"
      :multiple="false"
      :close-on-select="true"
      placeholder="Task Force"
      label="name"
      track-by="id"
      class="mb3"
      @input="setResourceTaskforceToCreate(localResourceTaskforce)"
    >
    </multiselect>
    <label for="resource-language" class="b mv2 dib">Resource Language</label>
    <validation-error :show="$v.selectedLanguage.$invalid">
      Resource Language cannot be empty
    </validation-error>
    <multiselect
      id="resource-language"
      v-model.trim="selectedLanguage"
      :options="languageOptions"
      :multiple="false"
      :close-on-select="true"
      placeholder="Language"
      :custom-label="nameWithNative"
      track-by="id"
      class="mb3"
      @input="setResourceLanguageToCreate(selectedLanguage)"
    >
    </multiselect>
    <label for="resource-topics" class="b mv2 dib"
      >Resource Areas of Specialization (Choose 1 or more)</label
    >
    <validation-error :show="$v.selectedTopics.$invalid">
      Topics Areas of Specialization be empty
    </validation-error>
    <multiselect
      id="resource-topics"
      v-model.trim="selectedTopics"
      :options="topicOptions"
      :multiple="true"
      :close-on-select="true"
      placeholder="Areas of Specialization"
      label="name"
      track-by="id"
      class="mb3"
      @input="setResourceTopicsToCreate(selectedTopics)"
    >
    </multiselect>
    <label for="resource-description" class="b mv2 dib"
      >Resource Description</label
    >
    <validation-error :show="$v.content.$invalid">
      Description cannot be empty
    </validation-error>
    <vue-editor
      id="resource-description"
      v-model.trim="content"
      :editor-options="editorSettings"
      placeholder="resource description..."
      class="resource-name-input mt3"
      rows="7"
      cols="40"
      @input="setResourceDescToCreate(content)"
    ></vue-editor>
    <save-changes
      :invalid="$v.$invalid"
      @save-changes="saveChanges()"
    ></save-changes>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import ValidationError from '@/components/validationError'
import SaveReminder from '@/components/saveReminder'
import SaveChanges from '@/components/saveChanges'
import Languages from '@/assets/languages'
import Types from '@/assets/resourceTypes'
import Audiences from '@/assets/resourceAudiences'
import { taskforceOptions, topicOptions } from '@/mixins/options'
import quillEditor from '@/mixins/quillEditor'

export default {
  components: {
    Multiselect,
    ValidationError,
    SaveReminder,
    SaveChanges
  },
  mixins: [taskforceOptions, topicOptions, quillEditor],
  data() {
    return {
      content: '',
      languageOptions: Languages,
      types: Types,
      audiences: Audiences,
      localResourceTaskforce: [],
      selectedLanguage: { id: 'en', name: 'English', nativeName: 'English' },
      selectedTopics: [],
      selectedType: '',
      name: '',
      selectedAudience: ''
    }
  },
  computed: {
    ...mapState('resources', [
      'resourceNameToCreate',
      'resourceAudienceToCreate',
      'resourceDescToCreate',
      'resourceTaskforceToCreate',
      'resourceLanguageToCreate',
      'resourceTopicsToCreate',
      'resourceTypeToCreate',
      'resourceCreationPending'
    ])
  },
  created() {
    this.setResourceLanguageToCreate(this.selectedLanguage)
  },
  methods: {
    ...mapMutations('resources', [
      'setResourceNameToCreate',
      'setResourceAudienceToCreate',
      'setResourceDescToCreate',
      'setResourceLanguageToCreate',
      'setResourceTypeToCreate',
      'setResourceTopicsToCreate',
      'setResourceTaskforceToCreate'
    ]),
    ...mapActions('resources', ['triggerAddResourceAction']),
    setResourceName(val) {
      this.name = val
      this.setResourceNameToCreate(val)
      this.$v.name.$touch()
    },
    nameWithNative({ name, nativeName }) {
      return `${name} — ${nativeName}`
    },
    keyToName(k) {
      return k.replace('_', ' ').replace(/\w\S*/g, txt => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    },
    saveChanges() {
      this.triggerAddResourceAction()
      this.$router.push({
        name: 'resources'
      })
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    selectedAudience: {
      required,
      minLength: minLength(4)
    },
    selectedType: {
      required,
      minLength: minLength(4)
    },
    localResourceTaskforce: {
      required,
      minLength: minLength(1)
    },
    selectedLanguage: {
      required,
      minLength: minLength(1)
    },
    selectedTopics: {
      required,
      minLength: minLength(1)
    },
    content: {
      required
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>
