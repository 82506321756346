<template>
  <div class="taskforce-action-bar">
    <h1>Create New Task Force</h1>
    <save-reminder :dirty="$v.$anyDirty"></save-reminder>

    <label for="taskforce-name" class="b mv2 dib">Task Force Name</label>
    <validation-error :show="$v.name.$error">
      Name requires 4 or more characters.
    </validation-error>
    <input
      id="taskforce-name"
      placeholder="task force name..."
      type="text"
      class="pa2 w-100 mb3"
      @input="setTaskforceName($event.target.value)"
    />
    <label for="taskforce-chairs" class="b mv2 dib"
      >Task Force Chairs (Choose 1 or more)</label
    >
    <validation-error :show="$v.selectedChairs.$invalid">
      Chairs cannot be empty
    </validation-error>
    <multiselect
      id="taskforce-chairs"
      v-model.trim="selectedChairs"
      :options="userOptions"
      :multiple="true"
      :close-on-select="true"
      placeholder="Chairs"
      label="name"
      track-by="id"
      class="mb3"
      @input="setTaskforceChairsToCreate(selectedChairs)"
    >
    </multiselect>
    <label for="taskforce-members" class="b mv2 dib"
      >Task Force Members (Choose 1 or more)</label
    >
    <validation-error :show="$v.selectedMembers.$invalid">
      Members cannot be empty
    </validation-error>
    <multiselect
      id="taskforce-members"
      v-model.trim="selectedMembers"
      :options="userOptions"
      :multiple="true"
      :close-on-select="true"
      placeholder="Members"
      label="name"
      track-by="id"
      class="mb3"
      @input="setTaskforceMembersToCreate(selectedMembers)"
    >
    </multiselect>

    <label for="taskforce-description" class="b mv2 dib"
      >Task Force Description</label
    >
    <validation-error :show="$v.content.$invalid">
      Description cannot be empty
    </validation-error>
    <vue-editor
      id="taskforce-description"
      v-model.trim="content"
      :editor-options="editorSettings"
      placeholder="task force description..."
      class="taskforce-name-input"
      rows="7"
      cols="40"
      @input="setTaskforceDescToCreate(content)"
    ></vue-editor>
    <save-changes
      :invalid="$v.$invalid"
      @save-changes="saveChanges()"
    ></save-changes>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import ValidationError from '@/components/validationError'
import SaveReminder from '@/components/saveReminder'
import SaveChanges from '@/components/saveChanges'
import { userOptions } from '@/mixins/options'
import quillEditor from '@/mixins/quillEditor'

export default {
  components: {
    Multiselect,
    ValidationError,
    SaveReminder,
    SaveChanges
  },
  mixins: [userOptions, quillEditor],
  data() {
    return {
      content: '',
      selectedChairs: [],
      selectedMembers: [],
      name: ''
    }
  },
  computed: {
    ...mapState('taskforces', [
      'taskforceNameToCreate',
      'taskforceDescToCreate',
      'taskforceChairsToCreate',
      'taskforceMembersToCreate',
      'taskforceCreationPending'
    ])
  },

  methods: {
    ...mapMutations('taskforces', [
      'setTaskforceNameToCreate',
      'setTaskforceDescToCreate',
      'setTaskforceChairsToCreate',
      'setTaskforceMembersToCreate'
    ]),
    ...mapActions('taskforces', ['triggerAddTaskforceAction']),
    setTaskforceName(val) {
      this.name = val
      this.setTaskforceNameToCreate(val)
      this.$v.name.$touch()
    },
    saveChanges() {
      this.triggerAddTaskforceAction()
      this.$router.push({
        name: 'taskforces'
      })
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    selectedChairs: {
      required,
      minLength: minLength(1)
    },
    selectedMembers: {
      required,
      minLength: minLength(1)
    },
    content: {
      required
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>
