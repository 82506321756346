<template>
  <div class="mw8 center">
    <edit-request
      v-if="currentRequest"
      :request="currentRequest"
    ></edit-request>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditRequest from '@/components/request/EditRequest'

export default {
  components: { EditRequest },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('requests', ['getRequestById']),
    currentRequest() {
      return this.getRequestById(this.id)
    }
  }
}
</script>
