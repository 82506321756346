<template>
  <div class="user-action-bar">
    <h1>Add Member</h1>
    <save-reminder :dirty="$v.$anyDirty"></save-reminder>
    <label for="user-name" class="b mv2 dib">Member Name</label>
    <validation-error :show="$v.name.$error">
      Name requires 4 or more characters.
    </validation-error>
    <input
      id="user-name"
      placeholder="user name..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setUserName($event.target.value)"
    />
    <cloudinary-upload
      :src="localUserPicture"
      name="User"
      @uploaded="setUserPicture($event)"
    ></cloudinary-upload>
    <label for="user-email" class="b mv2 db">Member Email</label>
    <p class="i">
      This is the email address used to manage your account. This address is not
      published. If you want to publish your email for other users, please use
      the contacts section.
    </p>
    <validation-error :show="$v.email.$error">
      Please enter a valid email address.
    </validation-error>
    <input
      id="user-email"
      placeholder="user email..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setUserEmail($event.target.value)"
    />
    <label for="user-university" class="b mv2 dib">University</label>
    <validation-error :show="$v.localUserUniversity.$invalid">
      Please select a University
    </validation-error>
    <multiselect
      id="user-university"
      v-model.trim="localUserUniversity"
      :options="universityOptions"
      :multiple="false"
      :close-on-select="true"
      placeholder="University"
      label="name"
      track-by="id"
      class="mb3"
      @input="setUserUniversityToCreate(localUserUniversity)"
    >
    </multiselect>
    <label for="user-topics" class="b mv2 dib"
      >Areas of Specialization (Choose 1 or more)</label
    >
    <validation-error :show="$v.selectedTopics.$invalid">
      Areas of Specialization cannot be empty
    </validation-error>
    <multiselect
      id="user-topics"
      v-model.trim="selectedTopics"
      :options="topicOptions"
      :multiple="true"
      :close-on-select="true"
      placeholder="Areas of Specialization"
      label="name"
      track-by="id"
      class="mb3"
      @input="setUserTopicsToCreate(selectedTopics)"
    >
    </multiselect>
    <label for="title" class="b mv2 dib">Title</label>
    <validation-error :show="$v.title.$error">
      Title requires 4 or more characters.
    </validation-error>
    <input
      id="title"
      placeholder="title..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setUserTitle($event.target.value)"
    />
    <label for="contact" class="b mv2 dib">Contact Information</label>
    <validation-error :show="$v.localUserContact.$invalid">
      Please add a contact method.
    </validation-error>
    <div
      v-for="(item, idx) in localUserContact"
      id="contact"
      :key="item.id"
      class="flex"
    >
      <select v-model.trim="item.type">
        <option value="">--Please choose an option--</option>
        <option value="phone">Telephone</option>
        <option value="email">E-Mail</option>
        <option value="website">Website</option>
        <option value="facebook">Facebook</option>
        <option value="twitter">Twitter</option>
        <option value="linkedin">LinkedIn</option>
      </select>
      <input v-model.trim="item.contact" type="text" class="pa2 w-100 ml2" />
      <div
        v-if="localUserContact.length > 1"
        class="ml2 pa2 b--red ba bw1 red pointer dim"
        @click="deleteContact(idx)"
      >
        -
      </div>
    </div>
    <div
      class="dib pa2 b--green ba bw1 green pointer dim"
      @click="newContact()"
    >
      +
    </div>
    <label for="user-bio" class="b mv2 db">Biography</label>
    <validation-error :show="$v.content.$invalid">
      Biography cannot be empty
    </validation-error>
    <vue-editor
      id="user-bio"
      v-model.trim="content"
      :editor-options="editorSettings"
      placeholder="user bio..."
      class="user-name-input"
      rows="7"
      cols="40"
      @input="setUserBioToCreate(content)"
    ></vue-editor>
    <save-changes
      :invalid="$v.$invalid"
      @save-changes="saveChanges()"
    ></save-changes>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { required, minLength, email } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import { cloneDeep } from 'lodash'
import CloudinaryUpload from '@/components/ImgUpload'
import ValidationError from '@/components/validationError'
import SaveReminder from '@/components/saveReminder'
import SaveChanges from '@/components/saveChanges'
import quillEditor from '@/mixins/quillEditor'
import { universityOptions, topicOptions } from '@/mixins/options'

export default {
  components: {
    Multiselect,
    CloudinaryUpload,
    ValidationError,
    SaveReminder,
    SaveChanges
  },
  mixins: [universityOptions, topicOptions, quillEditor],
  data() {
    return {
      content: '',
      name: '',
      email: '',
      title: '',
      localUserUniversity: {},
      localUserPicture: null,
      localUserContact: [],
      selectedTopics: []
    }
  },
  computed: {
    ...mapState('users', [
      'userNameToCreate',
      'userEmailToCreate',
      'userPictureToCreate',
      'userTitleToCreate',
      'userContactToCreate',
      'userTopicsToCreate',
      'userBioToCreate',
      'userUniversityToCreate',
      'userCreationPending'
    ]),
    ...mapGetters('universities', ['getUniversityById']),
    ...mapState('universities', ['universities']),
    ...mapGetters('topics', ['getTopicById']),
    ...mapState('topics', ['topics']),
    universityOptions() {
      return this.universities.flatMap(u => {
        return { name: this.getUniversityById(u.id).name, id: u.id }
      })
    },
    topicOptions() {
      return this.topics.flatMap(t => {
        return { name: t.name, id: t.id }
      })
    }
  },
  created() {
    this.localUserContact = cloneDeep(this.userContactToCreate)
  },
  methods: {
    ...mapMutations('users', [
      'setUserNameToCreate',
      'setUserEmailToCreate',
      'setUserPictureToCreate',
      'setUserTitleToCreate',
      'setUserUniversityToCreate',
      'setUserContactToCreate',
      'setUserTopicsToCreate',
      'setUserBioToCreate'
    ]),
    ...mapActions('users', ['triggerAddUserAction']),
    newContact() {
      this.localUserContact.push({
        type: '',
        contact: '',
        id: new Date().getTime()
      })
    },
    deleteContact(idx) {
      this.localUserContact.splice(idx, 1)
    },
    setUserName(val) {
      this.name = val
      this.setUserNameToCreate(val)
      this.$v.name.$touch()
    },
    setUserEmail(val) {
      this.email = val
      this.setUserEmailToCreate(val)
      this.$v.email.$touch()
    },
    setUserPicture(val) {
      this.setUserPictureToCreate(val)
    },
    setUserTitle(val) {
      this.title = val
      this.setUserTitleToCreate(val)
      this.$v.title.$touch()
    },
    saveChanges() {
      this.setUserContactToCreate(this.localUserContact)
      this.triggerAddUserAction()
      this.$router.push({
        name: 'members'
      })
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    title: {
      required,
      minLength: minLength(4)
    },
    email: {
      required,
      email
    },
    selectedTopics: {
      required,
      minLength: minLength(1)
    },
    localUserUniversity: {
      required,
      minLength: minLength(1)
    },
    localUserContact: {
      required,
      minLength: minLength(1),
      $each: {
        type: {
          required,
          minLength: minLength(1)
        },
        contact: {
          minLength: minLength(4)
        }
      }
    },
    content: {
      required
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>
