import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import authentication from './authentication'
import app from './app'
import taskforces from './taskforces'
import projects from './projects'
import universities from './universities'
import topics from './topics'
import users from './users'
import requests from './requests'
import resources from './resources'
import chairs from './chairs'
import admins from './admins'
import offers from './offers'

Vue.use(Vuex)

/* If you don't know about Vuex, please refer to https://vuex.vuejs.org/ */

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ],
  modules: {
    authentication,
    app,
    taskforces,
    projects,
    universities,
    topics,
    users,
    requests,
    resources,
    chairs,
    admins,
    offers
  }
})
