<template>
  <div class="mv3">
    <label :for="name.toLowerCase() + '-picture'" class="b mv2 db"
      >{{ name }} Picture</label
    >
    <p v-if="descr" class="i">{{ descr }}</p>
    <div v-if="getSrc != null" class="mb2">
      <img class="preview" :src="getOptimizedSrc(getSrc)" />
      <br />
    </div>
    <input
      :id="name.toLowerCase() + '-picture'"
      type="file"
      accept="image/*"
      @change="handleFileChange"
    />
    <div
      v-if="file != null"
      class="pa2 b-grey bw1 ba br1 dim pointer dib mv3 ml2"
      @click="upload()"
    >
      Upload Photo
    </div>
    <p v-if="file != null" class="pa2 bg-light-yellow b">
      Please click the upload button to see your changes.
    </p>
  </div>
</template>

<script>
/* eslint-disable func-names */
import axios from 'axios'

export default {
  name: 'CloudinaryUpload',
  props: {
    name: String,
    src: String,
    descr: String,
    member: Boolean
  },
  data() {
    return {
      results: null,
      errors: [],
      file: null,
      filesSelected: 0,
      fileContents: null,
      formData: null
    }
  },
  computed: {
    getSrc() {
      if (this.results) return this.results.public_id
      return this.src
    }
  },
  methods: {
    getOptimizedSrc(src) {
      if (this.member) {
        return `https://res.cloudinary.com/iajesdb/image/upload/ar_1,c_crop,f_auto,g_face,q_auto:good,z_0.7/c_scale,w_248,ar_1/${src}`
      }

      return `https://res.cloudinary.com/iajesdb/image/upload/f_auto,q_auto:good,c_scale,w_400/${src}`
    },
    // function to handle file info obtained from local
    // file system and set the file state
    handleFileChange(event) {
      // returns an array of files even though multiple not used
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0]
      this.filesSelected = event.target.files.length
    },
    prepareFormData() {
      this.formData = new FormData()
      this.formData.append('upload_preset', 'ml_default')
      this.formData.append('file', this.fileContents)
    },
    // function to handle form submit
    upload() {
      // clear errors

      this.errors = []
      const reader = new FileReader()
      // attach listener to be called when data from file
      reader.addEventListener(
        'load',
        function() {
          this.fileContents = reader.result
          this.prepareFormData()
          const cloudinaryUploadURL = `https://api.cloudinary.com/v1_1/iajesdb/upload`
          const requestObj = {
            url: cloudinaryUploadURL,
            method: 'POST',
            data: this.formData,
            onUploadProgress: function(progressEvent) {
              this.progress = Math.round(
                (progressEvent.loaded * 100.0) / progressEvent.total
              )
              // bind "this" to access vue state during callback
            }.bind(this)
          }
          // show progress bar at beginning of post
          this.showProgress = true
          axios(requestObj)
            .then(response => {
              this.results = response.data
              this.$emit('uploaded', this.results.public_id)
            })
            .catch(error => {
              this.errors.push(error)
            })
            .finally(() => {
              setTimeout(
                function() {
                  this.showProgress = false
                }.bind(this),
                1000
              )
            })
        }.bind(this),
        false
      )
      // call for file read if there is a file
      if (this.file && this.file.name) {
        reader.readAsDataURL(this.file)
      }
    }
  }
}
</script>
