<template>
  <div class="topic-action-bar">
    <h1>Create New Area of Specialization</h1>
    <save-reminder :dirty="$v.$anyDirty"></save-reminder>

    <label for="topic-name" class="b mv2 dib"
      >Area of Specialization Name</label
    >
    <validation-error :show="$v.name.$error">
      Name requires 4 or more characters.
    </validation-error>
    <input
      id="topic-name"
      placeholder="topic name..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setTopicName($event.target.value)"
    />
    <label for="topic-description" class="b mv2 dib"
      >Area of Specialization Description</label
    >
    <validation-error :show="$v.content.$invalid">
      Description cannot be empty
    </validation-error>
    <vue-editor
      id="topic-description"
      v-model.trim="content"
      :editor-options="editorSettings"
      placeholder="Area of Specialization description..."
      class="topic-name-input"
      rows="7"
      cols="40"
      @input="setTopicDescToCreate(content)"
    ></vue-editor>
    <save-changes
      :invalid="$v.$invalid"
      @save-changes="saveChanges()"
    ></save-changes>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import ValidationError from '@/components/validationError'
import SaveReminder from '@/components/saveReminder'
import SaveChanges from '@/components/saveChanges'
import quillEditor from '@/mixins/quillEditor'

export default {
  components: {
    ValidationError,
    SaveReminder,
    SaveChanges
  },
  mixins: [quillEditor],
  data() {
    return {
      content: '',
      name: ''
    }
  },
  computed: mapState('topics', [
    'topicNameToCreate',
    'topicDescToCreate',
    'topicCreationPending'
  ]),

  methods: {
    ...mapMutations('topics', ['setTopicNameToCreate', 'setTopicDescToCreate']),

    ...mapActions('topics', ['triggerAddTopicAction']),
    setTopicName(val) {
      this.name = val
      this.setTopicNameToCreate(val)
      this.$v.name.$touch()
    },
    saveChanges() {
      this.triggerAddTopicAction()
      this.$router.push({
        name: 'topics'
      })
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    content: {
      required
    }
  }
}
</script>

<style lang="scss" scoped></style>
