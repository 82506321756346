import state from './chairs.state'
import mutations from './chairs.mutations'
import actions from './chairs.actions'
import getters from './chairs.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
