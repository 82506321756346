import ChairsDB from '@/firebase/chairs-db'

export default {
  /**
   * Fetch chairs of current loggedin chair
   */
  getChairs: async ({ commit }) => {
    const ChairDb = new ChairsDB()
    const chairs = await ChairDb.readAll()
    commit('setChairs', chairs)
  },

  /**
   * Create a chair for current loggedin chair
   */
  createChair: async ({ commit }, chair) => {
    const ChairDb = new ChairsDB()

    const createdChair = await ChairDb.create(chair, chair.id)
    commit('addChair', createdChair)
  },

  /**
   * Delete a chair chair from its id
   */
  deleteChair: async ({ commit, getters }, chairId) => {
    if (getters.isChairDeletionPending(chairId)) return

    const ChairDb = new ChairsDB()

    commit('addChairDeletionPending', chairId)
    await ChairDb.delete(chairId)
    commit('removeChairDeletionPending', chairId)
    commit('removeChairById', chairId)
  }
}
