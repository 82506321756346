export default {
  /* Taskforce input name */
  setTaskforceNameToCreate: (state, taskforceNameToCreate) =>
    (state.taskforceNameToCreate = taskforceNameToCreate),
  setTaskforceDescToCreate: (state, taskforceDescToCreate) =>
    (state.taskforceDescToCreate = taskforceDescToCreate),
  setTaskforceChairsToCreate: (state, taskforceChairsToCreate) =>
    (state.taskforceChairsToCreate = taskforceChairsToCreate),
  setTaskforceMembersToCreate: (state, taskforceMembersToCreate) =>
    (state.taskforceMembersToCreate = taskforceMembersToCreate),
  setTaskforceNameToEdit: (state, taskforceNameToEdit) =>
    (state.taskforceNameToEdit = taskforceNameToEdit),
  setTaskforceDescToEdit: (state, taskforceDescToEdit) =>
    (state.taskforceDescToEdit = taskforceDescToEdit),
  setTaskforceChairsToEdit: (state, taskforceChairsToEdit) =>
    (state.taskforceChairsToEdit = taskforceChairsToEdit),
  setTaskforceMembersToEdit: (state, taskforceMembersToEdit) =>
    (state.taskforceMembersToEdit = taskforceMembersToEdit),

  /* Taskforces */
  setTaskforces: (state, taskforces) => (state.taskforces = taskforces),
  addTaskforce: (state, taskforce) => state.taskforces.push(taskforce),
  removeTaskforceById: (state, taskforceId) => {
    const index = state.taskforces.findIndex(
      taskforce => taskforce.id === taskforceId
    )
    state.taskforces.splice(index, 1)
  },

  /* Taskforces deletion */
  addTaskforceDeletionPending: (state, taskforceId) =>
    state.taskforceDeletionPending.push(taskforceId),
  removeTaskforceDeletionPending: (state, taskforceId) => {
    const index = state.taskforceDeletionPending.indexOf(taskforceId)
    state.taskforceDeletionPending.splice(index, 1)
  },

  /* Taskforces edit */
  addTaskforceEditPending: (state, taskforceId) =>
    state.taskforceEditPending.push(taskforceId),
  removeTaskforceEditPending: (state, taskforceId) => {
    const index = state.taskforceEditPending.indexOf(taskforceId)
    state.taskforceEditPending.splice(index, 1)
  }
}
