import { find } from 'lodash'

export default {
  /**
   * Check if a taskforce has deletion pending
   */
  isTaskforceDeletionPending: state => taskforceId =>
    state.taskforceDeletionPending.includes(taskforceId),

  isTaskforceEditPending: state => taskforceId =>
    state.taskforceEditPending.includes(taskforceId),

  /**
   * Get taskforce by id
   */
  getTaskforceById: state => taskforceId => {
    const res = find(
      state.taskforces,
      taskforce => taskforce.id === taskforceId
    )

    if (res) {
      return res
    }

    return false
  },

  /**
   * Is a given user a task force chair?
   */
  isTaskforceChair: state => localUserId => {
    const res = state.taskforces.filter(tf => {
      return tf.chairs.find(c => {
        return c === localUserId
      })
    })

    if (res.length > 0) {
      return res
    }

    return false
  }
}
