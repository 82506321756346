<template>
  <div>
    <p v-if="offers === null" class="i">Loading...</p>
    <p v-if="offers && !offers.length" class="i">
      You don't have any opportunities yet
    </p>
    <div class="mb0 relative">
      <i
        class="las la-search absolute left-1 black z-max mt3 pt1 f3 dn db-ns"
      ></i>
      <input
        id="offer-search"
        v-model.trim="query"
        type="text"
        placeholder="Search all opportunities"
        class="1 b--mid-gray pl5 pa3 w-100 f3"
      />
      <i
        v-show="query.length > 0"
        class="las la-backspace absolute right-1 highlight pointer dim z-max mt3 pt1 f3 dn db-ns"
        @click="clearQuery()"
      ></i>
      <label for="offer-search"></label>
    </div>
    <filter-bar
      :current="filteredOffers.length"
      :total="offers.length"
      name="opportunity"
      plural="opportunities"
      :an="true"
    ></filter-bar>
    <offer-item
      v-for="(offer, index) in filteredOffers"
      :key="offer.id"
      class="offer-row"
      :query="query"
      :index="index"
      :is-offer-deletion-pending="isOfferDeletionPending(offer.id)"
      :disable-actions="!networkOnLine"
      :data="offer"
      @deleteOffer="deleteOffer"
    ></offer-item>
  </div>
</template>

<script>
import OfferItem from '@/components/offer/OfferItem'
import { mapState, mapActions, mapGetters } from 'vuex'
import elasticlunr from 'elasticlunr'
import FilterBar from '@/components/filterBar'

export default {
  components: { OfferItem, FilterBar },
  data() {
    return {
      query: '',
      searchIndex: null,
      searchRes: []
    }
  },
  computed: {
    ...mapGetters('offers', ['isOfferDeletionPending']),
    ...mapState('offers', ['offers']),
    ...mapState('app', ['networkOnLine']),
    filteredOffers() {
      if (this.offers) {
        const arr = this.offers.filter(p => {
          return this.visible(p)
        })

        return arr
      }

      return []
    }
  },
  watch: {
    offers() {
      this.buildIndex()
    },
    query() {
      if (this.searchIndex) {
        this.searchRes = this.searchIndex.search(this.query, { expand: true })
      }
    }
  },
  created() {
    this.buildIndex()
  },
  methods: {
    ...mapActions('offers', ['deleteOffer']),
    buildIndex() {
      if (this.offers) {
        this.searchIndex = elasticlunr(function f() {
          this.addField('name')
          this.addField('description')
          this.addField('topics')
          this.setRef('id')
        })

        this.searchIndex.saveDocument(false)
        const i = this.offers.map(x => {
          return {
            name: x.name,
            description: x.description,
            topics: this.joinNames(x.topics),
            id: x.id
          }
        })

        i.forEach(x => {
          this.searchIndex.addDoc(x)
        })
      }
    },
    clearQuery() {
      this.query = ''
    },
    visible(prj) {
      let inSearch = false

      if (this.query.length < 4) {
        inSearch = true
      } else {
        this.searchRes.forEach(r => {
          if (r.ref === prj.id) {
            inSearch = true
          }
        })
      }

      return inSearch
    },
    joinNames(arr) {
      if (!arr) return ''
      const reducerFun = (acc, curr) => `${acc} ${curr.name}`

      return arr.reduce(reducerFun, '')
    }
  }
}
</script>

<style lang="scss" scoped></style>
