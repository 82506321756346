import Projects from '@/views/project/Projects'
import Project from '@/views/project/Project'
import AddProject from '@/views/project/AddProject'
import EditProject from '@/views/project/EditProject'
import { isAdmin, isChair, isLocalLead } from '@/router/helpers'

const routes = [
  {
    path: '/projects',
    name: 'projects',
    component: Projects
  },
  {
    path: '/projects/:id',
    name: 'project',
    props: true,
    component: Project
  },
  {
    path: '/projects/edit/:id',
    name: 'edit-project',
    props: true,
    component: EditProject,
    beforeEnter: (to, from, next) => {
      if (isChair() || isAdmin() || isLocalLead(to)) return next()
      return next('/unauthorized')
    }
  },
  {
    path: '/projects/add',
    name: 'add-project',
    props: true,
    component: AddProject
  }
]

export default routes
