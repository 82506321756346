import TaskforcesDB from '@/firebase/taskforces-db'

export default {
  /**
   * Fetch taskforces of current loggedin user
   */
  getTaskforces: async ({ commit }) => {
    const TaskforceDb = new TaskforcesDB()
    const taskforces = await TaskforceDb.readAll()
    commit('setTaskforces', taskforces)
  },

  /**
   * Create a taskforce for current loggedin user
   */
  createTaskforce: async ({ commit }, taskforce) => {
    const TaskforceDb = new TaskforcesDB()

    commit('setTaskforceCreationPending', true)
    const createdTaskforce = await TaskforceDb.create(taskforce)
    commit('addTaskforce', createdTaskforce)
    commit('setTaskforceCreationPending', false)
  },
  editTaskforce: async ({ dispatch, commit }, taskforce) => {
    const TaskforceDb = new TaskforcesDB()

    commit('addTaskforceEditPending', taskforce.id)
    await TaskforceDb.update(taskforce)
    commit('removeTaskforceEditPending', taskforce.id)
    dispatch('getTaskforces')
  },

  /**
   * Create a new taskforce for current loggedin user and reset taskforce name input
   */
  triggerAddTaskforceAction: ({ dispatch, state, commit }) => {
    if (state.taskforceNameToCreate === '') return
    if (state.taskforceDescToCreate === '') return
    if (state.taskforceChairsToCreate === '') return
    if (state.taskforceMembersToCreate === '') return
    const chairs = state.taskforceChairsToCreate.map(x => x.id)
    const members = state.taskforceMembersToCreate.map(x => x.id)
    const taskforce = {
      name: state.taskforceNameToCreate,
      description: state.taskforceDescToCreate,
      chairs,
      members
    }

    commit('setTaskforceNameToCreate', '')
    commit('setTaskforceDescToCreate', '')
    commit('setTaskforceChairsToCreate', '')
    commit('setTaskforceMembersToCreate', '')
    dispatch('createTaskforce', taskforce)
  },

  /**
   * Delete a user taskforce from its id
   */
  deleteTaskforce: async ({ commit, getters }, taskforceId) => {
    if (getters.isTaskforceDeletionPending(taskforceId)) return

    const TaskforceDb = new TaskforcesDB()

    commit('addTaskforceDeletionPending', taskforceId)
    await TaskforceDb.delete(taskforceId)
    commit('removeTaskforceById', taskforceId)
    commit('removeTaskforceDeletionPending', taskforceId)
  }
}
