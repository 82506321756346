export default {
  /* University input name */
  setUniversityNameToCreate: (state, universityNameToCreate) =>
    (state.universityNameToCreate = universityNameToCreate),
  setUniversityPictureToCreate: (state, universityPictureToCreate) =>
    (state.universityPictureToCreate = universityPictureToCreate),
  setUniversityDescToCreate: (state, universityDescToCreate) =>
    (state.universityDescToCreate = universityDescToCreate),
  setUniversityCityToCreate: (state, universityCityToCreate) =>
    (state.universityCityToCreate = universityCityToCreate),
  setUniversityCountryToCreate: (state, universityCountryToCreate) =>
    (state.universityCountryToCreate = universityCountryToCreate),
  setUniversityRegionToCreate: (state, universityRegionToCreate) =>
    (state.universityRegionToCreate = universityRegionToCreate),
  setUniversityContactToCreate: (state, universityContactToCreate) =>
    (state.universityContactToCreate = universityContactToCreate),
  setUniversityWebsiteToCreate: (state, universityWebsiteToCreate) =>
    (state.universityWebsiteToCreate = universityWebsiteToCreate),
  setUniversityTopicsToCreate: (state, universityTopicsToCreate) =>
    (state.universityTopicsToCreate = universityTopicsToCreate),
  setUniversityNameToEdit: (state, universityNameToEdit) =>
    (state.universityNameToEdit = universityNameToEdit),
  setUniversityPictureToEdit: (state, universityPictureToEdit) =>
    (state.universityPictureToEdit = universityPictureToEdit),
  setUniversityDescToEdit: (state, universityDescToEdit) =>
    (state.universityDescToEdit = universityDescToEdit),
  setUniversityCityToEdit: (state, universityCityToEdit) =>
    (state.universityCityToEdit = universityCityToEdit),
  setUniversityCountryToEdit: (state, universityCountryToEdit) =>
    (state.universityCountryToEdit = universityCountryToEdit),
  setUniversityRegionToEdit: (state, universityRegionToEdit) =>
    (state.universityRegionToEdit = universityRegionToEdit),
  setUniversityContactToEdit: (state, universityContactToEdit) =>
    (state.universityContactToEdit = universityContactToEdit),
  setUniversityWebsiteToEdit: (state, universityWebsiteToEdit) =>
    (state.universityWebsiteToEdit = universityWebsiteToEdit),

  /* Universities */
  setUniversities: (state, universities) => (state.universities = universities),
  addUniversity: (state, university) => state.universities.push(university),
  removeUniversityById: (state, universityId) => {
    const index = state.universities.findIndex(
      university => university.id === universityId
    )
    state.universities.splice(index, 1)
  },

  setUniversityCreationPending: (state, truth) =>
    (state.universityCreationPending = truth),

  /* Universities deletion */
  addUniversityDeletionPending: (state, universityId) =>
    state.universityDeletionPending.push(universityId),
  removeUniversityDeletionPending: (state, universityId) => {
    const index = state.universityDeletionPending.indexOf(universityId)
    state.universityDeletionPending.splice(index, 1)
  },

  /* Universities edit */
  addUniversityEditPending: (state, universityId) =>
    state.universityEditPending.push(universityId),
  removeUniversityEditPending: (state, universityId) => {
    const index = state.universityEditPending.indexOf(universityId)
    state.universityEditPending.splice(index, 1)
  }
}
