<template>
  <div class="mw8 center">
    <edit-resource
      v-if="currentResource"
      :resource="currentResource"
    ></edit-resource>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditResource from '@/components/resource/EditResource'

export default {
  components: { EditResource },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('resources', ['getResourceById']),
    currentResource() {
      return this.getResourceById(this.id)
    }
  }
}
</script>
