export default {
  /* User input name */
  setUserNameToCreate: (state, userNameToCreate) =>
    (state.userNameToCreate = userNameToCreate),
  setUserEmailToCreate: (state, userEmailToCreate) =>
    (state.userEmailToCreate = userEmailToCreate),
  setUserPictureToCreate: (state, userPictureToCreate) =>
    (state.userPictureToCreate = userPictureToCreate),
  setUserBioToCreate: (state, userBioToCreate) =>
    (state.userBioToCreate = userBioToCreate),
  setUserUniversityToCreate: (state, userUniversityToCreate) =>
    (state.userUniversityToCreate = userUniversityToCreate),
  setUserTitleToCreate: (state, userTitleToCreate) =>
    (state.userTitleToCreate = userTitleToCreate),
  setUserContactToCreate: (state, userContactToCreate) =>
    (state.userContactToCreate = userContactToCreate),
  setUserTopicsToCreate: (state, userTopicsToCreate) =>
    (state.userTopicsToCreate = userTopicsToCreate),
  setUserNameToEdit: (state, userNameToEdit) =>
    (state.userNameToEdit = userNameToEdit),
  setUserEmailToEdit: (state, userEmailToEdit) =>
    (state.userEmailToEdit = userEmailToEdit),
  setUserPictureToEdit: (state, userPictureToEdit) =>
    (state.userPictureToEdit = userPictureToEdit),
  setUserBioToEdit: (state, userBioToEdit) =>
    (state.userBioToEdit = userBioToEdit),
  setUserUniversityToEdit: (state, userUniversityToEdit) =>
    (state.userUniversityToEdit = userUniversityToEdit),
  setUserTitleToEdit: (state, userTitleToEdit) =>
    (state.userTitleToEdit = userTitleToEdit),
  setUserContactToEdit: (state, userContactToEdit) =>
    (state.userContactToEdit = userContactToEdit),
  setUserTopicsToEdit: (state, userTopicsToEdit) =>
    (state.userTopicsToEdit = userTopicsToEdit),

  /* Users */
  setUsers: (state, users) => (state.users = users),
  addUser: (state, user) => state.users.push(user),
  removeUserById: (state, userId) => {
    const index = state.users.findIndex(user => user.id === userId)
    state.users.splice(index, 1)
  },

  /* Users deletion */
  addUserDeletionPending: (state, userId) =>
    state.userDeletionPending.push(userId),
  removeUserDeletionPending: (state, userId) => {
    const index = state.userDeletionPending.indexOf(userId)
    state.userDeletionPending.splice(index, 1)
  },

  /* Users edit */
  addUserEditPending: (state, userId) => state.userEditPending.push(userId),
  removeUserEditPending: (state, userId) => {
    const index = state.userEditPending.indexOf(userId)
    state.userEditPending.splice(index, 1)
  }
}
