<template>
  <div id="app" class="sans-serif relative">
    <slide v-if="small" id="slide" :close-on-navigation="true">
      <router-link
        v-for="l in links"
        v-show="showLink(l)"
        :key="l.route"
        class="mh2 pv2 db ph2 link no-underline white"
        :to="l.route"
        >{{ l.name }}</router-link
      >
      <router-link
        v-if="showNav && networkOnLine"
        class="mh2 pv2 db ph2 link no-underline white"
        to="/profile"
      >
        My Account
      </router-link>
      <div class="mh2 flex">
        <div v-if="!showNav && networkOnLine" class="nav-item">
          <router-link
            class="mh2 pv2 db ph2 link no-underline white"
            to="/login"
            >Login</router-link
          >
        </div>

        <div v-if="showNav && networkOnLine" class="">
          <a
            class="mh2 pv2 db ph2 dark-red hover-bg-dark-red hover-white link no-underline pointer ba bw1 b--red"
            @click="logout()"
          >
            Logout
          </a>
        </div>
      </div>
    </slide>
    <nav-bar></nav-bar>
    <div v-if="isUserLoggedIn && currentUser" class="w-100 center">
      <div v-if="currentUser.firstRun" class="flex justify-center ">
        <div class="ma1 pa2 bg-light-yellow">
          Please fill out your user profile.
          <router-link
            class="pa1 no-underline bb bw1 b--blue navy hover-bg-navy hover-white bg-animate"
            :to="{ name: 'edit-member', params: { id: currentUser.id } }"
            >Click here to start</router-link
          >.
        </div>
      </div>
      <user-notification
        v-for="(n, i) in currentUser.notifications"
        :key="n"
        :idx="i"
        @dismiss="dismissNotification($event)"
        >{{ n }}</user-notification
      >
    </div>
    <div class="w-100 relative">
      <transition
        enter-active-class="animate__animated animate__fadeIn animate__faster"
        leave-active-class="dn"
      >
        <router-view class="ph2" />
      </transition>
    </div>

    <new-content-available-toastr
      v-if="newContentAvailable && !refreshingApp"
      class="new-content-available-toastr"
      :refreshing-app="refreshingApp"
      @refresh="serviceWorkerSkipWaiting"
    ></new-content-available-toastr>
    <apple-add-to-home-screen-modal
      v-if="showAddToHomeScreenModalForApple"
      class="apple-add-to-home-screen-modal"
      @close="closeAddToHomeScreenModalForApple(false)"
    >
    </apple-add-to-home-screen-modal>
  </div>
</template>
<script>
import firebase from 'firebase/app'
import UserNotification from '@/components/userNotification'
import NavBar from '@/components/NavBar'
import NewContentAvailableToastr from '@/components/NewContentAvailableToastr'
import AppleAddToHomeScreenModal from '@/components/AppleAddToHomeScreenModal'
import { mapState, mapActions, mapGetters } from 'vuex'
import { Slide } from 'vue-burger-menu'
import navLinks from '@/assets/navLinks'

export default {
  components: {
    NavBar,
    NewContentAvailableToastr,
    AppleAddToHomeScreenModal,
    Slide,
    UserNotification
  },
  data() {
    return {
      small: true,
      width: null,
      links: navLinks
    }
  },
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapGetters('app', ['newContentAvailable']),
    ...mapGetters('users', ['getUserById']),
    ...mapState('authentication', ['user']),
    ...mapState('app', [
      'networkOnLine',
      'showAddToHomeScreenModalForApple',
      'refreshingApp'
    ]),
    currentUser() {
      if (this.user) {
        return this.getUserById(this.user.id)
      }
      return null
    },
    showNav() {
      return (
        this.isUserLoggedIn &&
        !this.$route.name.includes('baduser') &&
        !this.$route.name.includes('login')
      )
    }
  },
  watch: {
    width() {
      if (this.width < 1000) {
        this.small = true
      } else {
        this.small = false
      }
    }
  },
  created() {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapActions('app', [
      'closeAddToHomeScreenModalForApple',
      'serviceWorkerSkipWaiting'
    ]),
    showLink(l) {
      if (this.showNav) {
        if (!l.adminOnly || this.currentUser.admin === 'true') {
          return true
        }
      }

      return false
    },
    async logout() {
      await firebase.auth().signOut()
    },
    onResize() {
      setInterval(() => {
        this.width = window.innerWidth
      }, 100)
    }
  }
}
</script>

<style>
@import 'theme/main.css';
</style>
