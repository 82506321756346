<template>
  <div class="user-action-bar">
    <h1>Edit Member Permissions</h1>

    <label for="user-admin" class="b mv2 dib"
      >Is {{ user.name }} an administrator?</label
    >
    <p class="i">
      Administrators have full read and write access to all of IAJESdb.
    </p>
    <select id="user-admin" v-model.trim="localAdmin">
      <option value="true">YES</option>
      <option value="false" selected>NO</option>
    </select>

    <save-changes :invalid="false" @save-changes="saveChanges()"></save-changes>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import firebase from 'firebase/app'
import SaveChanges from '@/components/saveChanges'

export default {
  components: {
    SaveChanges
  },
  props: {
    user: Object
  },
  data() {
    return {
      fbUser: null,
      localAdmin: null
    }
  },
  computed: {
    ...mapGetters('users', ['getUserById']),
    ...mapGetters('admins', ['getAdminById']),
    ...mapState('admins', ['admins']),
    currentUser() {
      const user = this.fbUser
      return this.getUserById(user.uid)
    }
  },
  created() {
    this.fbUser = firebase.auth().currentUser

    this.localAdmin = this.getAdminById(this.user.id) !== false
  },

  methods: {
    ...mapActions('admins', ['createAdmin', 'deleteAdmin']),

    saveChanges() {
      const isAdminOnDb = this.getAdminById(this.user.id) !== false

      if (this.localAdmin && !isAdminOnDb) {
        this.createAdmin({ id: this.user.id })
      }

      if (this.localAdmin === 'false' && isAdminOnDb) {
        this.deleteAdmin(this.user.id)
      }

      this.$router.push({
        name: 'member',
        params: { id: this.user.id }
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
