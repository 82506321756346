import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      isLocalChairsSet: false,
      isTaskforcesSet: false
    }
  },
  computed: {
    ...mapGetters('users', ['getUserById']),
    ...mapGetters('chairs', ['getChairById']),
    ...mapGetters('taskforces', ['isTaskforceChair']),
    ...mapState('authentication', ['user']),
    ...mapState('chairs', ['chairs']),
    ...mapState('taskforces', ['taskforces'])
  },
  watch: {
    taskforces() {
      if (this.taskforces) {
        this.isTaskforcesSet = true
      } else {
        this.isTaskforcesSet = false
      }
    },
    localTaskforce() {
      if (this.localTaskforce) {
        this.isLocalChairsSet = true
      } else {
        this.isLocalChairsSet = false
      }
    }
  },
  methods: {
    ...mapActions('chairs', ['createChair', 'deleteChair']),
    checkChairs(ids) {
      ids.forEach(i => this.checkChairDb(i))
    },
    checkChairDb(id) {
      const isChairInState = this.isTaskforceChair(id)
      const isChairOnDb = this.getChairById(id) !== false

      if (isChairInState && !isChairOnDb) {
        this.createChair({ id })
      }

      if (!isChairInState && isChairOnDb) {
        this.deleteChair(id)
      }
    }
  }
}
