export default {
  offers: null,
  offerNameToCreate: '',
  offerOwner: '',
  offerDescToCreate: '',
  offerTopicsToCreate: '',
  offerExpiryToCreate: '',
  offerNameToEdit: '',
  offerDescToEdit: '',
  offerTopicsToEdit: '',
  offerExpiryToEdit: '',
  offerDeletionPending: [],
  offerCreationPending: false,
  offerEditPending: []
}
