<template>
  <div class="mw8 center">
    <div class="flex justify-end mt3">
      <router-link
        v-if="networkOnLine && (isTaskforceChairOrAbove || isLocalUser)"
        class="bw1 ba br-1 b--blue link white bg-navy pa2 dim pointer dib"
        :to="{ name: 'edit-member', params: { id: luser.id } }"
      >
        <i class="las la-edit"></i>
        {{ isUserEditPending(luser.id) ? 'edit in progress...' : 'edit' }}
      </router-link>
      <router-link
        v-if="networkOnLine && isAdmin"
        class="bw1 ba br-1 b--gold link white bg-purple pa2 dim pointer dib ml2"
        :to="{ name: 'edit-member-permissions', params: { id: luser.id } }"
      >
        <i class="las la-user-lock"></i>
        {{
          isUserEditPending(luser.id)
            ? 'edit in progress...'
            : 'edit permissions'
        }}
      </router-link>
      <div
        v-if="networkOnLine && (isTaskforceChairOrAbove || isLocalUser)"
        class="bw1 ba br-1 b--red link white bg-dark-red pa2 dim pointer dib ml2"
        @click="saveChanges(luser.id)"
      >
        <i class="las la-trash"></i>
        {{
          isUserDeletionPending(luser.id) ? 'delete in progress...' : 'delete'
        }}
      </div>
    </div>
    <div class="flex bw1 bb b-grey mb3">
      <div class="">
        <img
          v-if="luser.picture"
          class="h4 mr2"
          :src="
            'https://res.cloudinary.com/iajesdb/image/upload/ar_1,c_crop,f_auto,g_face,q_auto:good,z_0.7/c_scale,w_200,ar_1/' +
              luser.picture
          "
          :alt="luser.name + ' - ' + 'profile picture'"
          :title="luser.name + ' - ' + luser.title"
        />
        <img
          v-if="!luser.picture"
          class="h4 mr2"
          src="/img/default.png"
          :alt="luser.name + ' - ' + 'profile picture'"
          :title="luser.name + ' - ' + luser.title"
        />
      </div>
      <div>
        <h1 class="mt0">{{ luser.name }}</h1>
        <h2 class="f3 ">{{ luser.title }}</h2>
        <h2 v-if="luser.firstRun" class="f3 i">
          This user has not filled out their profile yet.
        </h2>
        <div v-if="luser.university" class="db mb2">
          <span class="b">University:</span>
          <span v-if="luser.university.length === 0" class="pl2 i b"
            >None at this time</span
          >
          <div class="dib mb3">
            <ul class="list flex pa0 ma0">
              <li class="ml2">
                <router-link
                  class="pa1 no-underline bb bw1 b--blue navy hover-bg-navy hover-white bg-animate flex flex-nowrap"
                  :to="{
                    name: 'university',
                    params: { id: luser.university.id }
                  }"
                >
                  <div class="">
                    {{ luser.university.name }}
                  </div>
                  <i class="las la-angle-right db f4 b"></i>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div v-if="luser.topics" class="db b mb2">
      Areas of Specialization:
      <span v-if="luser.topics.length === 0" class="pl2 i"
        >None at this time</span
      >
    </div>
    <div v-if="luser.topics" class="flex">
      <ul class="list flex flex-wrap pa0 ma0">
        <li v-for="u in luser.topics" :key="u.id" class="ml2">
          <router-link
            class="pa1 no-underline bb bw1 b--blue navy hover-bg-navy hover-white bg-animate flex flex-nowrap"
            :to="{ name: 'topic', params: { id: u.id } }"
            :v-if="getTopicById(u.id)"
            >{{ getTopicById(u.id).name }}</router-link
          >
        </li>
      </ul>
    </div>

    <h3 v-if="luser.contact.length > 0" class="">Contact Information</h3>
    <div v-if="luser.contact" class="">
      <div v-for="c in luser.contact" :key="c.id" class="flex">
        <div class="w-30">{{ c.type | capitalize }}:</div>
        <div v-if="c.type == 'email'" class="pl2 w-70">
          <a :href="'mailto:' + c.contact" target="_blank">{{ c.contact }}</a>
        </div>
        <div v-if="c.type == 'phone'" class="pl2 w-70">
          <a :href="'tel:' + phone(c.contact)" target="_blank">
            {{ c.contact }}
          </a>
        </div>
        <div v-if="c.type != 'phone' && c.type != 'email'" class="pl2 w-70">
          <a :href="c.contact" target="_blank">{{ c.contact }}</a>
        </div>
      </div>
    </div>
    <!-- eslint-disable -->
    <div v-html="highlightedDescription"></div>
    <!-- eslint-enable -->
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import csReplace from 'preserve-case'
import adminHelper from '@/mixins/adminHelper'

export default {
  filters: {
    capitalize(value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  mixins: [adminHelper],
  props: {
    luser: Object
  },
  computed: {
    ...mapGetters('users', ['isUserDeletionPending', 'isUserEditPending']),
    ...mapGetters('topics', ['getTopicById']),
    ...mapGetters('users', ['getUserById']),
    ...mapGetters('taskforces', ['isTaskforceChair']),
    ...mapState('authentication', ['user']),
    ...mapState('users', ['users']),
    ...mapState('app', ['networkOnLine']),
    isLocalUser() {
      return this.luser.id === this.user.id
    },
    query() {
      return this.$route.params.q || ''
    },
    highlightedDescription() {
      if (this.query.length < 4 || this.luser.bio === undefined) {
        return this.luser.bio
      }

      let hDesc = csReplace.all(
        this.luser.bio,
        this.query,
        `<::${this.query}::>`
      )

      hDesc = hDesc.replaceAll('<::', `<span class='bg-yellow'>`)

      hDesc = hDesc.replaceAll('::>', '</span>')

      return hDesc
    }
  },
  methods: {
    ...mapActions('users', ['deleteUser']),
    phone(value) {
      if (!value) return ''
      value = value.toString()
      return value.replace(/[^0-9]/g, '')
    },
    saveChanges(id) {
      this.$dialog
        .confirm('Are you sure? Deleted records cannot be recovered.')
        .then(() => {
          this.deleteUser(id)
          this.$router.push({
            name: 'members'
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped></style>
