import state from './taskforces.state'
import mutations from './taskforces.mutations'
import actions from './taskforces.actions'
import getters from './taskforces.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
