<template>
  <header
    class="bb bw1 bg-gray-light flex"
    :class="{
      offline: !networkOnLine,
      'justify-between': !small,
      'justify-center': small,
      'bg-navy': small
    }"
  >
    <router-link class="dtc pv2 flex dim pointer bg-navy pl2" to="/home">
      <img alt="logo" class="h2 ml2 mt2" src="@/assets/logo-white.png" />
      <span class="mh2 pv2 db white no-underline mt2">{{ appTitle }}</span>
    </router-link>
    <div v-if="!small" class="db pv2">
      <nav class="pv2">
        <div class="flex justify-end">
          <router-link
            v-for="l in links"
            v-show="showLink(l)"
            :key="l.route"
            class="mh2 pv2 db ph2 navy hover-bg-navy hover-white link no-underline pointer ba bw1 b--blue"
            :to="l.route"
            >{{ l.name }}</router-link
          >
          <router-link
            v-if="!showNav && networkOnLine"
            class="mh2 pv2 db ph2 mr2 navy hover-bg-navy hover-white link no-underline pointer ba bw1 b--blue"
            to="/login"
            >Login</router-link
          >
          <router-link
            v-if="showNav && networkOnLine"
            class="mh2 pv2 db ph2 navy hover-bg-navy hover-white link no-underline pointer ba bw1 b--blue"
            to="/profile"
          >
            My Account
          </router-link>
          <a
            v-if="showNav && networkOnLine"
            class="mh2 pv2 db ph2 mr2 dark-red hover-bg-dark-red hover-white link no-underline pointer ba bw1 b--red"
            @click="logout()"
          >
            Logout
          </a>
        </div>

        <div v-if="!networkOnLine" class="nav-item offline-label">Offline</div>
      </nav>
    </div>
  </header>
</template>

<script>
import firebase from 'firebase/app'
import { mapGetters, mapState } from 'vuex'
import navLinks from '@/assets/navLinks'

export default {
  data() {
    return {
      small: true,
      width: null,
      links: navLinks
    }
  },
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle']),
    ...mapGetters('users', ['getUserById']),
    ...mapGetters('admins', ['getAdminById']),
    ...mapState('admins', ['admins']),
    showNav() {
      return (
        this.isUserLoggedIn &&
        !this.$route.name.includes('baduser') &&
        !this.$route.name.includes('login')
      )
    }
  },
  watch: {
    width() {
      if (this.width < 1000) {
        this.small = true
      } else {
        this.small = false
      }
    }
  },
  created() {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    async logout() {
      await firebase.auth().signOut()
    },
    showLink(l) {
      if (this.showNav) {
        if (!l.adminOnly || this.getAdminById(this.user.id)) {
          return true
        }
      }

      return false
    },
    onResize() {
      setInterval(() => {
        this.width = window.innerWidth
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped></style>
