<template>
  <div class="mw8 center">
    <h1 class="requests-page-title">Requests</h1>
    <request-list class="request-list"></request-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RequestList from '@/components/request/RequestList'

export default {
  components: { RequestList },
  computed: mapState('app', ['networkOnLine'])
}
</script>

<style lang="scss" scoped></style>
