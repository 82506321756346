import ProjectsDB from '@/firebase/projects-db'

export default {
  /**
   * Fetch projects of current loggedin user
   */
  getProjects: async ({ commit }) => {
    const ProjectDb = new ProjectsDB()
    const projects = await ProjectDb.readAll()
    commit('setProjects', projects)
  },

  /**
   * Create a project for current loggedin user
   */
  createProject: async ({ commit }, project) => {
    const ProjectDb = new ProjectsDB()

    commit('setProjectCreationPending', true)
    const createdProject = await ProjectDb.create(project)
    commit('addProject', createdProject)
    commit('setProjectCreationPending', false)
  },
  editProject: async ({ dispatch, commit }, project) => {
    const ProjectDb = new ProjectsDB()

    commit('addProjectEditPending', project.id)
    await ProjectDb.update(project)
    commit('removeProjectEditPending', project.id)
    dispatch('getProjects')
  },

  /**
   * Create a new project for current loggedin user and reset project name input
   */
  triggerAddProjectAction: ({ dispatch, state, commit }) => {
    if (state.projectNameToCreate === '') return
    if (state.projectDescToCreate === '') return
    if (
      state.projectStatusToCreate !== 'proposal' &&
      state.projectTaskforceToCreate === []
    )
      return
    if (state.projectLeadsToCreate === []) return
    if (state.projectTopicsToCreate === []) return
    if (state.projectStatusToCreate === '') return

    const leads = state.projectLeadsToCreate.map(x => x.id)

    const project = {
      name: state.projectNameToCreate,
      description: state.projectDescToCreate,
      taskforces: state.projectTaskforcesToCreate,
      leads,
      topics: state.projectTopicsToCreate,
      status: state.projectStatusToCreate
    }

    commit('setProjectNameToCreate', '')
    commit('setProjectDescToCreate', '')
    commit('setProjectTaskforcesToCreate', [])
    commit('setProjectLeadsToCreate', [])
    commit('setProjectTopicsToCreate', [])
    commit('setProjectStatusToCreate', '')
    dispatch('createProject', project)
  },

  /**
   * Delete a user project from its id
   */
  deleteProject: async ({ commit, getters }, projectId) => {
    if (getters.isProjectDeletionPending(projectId)) return

    const ProjectDb = new ProjectsDB()

    commit('addProjectDeletionPending', projectId)
    await ProjectDb.delete(projectId)
    commit('removeProjectById', projectId)
    commit('removeProjectDeletionPending', projectId)
  }
}
