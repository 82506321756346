export default {
  /* Project input name */
  setProjectNameToCreate: (state, projectNameToCreate) =>
    (state.projectNameToCreate = projectNameToCreate),
  setProjectDescToCreate: (state, projectDescToCreate) =>
    (state.projectDescToCreate = projectDescToCreate),
  setProjectTaskforcesToCreate: (state, projectTaskforcesToCreate) =>
    (state.projectTaskforcesToCreate = projectTaskforcesToCreate),
  setProjectLeadsToCreate: (state, projectLeadsToCreate) =>
    (state.projectLeadsToCreate = projectLeadsToCreate),
  setProjectTopicsToCreate: (state, projectTopicsToCreate) =>
    (state.projectTopicsToCreate = projectTopicsToCreate),
  setProjectStatusToCreate: (state, projectStatusToCreate) =>
    (state.projectStatusToCreate = projectStatusToCreate),
  setProjectNameToEdit: (state, projectNameToEdit) =>
    (state.projectNameToEdit = projectNameToEdit),
  setProjectDescToEdit: (state, projectDescToEdit) =>
    (state.projectDescToEdit = projectDescToEdit),
  setProjectTaskforcesToEdit: (state, projectTaskforcesToEdit) =>
    (state.projectTaskforcesToEdit = projectTaskforcesToEdit),
  setProjectLeadsToEdit: (state, projectLeadsToEdit) =>
    (state.projectLeadsToEdit = projectLeadsToEdit),
  setProjectTopicsToEdit: (state, projectTopicsToEdit) =>
    (state.projectTopicsToEdit = projectTopicsToEdit),
  setProjectStatusToEdit: (state, projectStatusToEdit) =>
    (state.projectStatusToEdit = projectStatusToEdit),

  /* Projects */
  setProjects: (state, projects) => (state.projects = projects),
  addProject: (state, project) => state.projects.push(project),
  removeProjectById: (state, projectId) => {
    const index = state.projects.findIndex(project => project.id === projectId)
    state.projects.splice(index, 1)
  },

  /* Projects deletion */
  addProjectDeletionPending: (state, projectId) =>
    state.projectDeletionPending.push(projectId),
  removeProjectDeletionPending: (state, projectId) => {
    const index = state.projectDeletionPending.indexOf(projectId)
    state.projectDeletionPending.splice(index, 1)
  },

  /* Projects edit */
  addProjectEditPending: (state, projectId) =>
    state.projectEditPending.push(projectId),
  removeProjectEditPending: (state, projectId) => {
    const index = state.projectEditPending.indexOf(projectId)
    state.projectEditPending.splice(index, 1)
  }
}
