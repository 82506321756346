<template>
  <div>
    <p v-if="taskforces === null" class="i">Loading...</p>
    <p v-if="taskforces && !taskforces.length" class="i">
      You don't have any task forces yet
    </p>
    <div class="mb0 relative">
      <i
        class="las la-search absolute left-1 black z-max mt3 pt1 f3 dn db-ns"
      ></i>
      <input
        id="taskforce-search"
        v-model.trim="query"
        type="text"
        placeholder="Search all task forces"
        class="1 b--mid-gray pl5 pa3 w-100 f3"
      />
      <i
        v-show="query.length > 0"
        class="las la-backspace absolute right-1 highlight pointer dim z-max mt3 pt1 f3 dn db-ns"
        @click="clearQuery()"
      ></i>
      <label for="taskforce-search"></label>
    </div>
    <filter-bar
      :current="filteredTaskforces.length"
      :total="taskforces.length"
      name="task force"
    ></filter-bar>
    <taskforce-item
      v-for="(taskforce, index) in filteredTaskforces"
      :key="taskforce.id"
      class="taskforce-row"
      :query="query"
      :index="index"
      :is-taskforce-deletion-pending="isTaskforceDeletionPending(taskforce.id)"
      :disable-actions="!networkOnLine"
      :data="taskforce"
      @deleteTaskforce="deleteTaskforce"
    ></taskforce-item>
    <div v-if="filteredTaskforces.length === 0" class="mid-gray i center tc f3">
      <i class="las la-exclamation-triangle"></i> No Search Results Found
    </div>
  </div>
</template>

<script>
import TaskforceItem from '@/components/taskforce/TaskforceItem'
import { mapState, mapActions, mapGetters } from 'vuex'
import elasticlunr from 'elasticlunr'
import FilterBar from '@/components/filterBar'

export default {
  components: { TaskforceItem, FilterBar },
  data() {
    return {
      query: '',
      searchIndex: null,
      searchRes: []
    }
  },
  computed: {
    ...mapGetters('taskforces', ['isTaskforceDeletionPending']),
    ...mapState('taskforces', ['taskforces']),
    ...mapState('app', ['networkOnLine']),
    filteredTaskforces() {
      if (this.taskforces) {
        const arr = this.taskforces.filter(p => {
          return this.visible(p)
        })

        return arr
      }

      return []
    }
  },
  watch: {
    taskforces() {
      this.buildIndex()
    },
    query() {
      if (this.searchIndex) {
        this.searchRes = this.searchIndex.search(this.query, { expand: true })
      }
    }
  },
  created() {
    this.buildIndex()
  },
  methods: {
    ...mapActions('taskforces', ['deleteTaskforce']),
    buildIndex() {
      if (this.taskforces) {
        this.searchIndex = elasticlunr(function f() {
          this.addField('name')
          this.addField('description')
          this.addField('chairs')
          this.addField('members')
          this.setRef('id')
        })

        this.searchIndex.saveDocument(false)
        const i = this.taskforces.map(x => {
          return {
            name: x.name,
            description: x.description,
            chairs: this.joinNames(x.chairs),
            members: this.joinNames(x.members),
            id: x.id
          }
        })

        i.forEach(x => {
          this.searchIndex.addDoc(x)
        })
      }
    },
    clearQuery() {
      this.query = ''
    },
    visible(tf) {
      let inSearch = false

      if (this.query.length < 4) {
        inSearch = true
      } else {
        this.searchRes.forEach(r => {
          if (r.ref === tf.id) {
            inSearch = true
          }
        })
      }

      return inSearch
    },
    joinNames(arr) {
      const reducerFun = (acc, curr) => `${acc} ${curr.name}`

      return arr.reduce(reducerFun, '')
    }
  }
}
</script>

<style lang="scss" scoped></style>
