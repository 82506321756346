/* eslint-disable */
import axios from 'axios'

export default {
  methods: {
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'ml_default');
      const cloudinaryUploadURL = `https://api.cloudinary.com/v1_1/iajesdb/upload`;
      const requestObj = {
        url: cloudinaryUploadURL,
        method: 'POST',
        data: formData
      };
      axios(requestObj)
        .then(response => {
          const { url } = response.data;
          Editor.insertEmbed(cursorLocation, 'image', url);
          resetUploader();
        })
        .catch(error => {
          this.errors.push(error);
        });
    }
  }
}
