<template>
  <div class="page-wrapper">
    <taskforce-detail
      v-if="currentTaskforce"
      :taskforce="currentTaskforce"
    ></taskforce-detail>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TaskforceDetail from '@/components/taskforce/TaskforceDetail'

export default {
  components: { TaskforceDetail },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('taskforces', ['getTaskforceById']),
    currentTaskforce() {
      return this.getTaskforceById(this.id)
    }
  }
}
</script>
