<template>
  <div>
    <p v-if="users === null" class="i">Loading...</p>
    <p v-if="users && !users.length" class="i">
      You don't have any members yet
    </p>
    <div class="flex justify-between flex-wrap mt1 mb2">
      <div class="i f3 pt1">Filter Members</div>
      <div class="flex w-50-l justify-end">
        <div
          class="mr2 shadow-1 bg-white hover-bg-navy hover-white bg-animate pointer flex"
          @click="toggleFilter('contacts')"
        >
          <div class="br bw1 b--navy dtc tc ph3 pt1">
            <i
              class="las la-check b f1 ta"
              :class="{ 'o-0': !filter.includes('contacts') }"
            ></i>
          </div>
          <div class="tl ph2 flex flex-column justify-center">
            <div>Show University<br />Contacts Only</div>
          </div>
        </div>
        <div
          class="mr2 shadow-1 bg-white hover-bg-navy hover-white bg-animate pointer flex"
          @click="toggleFilter('chairs')"
        >
          <div class="br bw1 b--navy dtc tc ph3 pt1">
            <i
              class="las la-check b f1 ta"
              :class="{ 'o-0': !filter.includes('chairs') }"
            ></i>
          </div>
          <div class="tl ph2 flex flex-column justify-center">
            <div>Show Task Force<br />Chairs Only</div>
          </div>
        </div>
      </div>
    </div>
    <user-list-export :users="filteredUsers"></user-list-export>
    <div class="mb0 relative w-100">
      <i
        class="las la-search absolute left-1 black z-max mt3 pt1 f3 dn db-ns"
      ></i>
      <input
        id="members-search"
        v-model.trim="query"
        type="text"
        placeholder="Search all members"
        class="1 b--mid-gray pl5 pa3 w-100 f3"
      />
      <i
        v-show="query.length > 0"
        class="las la-backspace absolute right-1 highlight pointer dim z-max mt3 pt1 f3 dn db-ns"
        @click="clearQuery()"
      ></i>
      <label for="members-search"></label>
    </div>
    <div class="pa2 bg-light-yellow w-100">
      This will search the entire member profile, including name, university,
      task forces, or keywords in their bio.
    </div>
    <filter-bar
      :current="filteredUsers.length"
      :total="users.length"
      name="member"
    ></filter-bar>
    <div class="flex justify-start flex-wrap">
      <user-item
        v-for="(user, index) in filteredUsers"
        :key="user.id"
        class="db"
        :class="{ 'w-20': !small, 'w-50': small }"
        :query="query"
        :index="index"
        :is-user-deletion-pending="isUserDeletionPending(user.id)"
        :disable-actions="!networkOnLine"
        :data="user"
        :uni="filter[0] === 'contact'"
        @deleteUser="deleteUser"
      ></user-item>
    </div>
    <div v-if="filteredUsers.length === 0" class="mid-gray i center tc f3">
      <i class="las la-exclamation-triangle"></i> No Search Results Found
    </div>
  </div>
</template>

<script>
import UserItem from '@/components/user/UserItem'
import resizeMixin from '@/mixins/resizeMixin'
import { mapState, mapActions, mapGetters } from 'vuex'
import elasticlunr from 'elasticlunr'
import FilterBar from '@/components/filterBar'
import UserListExport from '@/components/user/UserListExport'

export default {
  components: { UserItem, FilterBar, UserListExport },
  mixins: [resizeMixin],
  data() {
    return {
      query: '',
      searchIndex: null,
      searchRes: [],
      filter: []
    }
  },
  computed: {
    ...mapGetters('users', ['isUserDeletionPending']),
    ...mapGetters('universities', ['getUniversityContactIds']),
    ...mapGetters('taskforces', ['isTaskforceChair']),
    ...mapState('users', ['users']),
    ...mapState('app', ['networkOnLine']),
    filteredUsers() {
      if (this.users) {
        const arr = this.users.filter(p => {
          return this.visible(p)
        })

        return arr.sort((a, b) => {
          const lnameA = this.getLastName(a.name)
          const lnameB = this.getLastName(b.name)
          const A = `${lnameA} ${this.getFirstName(a.name, lnameA)}`
          const B = `${lnameB} ${this.getFirstName(b.name, lnameB)}`

          if (A > B) {
            return 1
          }

          if (A < B) {
            return -1
          }

          return 0
        })
      }

      return []
    }
  },
  watch: {
    users() {
      this.buildIndex()
    },
    query() {
      if (this.searchIndex) {
        this.searchRes = this.searchIndex.search(this.query, { expand: true })
      }
    }
  },
  created() {
    this.buildIndex()
  },
  methods: {
    ...mapActions('users', ['deleteUser']),
    getLastName(str) {
      const noSJ = str
        .trim()
        .toLowerCase()
        .replace(/,?\s*sj/, '')
        .trim()
      const lastSpace = noSJ.lastIndexOf(' ')

      return noSJ.substr(lastSpace + 1)
    },
    getFirstName(str, lastName) {
      return str
        .trim()
        .toLowerCase()
        .replace(/,?\s*sj/, '')
        .replace(lastName, '')
        .trim()
    },
    buildIndex() {
      if (this.users) {
        this.searchIndex = elasticlunr(function f() {
          this.addField('name')
          this.addField('bio')
          this.addField('title')
          this.addField('topics')
          this.addField('university')
          this.addField('contact')
          this.setRef('id')
        })

        this.searchIndex.saveDocument(false)
        const i = this.users.map(x => {
          let uniName = ''
          if (x.university) uniName = x.university.name

          return {
            name: x.name,
            bio: x.bio,
            title: x.title,
            university: uniName,
            topics: this.joinNames(x.topics),
            contact: this.joinContact(x.contact),
            id: x.id
          }
        })

        i.forEach(x => {
          this.searchIndex.addDoc(x)
        })
      }
    },
    clearQuery() {
      this.query = ''
    },
    visible(t) {
      let inSearch = false
      const inContacts = this.getUniversityContactIds.includes(t.id)
      const isChair = this.isTaskforceChair(t.id)

      if (this.query.length < 4) {
        inSearch = true
      } else {
        this.searchRes.forEach(r => {
          if (r.ref === t.id) {
            inSearch = true
          }
        })
      }

      if (this.filter[0] === 'contacts' && !inContacts) {
        return false
      }

      if (this.filter[0] === 'chairs' && !isChair) {
        return false
      }

      return inSearch
    },
    joinNames(arr) {
      if (!arr) return ''
      const reducerFun = (acc, curr) => `${acc} ${curr.name}`

      return arr.reduce(reducerFun, '')
    },
    joinContact(arr) {
      if (!arr) return ''
      const reducerFun = (acc, curr) => `${acc} ${curr.contact}`

      return arr.reduce(reducerFun, '')
    },
    toggleFilter(key) {
      const idx = this.filter.find(f => f === key)
      if (idx) {
        this.filter.splice(idx, 1)
      } else {
        this.filter = [key]
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
