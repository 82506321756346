import store from '@/store'
import { isNil } from 'lodash'

// Is logged in user an admin?
const isAdmin = () => {
  const { user } = store.state.authentication
  const getAdminById = store.getters['admins/getAdminById']
  if (user === null) return false
  return getAdminById(user.id) !== false
}

// Is logged in user a task force chair?
const isChair = () => {
  const { user } = store.state.authentication
  const isTaskforceChair = store.getters['taskforces/isTaskforceChair']
  if (user === null) return false

  return isTaskforceChair(user.id)
}

// Is logged in user a chair of the given task force?
const isLocalChair = to => {
  const { user } = store.state.authentication
  const getTaskforceById = store.getters['taskforces/getTaskforceById']
  const localTaskforce = getTaskforceById(to.params.id)

  if (user === null) return false

  const filteredChairs = localTaskforce.chairs.filter(u => {
    return u === user.id
  })

  return filteredChairs.length > 0
}

// Is logged in user a lead of the given project?
const isLocalLead = to => {
  const { user } = store.state.authentication
  const getProjectById = store.getters['projects/getProjectById']
  const localProject = getProjectById(to.params.id)

  if (user === null) return false

  const filteredLeads = localProject.leads.filter(u => {
    return u === user.id
  })

  return filteredLeads.length > 0
}

// Is the member editing their own profile?
const isMemberOwner = to => {
  const { user } = store.state.authentication

  if (user === null) return false

  return user.id === to.params.id
}

// Is the member the contact for the given university?
const isUnversityContact = to => {
  const { user } = store.state.authentication
  const getUniversityById = store.getters['universities/getUniversityById']
  const localUniversity = getUniversityById(to.params.id)
  if (user === null) return false
  if (isNil(localUniversity.contact)) return false
  return user.id === localUniversity.contact
}

export {
  isAdmin,
  isChair,
  isLocalChair,
  isLocalLead,
  isMemberOwner,
  isUnversityContact
}
