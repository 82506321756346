import { mapState } from 'vuex'

const taskforceOptions = {
  computed: {
    ...mapState('taskforces', ['taskforces']),
    taskforceOptions() {
      return this.taskforces.flatMap(x => {
        return { name: x.name, id: x.id }
      })
    }
  }
}

const topicOptions = {
  computed: {
    ...mapState('topics', ['topics']),
    topicOptions() {
      return this.topics.flatMap(x => {
        return { name: x.name, id: x.id }
      })
    }
  }
}

const universityOptions = {
  computed: {
    ...mapState('universities', ['universities']),
    universityOptions() {
      return this.universities.flatMap(x => {
        return { name: x.name, id: x.id }
      })
    }
  }
}

const userOptions = {
  computed: {
    ...mapState('users', ['users']),
    userOptions() {
      return this.users.flatMap(x => {
        return { name: x.name, id: x.id }
      })
    }
  }
}

const userOptionsByUniversity = {
  computed: {
    ...mapState('users', ['users'])
  },
  methods: {
    userOptionsByUniversity(uniId) {
      const arr = this.users.flatMap(x => {
        if (!x.university) return {}
        if (x.university.id === uniId) {
          return { name: x.name, id: x.id }
        }
        return {}
      })

      return arr.filter(o => Object.keys(o).length > 0)
    }
  }
}

export {
  taskforceOptions,
  topicOptions,
  universityOptions,
  userOptions,
  userOptionsByUniversity
}
