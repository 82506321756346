<template>
  <div class="mw8 center">
    <div class="flex justify-end mt3">
      <router-link
        v-if="networkOnLine && (isTaskforceChairOrAbove || isProjectLead)"
        class="bw1 ba br-1 b--blue link white bg-navy pa2 dim pointer dib"
        :to="{ name: 'edit-project', params: { id: project.id } }"
      >
        <i class="las la-edit"></i>
        {{ isProjectEditPending(project.id) ? 'edit in progress...' : 'edit' }}
      </router-link>
      <div
        v-if="networkOnLine && (isTaskforceChairOrAbove || isProjectLead)"
        class="bw1 ba br-1 b--red link white bg-dark-red pa2 dim pointer dib ml2"
        @click="saveChanges(project.id)"
      >
        <i class="las la-trash"></i>
        {{
          isProjectDeletionPending(project.id)
            ? 'delete in progress...'
            : 'delete'
        }}
      </div>
    </div>
    <h1 class="">{{ project.name }}</h1>
    <h2 class="f4 bw1 bb b-grey i">{{ capitalizeStatus }} - {{ formatTs }}</h2>
    <div class="db b mb2">
      Associated Taskforces:
      <span v-if="project.taskforces.length === 0" class="pl2 i"
        >None at this time</span
      >
    </div>
    <div class="flex mb3">
      <ul class="list flex flex-wrap pa0 ma0">
        <li v-for="tf in project.taskforces" :key="tf.id" class="ml2">
          <router-link
            class="pa1 no-underline bb bw1 b--blue navy hover-bg-navy hover-white bg-animate flex flex-nowrap"
            :to="{ name: 'taskforce', params: { id: tf.id } }"
            :v-if="getTaskforceById(tf.id)"
          >
            <div class="">
              {{ getTaskforceById(tf.id).name }}
            </div>
            <i class="las la-angle-right db f4 b"></i>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="db b mb2">
      Relevant Areas of Specialization:
      <span v-if="project.topics.length === 0" class="pl2 i"
        >None at this time</span
      >
    </div>
    <div class="flex mb3">
      <ul class="list flex flex-wrap pa0 ma0">
        <li v-for="u in project.topics" :key="u.id" class="ml2">
          <router-link
            class="pa1 no-underline bb bw1 b--blue navy hover-bg-navy hover-white bg-animate flex flex-nowrap"
            :to="{ name: 'topic', params: { id: u.id } }"
            :v-if="getTopicById(u.id)"
          >
            <div class="">
              {{ getTopicById(u.id).name }}
            </div>
            <i class="las la-angle-right db f4 b"></i>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="">
      <div class="b mr3 db">
        Project Leads:
        <span v-if="filteredUsers.length === 0" class="pl2 i"
          >None at this time</span
        >
      </div>
      <user-list-export :users="filteredUsers"></user-list-export>

      <div class="flex flex-wrap">
        <user-item-small
          v-for="(user, index) in filteredUsers"
          :key="user.id"
          class="db"
          :class="{ 'w-25': !small, 'w-100': small }"
          :index="index"
          :data="user"
        ></user-item-small>
      </div>
    </div>
    <!-- eslint-disable -->
    <div v-html="highlightedDescription"></div>
    <!-- eslint-enable -->
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import csReplace from 'preserve-case'
import UserItemSmall from '@/components/user/UserItemSmall'
import adminHelper from '@/mixins/adminHelper'
import resizeMixin from '@/mixins/resizeMixin'
import UserListExport from '@/components/user/UserListExport'

export default {
  components: { UserItemSmall, UserListExport },
  mixins: [adminHelper, resizeMixin],
  props: {
    project: Object
  },
  computed: {
    ...mapGetters('projects', [
      'isProjectDeletionPending',
      'isProjectEditPending'
    ]),
    ...mapGetters('taskforces', ['getTaskforceById']),
    ...mapGetters('topics', ['getTopicById']),
    ...mapGetters('users', ['getUserById']),
    ...mapState('projects', ['projects']),
    ...mapState('app', ['networkOnLine']),
    ...mapState('authentication', ['user']),
    isProjectLead() {
      return !!this.project.leads.find(lead => lead === this.user.id)
    },
    capitalizeStatus() {
      const s = this.project.status
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    formatTs() {
      const t = this.project.createTimestamp
      const y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(t)
      const m = new Intl.DateTimeFormat('en', { month: 'long' }).format(t)
      return `${m} ${y}`
    },
    query() {
      return this.$route.params.q || ''
    },
    filteredUsers() {
      return this.project.leads.map(u => {
        return this.getUserById(u)
      })
    },
    highlightedDescription() {
      if (this.query.length < 4) {
        return this.project.description
      }

      let hDesc = csReplace.all(
        this.project.description,
        this.query,
        `<::${this.query}::>`
      )

      hDesc = hDesc.replaceAll('<::', `<span class='bg-yellow'>`)

      hDesc = hDesc.replaceAll('::>', '</span>')

      return hDesc
    }
  },
  methods: {
    ...mapActions('projects', ['deleteProject']),
    saveChanges(id) {
      this.$dialog
        .confirm('Are you sure? Deleted records cannot be recovered.')
        .then(() => {
          this.deleteProject(id)
          this.$router.push({
            name: 'projects'
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped></style>
