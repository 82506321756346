<template>
  <div class="project-action-bar">
    <save-reminder :dirty="dirty"></save-reminder>
    <h1>Edit Project</h1>
    <label for="project-name" class="b mv2 dib">Project Name</label>
    <validation-error :show="$v.localProject.name.$invalid">
      Name requires 4 or more characters.
    </validation-error>
    <input
      id="project-name"
      v-model.trim="localProject.name"
      placeholder="project name..."
      type="text"
      class="pa2 w-100 mt1 mb3"
      @input="setProjectNameToEdit($event.target.value)"
    />
    <label for="project-status" class="b mv2 db">Project Status</label>
    <validation-error :show="$v.localProject.status.$invalid">
      Status is required.
    </validation-error>
    <select
      id="project-status"
      v-model.trim="localProject.status"
      @change="setProjectStatusToEdit(localProject.status)"
    >
      <option value="">--Please choose an option--</option>
      <option value="proposal">Proposal</option>
      <option v-if="isAllowed" value="ongoing">Ongoing</option>
      <option v-if="isAllowed" value="complete">Complete</option>
      <option v-if="isAllowed" value="inactive">Inactive</option>
    </select>

    <label
      v-if="isAllowed && localProject.status !== 'proposal'"
      for="project-taskforces"
      class="b mv2 db"
      >Associated Task Forces (Choose 1 or more)</label
    >
    <validation-error :show="!isTaskforcesValid">
      Associated Task Forces is required
    </validation-error>
    <multiselect
      v-if="isAllowed && localProject.status !== 'proposal'"
      id="project-taskforces"
      v-model.trim="localProjectTaskforces"
      :options="taskforceOptions"
      :multiple="true"
      :close-on-select="true"
      placeholder="Task Forces"
      label="name"
      track-by="id"
      class="mb3"
      @input="setProjectTaskforcesToEdit(localProjectTaskforces)"
    >
    </multiselect>
    <label for="project-leads" class="b mv2 dib"
      >Project Leads (Choose 1 or more)</label
    >
    <validation-error :show="$v.localProjectLeads.$invalid">
      Project Leads cannot be empty
    </validation-error>
    <multiselect
      id="project-leads"
      v-model.trim="localProjectLeads"
      :options="userOptions"
      :multiple="true"
      :close-on-select="true"
      placeholder="People"
      label="name"
      track-by="id"
      class="mb3"
      @input="setProjectLeadsToEdit(localProjectLeads)"
    >
    </multiselect>
    <label for="project-topics" class="b mv2 dib"
      >Project Areas of Specialization (Choose 1 or more)</label
    >
    <validation-error :show="$v.localProjectTopics.$invalid">
      Areas of Specialization cannot be empty
    </validation-error>
    <multiselect
      id="project-topics"
      v-model.trim="localProjectTopics"
      :options="topicOptions"
      :multiple="true"
      :close-on-select="true"
      placeholder="Areas of Specialization "
      label="name"
      track-by="id"
      class="mb3"
      @input="setProjectTopicsToEdit(localProjectTopics)"
    >
    </multiselect>
    <label for="project-description" class="b mv2 dib"
      >Project Description</label
    >
    <validation-error :show="$v.localProject.description.$invalid">
      Description cannot be empty
    </validation-error>
    <vue-editor
      id="project-description"
      v-model.trim="localProject.description"
      :editor-options="editorSettings"
      placeholder="project description..."
      class="project-name-input mt3"
      rows="7"
      cols="40"
      use-custom-image-handler
      @image-added="handleImageAdded"
      @input="setProjectDescToEdit(localProject.description)"
    ></vue-editor>
    <save-changes
      :invalid="$v.$invalid || !isTaskforcesValid"
      @save-changes="saveChanges()"
    ></save-changes>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import imageHandler from '@/mixins/imageHandler'
import { isEqual } from 'lodash'
import SaveReminder from '@/components/saveReminder'
import ValidationError from '@/components/validationError'
import SaveChanges from '@/components/saveChanges'
import {
  taskforceOptions,
  userOptions,
  topicOptions
} from '@/mixins/optionsWithUser'
import quillEditor from '@/mixins/quillEditor'

export default {
  components: {
    Multiselect,
    SaveReminder,
    ValidationError,
    SaveChanges
  },
  mixins: [
    imageHandler,
    taskforceOptions,
    userOptions,
    topicOptions,
    quillEditor
  ],
  props: {
    project: Object
  },
  data() {
    return {
      content: '',
      localProject: {
        name: '',
        status: '',
        description: ''
      },
      localProjectTaskforces: [],
      localProjectTopics: [],
      localProjectLeads: []
    }
  },
  computed: {
    ...mapState('projects', [
      'projectNameToEdit',
      'projectDescToEdit',
      'projectTaskforcesToEdit',
      'projectLeadsToEdit',
      'projectTopicsToEdit',
      'projectStatusToEdit',
      'projectEditPending'
    ]),
    ...mapState('authentication', ['user']),
    ...mapGetters('admins', ['getAdminById']),
    ...mapState('admins', ['admins']),
    ...mapGetters('chairs', ['getChairById']),
    ...mapState('chairs', ['chairs']),
    ...mapGetters('users', ['getUserById']),
    ...mapState('topics', ['topics']),
    ...mapGetters('topics', ['getTopicById']),
    isAllowed() {
      const isAdmin = this.getAdminById(this.user.id) !== false
      const isChair = this.getChairById(this.user.id) !== false
      const isPromoted = !(
        this.project.status === '' || this.project.status === 'proposal'
      )
      return (
        isPromoted ||
        isChair ||
        isAdmin ||
        this.localProjectTaskforces.length > 0
      )
    },
    isTaskforcesValid() {
      if (!this.isAllowed) return true
      if (this.localProject.status === 'proposal') return true

      return this.localProjectTaskforces.length > 0
    },
    dirty() {
      const pDirty = !isEqual(this.project, this.localProject)
      const tfDirty = !isEqual(
        this.project.taskforces,
        this.localProjectTaskforces
      )
      const tDirty = !isEqual(this.project.topics, this.localProjectTopics)
      const lDirty = !isEqual(this.project.leads, this.localProjectLeads)
      return pDirty || tfDirty || tDirty || lDirty
    }
  },
  created() {
    this.setProjectNameToEdit(this.project.name)
    this.setProjectDescToEdit(this.project.description)
    this.setProjectTaskforcesToEdit(this.project.taskforces)
    this.setProjectLeadsToEdit(this.project.leads)
    this.setProjectStatusToEdit(this.project.status)
    this.content = this.project.description
    let localProjectTaskforces = []
    if (this.project.taskforces) {
      localProjectTaskforces = this.project.taskforces.flatMap(tf => {
        return { name: this.getTaskforceById(tf.id).name, id: tf.id }
      })
    }
    const localProjectLeads = this.project.leads.map(u => this.getUserById(u))
    const localProjectTopics = this.project.topics.flatMap(t => {
      return { name: this.getTopicById(t.id).name, id: t.id }
    })
    this.localProjectTaskforces = localProjectTaskforces.filter(o => o.name)
    this.localProjectLeads = localProjectLeads.filter(o => o.name)
    this.localProjectTopics = localProjectTopics.filter(o => o.name)
    Object.assign(this.localProject, this.project)
  },

  methods: {
    ...mapMutations('projects', [
      'setProjectNameToEdit',
      'setProjectDescToEdit',
      'setProjectTaskforcesToEdit',
      'setProjectStatusToEdit',
      'setProjectTopicsToEdit',
      'setProjectLeadsToEdit'
    ]),
    ...mapActions('projects', ['triggerEditProjectAction', 'editProject']),
    saveChanges() {
      this.localProject.taskforces = this.localProjectTaskforces
      this.localProject.leads = this.localProjectLeads.map(x => x.id)
      this.localProject.topics = this.localProjectTopics
      this.editProject(this.localProject)
      this.$router.push({
        name: 'project',
        params: { id: this.project.id }
      })
    }
  },
  validations: {
    localProject: {
      name: {
        required,
        minLength: minLength(4)
      },
      status: {
        required,
        minLength: minLength(4)
      },
      description: {
        required
      }
    },
    localProjectLeads: {
      required,
      minLength: minLength(1)
    },
    localProjectTopics: {
      required,
      minLength: minLength(1)
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>
