export default {
  /* Resource input name */
  setResourceNameToCreate: (state, resourceNameToCreate) =>
    (state.resourceNameToCreate = resourceNameToCreate),
  setResourceLanguageToCreate: (state, resourceLanguageToCreate) =>
    (state.resourceLanguageToCreate = resourceLanguageToCreate),
  setResourceDescToCreate: (state, resourceDescToCreate) =>
    (state.resourceDescToCreate = resourceDescToCreate),
  setResourceTaskforceToCreate: (state, resourceTaskforceToCreate) =>
    (state.resourceTaskforceToCreate = resourceTaskforceToCreate),
  setResourceAudienceToCreate: (state, resourceAudienceToCreate) =>
    (state.resourceAudienceToCreate = resourceAudienceToCreate),
  setResourceTopicsToCreate: (state, resourceTopicsToCreate) =>
    (state.resourceTopicsToCreate = resourceTopicsToCreate),
  setResourceTypeToCreate: (state, resourceTypeToCreate) =>
    (state.resourceTypeToCreate = resourceTypeToCreate),
  setResourceNameToEdit: (state, resourceNameToEdit) =>
    (state.resourceNameToEdit = resourceNameToEdit),
  setResourceLanguageToEdit: (state, resourceLanguageToEdit) =>
    (state.resourceLanguageToEdit = resourceLanguageToEdit),
  setResourceDescToEdit: (state, resourceDescToEdit) =>
    (state.resourceDescToEdit = resourceDescToEdit),
  setResourceTaskforceToEdit: (state, resourceTaskforceToEdit) =>
    (state.resourceTaskforceToEdit = resourceTaskforceToEdit),
  setResourceAudienceToEdit: (state, resourceAudienceToEdit) =>
    (state.resourceAudienceToEdit = resourceAudienceToEdit),
  setResourceTopicsToEdit: (state, resourceTopicsToEdit) =>
    (state.resourceTopicsToEdit = resourceTopicsToEdit),
  setResourceTypeToEdit: (state, resourceTypeToEdit) =>
    (state.resourceTypeToEdit = resourceTypeToEdit),

  /* Resources */
  setResources: (state, resources) => (state.resources = resources),
  addResource: (state, resource) => state.resources.push(resource),
  removeResourceById: (state, resourceId) => {
    const index = state.resources.findIndex(
      resource => resource.id === resourceId
    )
    state.resources.splice(index, 1)
  },

  /* Resources deletion */
  addResourceDeletionPending: (state, resourceId) =>
    state.resourceDeletionPending.push(resourceId),
  removeResourceDeletionPending: (state, resourceId) => {
    const index = state.resourceDeletionPending.indexOf(resourceId)
    state.resourceDeletionPending.splice(index, 1)
  },

  /* Resources edit */
  addResourceEditPending: (state, resourceId) =>
    state.resourceEditPending.push(resourceId),
  removeResourceEditPending: (state, resourceId) => {
    const index = state.resourceEditPending.indexOf(resourceId)
    state.resourceEditPending.splice(index, 1)
  }
}
