<template>
  <div>
    <transition
      enter-active-class="animate__animated animate__slideInRight animate__faster"
      leave-active-class="animate__animated animate__slideOutRight animate__faster"
    >
      <div
        v-if="dirty"
        class="fixed top right-0 w5 br--left bg-light-yellow pa2  br3 b--black z-max shadow-1"
      >
        <div class="ttu b bb bw1 b--black">
          <i class="las la-exclamation-triangle"></i> Unsaved Changes
        </div>
        <p>
          Changes have been made to the form. To save your changes, click on the
          save button at the
          <a href="#saveButton" class="link navy dim underline"
            >bottom of the page</a
          >.
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SaveReminder',
  props: {
    dirty: Boolean
  }
}
</script>
