<template>
  <div class="page-wrapper">
    <request-detail
      v-if="currentRequest"
      :request="currentRequest"
    ></request-detail>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RequestDetail from '@/components/request/RequestDetail'

export default {
  components: { RequestDetail },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('requests', ['getRequestById']),
    currentRequest() {
      return this.getRequestById(this.id)
    }
  }
}
</script>
