<template>
  <div class="pa2 bg-light-yellow b mv1 flex justify-between">
    <div class="db"><i class="las la-bell"></i> <slot></slot></div>
    <div
      class="db bb bw b--mid-gray dim pointer"
      @click="$emit('dismiss', idx)"
    >
      <i class="las la-times"></i> Dismiss
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserNotification',
  props: {
    idx: Number
  }
}
</script>
