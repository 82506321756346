import Resources from '@/views/resource/Resources'
import Resource from '@/views/resource/Resource'
import AddResource from '@/views/resource/AddResource'
import EditResource from '@/views/resource/EditResource'

const routes = [
  {
    path: '/resources',
    name: 'resources',
    component: Resources
  },
  {
    path: '/resources/:id',
    name: 'resource',
    props: true,
    component: Resource
  },
  {
    path: '/resources/edit/:id',
    name: 'edit-resource',
    props: true,
    component: EditResource,
    meta: {
      reqTaskforceChair: true
    }
  },
  {
    path: '/resources/add',
    name: 'add-resource',
    props: true,
    component: AddResource,
    meta: {
      reqTaskforceChair: true
    }
  }
]

export default routes
