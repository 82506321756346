import { find } from 'lodash'

export default {
  /**
   * Check if a offer has deletion pending
   */
  isOfferDeletionPending: state => offerId =>
    state.offerDeletionPending.includes(offerId),

  isOfferEditPending: state => offerId =>
    state.offerEditPending.includes(offerId),

  /**
   * Get offer by id
   */
  getOfferById: state => offerId =>
    find(state.offers, offer => offer.id === offerId)
}
