<template>
  <div class="page-wrapper">
    <user-detail v-if="currentUser" :luser="currentUser"></user-detail>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserDetail from '@/components/user/UserDetail'

export default {
  components: { UserDetail },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('users', ['getUserById']),
    currentUser() {
      return this.getUserById(this.id)
    }
  }
}
</script>
