import UsersDB from '@/firebase/users-db'

export default {
  /**
   * Fetch users of current loggedin user
   */
  getUsers: async ({ commit }) => {
    const UserDb = new UsersDB()
    const users = await UserDb.readAll()
    commit('setUsers', users)
  },

  /**
   * Create a user for current loggedin user
   */
  createUser: async ({ commit }, user) => {
    const UserDb = new UsersDB()

    // Flag for first run
    user.firstRun = true
    user.contact = []
    user.notifications = []

    commit('setUserCreationPending', true)
    const createdUser = await UserDb.create(user, user.id)
    commit('addUser', createdUser)
    commit('setUserCreationPending', false)
  },
  editUser: async ({ dispatch, commit }, user) => {
    const UserDb = new UsersDB()

    commit('addUserEditPending', user.id)
    await UserDb.update(user)
    commit('removeUserEditPending', user.id)
    dispatch('getUsers')
  },

  /**
   * Create a new user for current loggedin user and reset user name input
   */
  triggerAddUserAction: ({ dispatch, state, commit }) => {
    if (state.userNameToCreate === '') return
    if (state.userEmailToCreate === '') return
    if (state.userBioToCreate === '') return
    if (state.userUniversityToCreate === '') return
    if (state.userTitleToCreate === '') return
    if (state.userContactToCreate === []) return
    if (state.userTopicsToCreate === []) return

    const user = {
      name: state.userNameToCreate,
      email: state.userEmailToCreate,
      bio: state.userBioToCreate,
      university: state.userUniversityToCreate,
      title: state.userTitleToCreate,
      contact: state.userContactToCreate,
      topics: state.userTopicsToCreate,
      picture: state.userPictureToCreate
    }

    commit('setUserNameToCreate', '')
    commit('setUserEmailToCreate', '')
    commit('setUserPictureToCreate', '')
    commit('setUserBioToCreate', '')
    commit('setUserUniversityToCreate', '')
    commit('setUserTitleToCreate', '')
    commit('setUserContactToCreate', [{ type: '', contact: '', id: 0 }])
    commit('setUserTopicsToCreate', [])
    dispatch('createUser', user)
  },

  /**
   * Delete a user user from its id
   */
  deleteUser: async ({ commit, getters }, userId) => {
    if (getters.isUserDeletionPending(userId)) return

    const UserDb = new UsersDB()

    commit('addUserDeletionPending', userId)
    await UserDb.delete(userId)
    commit('removeUserById', userId)
    commit('removeUserDeletionPending', userId)
  }
}
