<template>
  <div class="i w-100 b">
    <div class="pv2">
      Showing {{ current }} {{ getName }} of {{ total }}.
      <span v-if="current > 0"
        >Click on a{{ an ? 'n' : '' }} {{ name }} for more information.</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterBar',
  props: {
    current: Number,
    total: Number,
    name: String,
    plural: String,
    an: Boolean
  },
  computed: {
    getName() {
      if (this.current === 1) {
        return this.name
      }

      if (this.plural) {
        return this.plural
      }

      return `${this.name}s`
    }
  }
}
</script>
