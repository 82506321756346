import { mapState, mapGetters } from 'vuex'

const taskforceOptions = {
  computed: {
    ...mapState('taskforces', ['taskforces']),
    ...mapGetters('taskforces', ['getTaskforceById']),
    taskforceOptions() {
      const arr = this.taskforces.flatMap(x => {
        if (x.name && this.getTaskforceById(x.id)) {
          return { name: x.name, id: x.id }
        }

        return {}
      })

      return arr.filter(o => Object.keys(o).length > 0)
    }
  }
}

const topicOptions = {
  computed: {
    ...mapState('topics', ['topics']),
    ...mapGetters('topics', ['getTopicById']),
    topicOptions() {
      const arr = this.topics.flatMap(t => {
        if (t.name && this.getTopicById(t.id)) {
          return { name: t.name, id: t.id }
        }

        return {}
      })

      return arr.filter(o => Object.keys(o).length > 0)
    }
  }
}

const universityOptions = {
  computed: {
    ...mapState('universities', ['universities']),
    ...mapGetters('universities', ['getUniversityById']),
    universityOptions() {
      const arr = this.universities.flatMap(u => {
        if (u.name && this.getUniversityById(u.id)) {
          return { name: u.name, id: u.id }
        }

        return {}
      })

      return arr.filter(o => Object.keys(o).length > 0)
    }
  }
}

const userOptions = {
  computed: {
    ...mapState('users', ['users']),
    ...mapGetters('users', ['getUserById']),
    userOptions() {
      const arr = this.users.flatMap(u => {
        if (u.name && this.getUserById(u.id)) {
          return { name: u.name, id: u.id }
        }

        return {}
      })

      return arr.filter(o => Object.keys(o).length > 0)
    }
  }
}

export { taskforceOptions, topicOptions, universityOptions, userOptions }
