<template>
  <div class="user-action-bar">
    <save-reminder :dirty="dirty"></save-reminder>
    <h1>Edit Member</h1>
    <p v-if="currentUser.firstRun" class="pa2 bg-light-yellow b">
      Welcome to IAJESdb. Please fill out this form to complete your user
      profile.
    </p>

    <label for="user-name" class="b mv2 dib">Member Name</label>
    <validation-error :show="$v.localUser.name.$invalid">
      Name requires 4 or more characters.
    </validation-error>
    <input
      id="user-name"
      v-model.trim="localUser.name"
      placeholder="user name..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setUserNameToEdit($event.target.value)"
    />
    <cloudinary-upload
      :src="localUser.picture"
      :member="true"
      descr="The member picture is automatically cropped to a square centered around the face."
      name="Member"
      @uploaded="setPicture"
    ></cloudinary-upload>
    <label for="user-university" class="b mv2 dib">University</label>
    <validation-error :show="$v.localUserUniversity.$invalid">
      Please select a University
    </validation-error>
    <multiselect
      id="user-university"
      v-model.trim="localUserUniversity"
      :options="universityOptions"
      :multiple="false"
      :close-on-select="true"
      placeholder="University"
      label="name"
      track-by="id"
      class="mb3"
      @input="setUserUniversityToEdit(localUserUniversity)"
    >
    </multiselect>
    <label for="user-topics" class="b mv2 dib"
      >Areas of Specialization (Choose 1 or more)</label
    >
    <validation-error :show="$v.localUserTopics.$invalid">
      Areas of Specialization cannot be empty
    </validation-error>
    <multiselect
      id="user-topics"
      v-model.trim="localUserTopics"
      :options="topicOptions"
      :multiple="true"
      :close-on-select="true"
      placeholder="Areas of Specialization"
      label="name"
      track-by="id"
      class="mb3"
      @input="setUserTopicsToEdit(localUserTopics)"
    >
    </multiselect>
    <label for="title" class="b mv2 dib">Title</label>
    <validation-error :show="$v.localUser.title.$invalid">
      Title requires 4 or more characters.
    </validation-error>
    <input
      id="title"
      v-model.trim="localUser.title"
      placeholder="title..."
      type="text"
      class="pa2 w-100 mv3"
    />
    <label for="contact" class="b mv2 dib">Contact Information</label>
    <validation-error :show="$v.localUser.contact.$invalid">
      Please add a contact method.
    </validation-error>
    <div
      v-for="(item, idx) in localUser.contact"
      id="contact"
      :key="item.id"
      class="flex ta mb1"
    >
      <select v-model.trim="item.type">
        <option value="">--Please choose an option--</option>
        <option value="phone">Telephone</option>
        <option value="email">E-Mail</option>
        <option value="website">Website</option>
        <option value="facebook">Facebook</option>
        <option value="twitter">Twitter</option>
        <option value="linkedin">LinkedIn</option>
      </select>
      <input v-model.trim="item.contact" type="text" class="pa2 w-100 ml2" />
      <div
        v-if="localUser.contact.length > 1"
        class="br-100 ml2 pa2 b--red ba bw1 bg-dark-red white b pointer dim"
        @click="deleteContact(idx)"
      >
        <i class="las la-trash"></i>
      </div>
    </div>
    <div
      class="mb2 br-100 dib pa2 b--green ba bw1 bg-dark-green white b pointer dim"
      @click="newContact()"
    >
      <i class="las la-plus"></i>
    </div>
    <label for="user-bio" class="b mv2 db">Biography</label>
    <validation-error :show="$v.localUser.bio.$invalid">
      Biography cannot be empty
    </validation-error>
    <vue-editor
      id="user-bio"
      v-model.trim="localUser.bio"
      :editor-options="editorSettings"
      placeholder="user bio..."
      class="user-name-input"
      rows="7"
      cols="40"
      use-custom-image-handler
      @input="setUserBioToEdit(localUser.bio)"
      @image-added="handleImageAdded"
    ></vue-editor>
    <save-changes
      :invalid="$v.$invalid"
      @save-changes="saveChanges()"
    ></save-changes>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { required, minLength, email } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import { cloneDeep, isEqual } from 'lodash'
import firebase from 'firebase/app'
import CloudinaryUpload from '@/components/ImgUpload'
import imageHandler from '@/mixins/imageHandler'
import SaveReminder from '@/components/saveReminder'
import ValidationError from '@/components/validationError'
import SaveChanges from '@/components/saveChanges'
import quillEditor from '@/mixins/quillEditor'
import { universityOptions, topicOptions } from '@/mixins/optionsWithUser'

export default {
  components: {
    Multiselect,
    CloudinaryUpload,
    SaveReminder,
    ValidationError,
    SaveChanges
  },
  mixins: [imageHandler, universityOptions, topicOptions, quillEditor],
  props: {
    user: Object
  },
  data() {
    return {
      fbUser: null,
      content: '',
      localUser: {
        name: '',
        email: '',
        title: '',
        bio: '',
        picture: null
      },
      imageData: null,
      localUserUniversity: {},
      localUserContact: [],
      localUserTopics: []
    }
  },
  computed: {
    ...mapState('users', [
      'userNameToEdit',
      'userUniversityToEdit',
      'userTitleToEdit',
      'userContactToEdit',
      'userTopicsToEdit',
      'userBioToEdit',
      'userEditPending'
    ]),
    ...mapGetters('users', ['getUserById']),
    dirty() {
      const uDirty = !isEqual(this.user, this.localUser)
      const tDirty = !isEqual(this.user.topics, this.localUserTopics)
      const uniDirty = !isEqual(this.user.university, this.localUserUniversity)
      return uDirty || tDirty || uniDirty
    },
    currentUser() {
      const user = this.fbUser
      return this.getUserById(user.uid)
    }
  },
  created() {
    this.fbUser = firebase.auth().currentUser
    this.setUserNameToEdit(this.user.name)
    this.setUserUniversityToEdit(this.user.university || [])
    this.setUserBioToEdit(this.user.bio || '')
    this.setUserTitleToEdit(this.user.title || '')
    this.content = this.user.bio

    if (this.user.university) {
      this.localUserUniversity = {
        name: this.getUniversityById(this.user.university.id).name,
        id: this.user.university.id
      }
    } else {
      this.localUserUniversity = []
    }

    if (this.user.topics) {
      this.localUserTopics = this.user.topics.flatMap(t => {
        return { name: this.getTopicById(t.id).name, id: t.id }
      })
    } else {
      this.localUserTopics = []
    }

    this.localUser = cloneDeep(this.user)

    if (!this.fbUser.emailVerified) {
      this.fbUser.sendEmailVerification().then(() => {
        // Email sent.
      })
      // .catch(error => {
      //   // An error happened.
      //   console.log(error)
      // })
    }
  },

  methods: {
    ...mapMutations('users', [
      'setUserNameToEdit',
      'setUserBioToEdit',
      'setUserTitleToEdit',
      'setUserContactToEdit',
      'setUserTopicsToEdit',
      'setUserUniversityToEdit'
    ]),
    ...mapActions('users', ['triggerEditUserAction', 'editUser']),
    setPicture(src) {
      this.localUser.picture = src
    },
    newContact() {
      this.localUser.contact.push({
        type: '',
        contact: '',
        id: new Date().getTime()
      })
    },
    deleteContact(idx) {
      this.localUser.contact.splice(idx, 1)
    },
    saveChanges() {
      this.localUser.university = this.localUserUniversity
      this.localUser.topics = this.localUserTopics
      this.localUser.firstRun = false
      this.editUser(this.localUser)
      this.$router.push({
        name: 'member',
        params: { id: this.user.id }
      })
    }
  },
  validations: {
    localUser: {
      name: {
        required,
        minLength: minLength(4)
      },
      title: {
        required,
        minLength: minLength(4)
      },
      email: {
        required,
        email
      },
      bio: {
        required
      },
      contact: {
        required,
        minLength: minLength(1),
        $each: {
          type: {
            required,
            minLength: minLength(1)
          },
          contact: {
            minLength: minLength(4)
          }
        }
      }
    },
    localUserTopics: {
      required,
      minLength: minLength(1)
    },
    localUserUniversity: {
      required,
      minLength: minLength(1)
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>
