<template>
  <div class="mw8 center">
    <div class="flex justify-end mt3">
      <router-link
        v-if="networkOnLine && (isAdmin || isLocalChair)"
        class="bw1 ba br-1 b--blue link white bg-navy pa2 dim pointer dib"
        :to="{ name: 'edit-taskforce', params: { id: taskforce.id } }"
      >
        <i class="las la-edit"></i>
        {{
          isTaskforceEditPending(taskforce.id) ? 'edit in progress...' : 'edit'
        }}
      </router-link>
      <div
        v-if="networkOnLine && (isAdmin || isLocalChair)"
        class="bw1 ba br-1 b--red link white bg-dark-red pa2 dim pointer dib ml2"
        @click="saveChanges(taskforce.id)"
      >
        <i class="las la-trash"></i>
        {{
          isTaskforceDeletionPending(taskforce.id)
            ? 'delete in progress...'
            : 'delete'
        }}
      </div>
    </div>
    <h1 class="bw1 bb b-grey">{{ taskforce.name }}</h1>
    <div class="">
      <user-list-export :users="filteredUsers"></user-list-export>
      <div class="b mr3 db">
        Task Force Chairs:
        <span v-if="filteredChairs.length === 0" class="pl2 i"
          >None at this time</span
        >
      </div>
      <div class="flex flex-wrap">
        <user-item-small
          v-for="(user, index) in filteredChairs"
          :key="user.id"
          class="db w-25"
          :index="index"
          :data="user"
        ></user-item-small>
      </div>
    </div>
    <div class="">
      <div class="b mr3 db">
        Task Force Members:
        <span v-if="filteredMembers.length === 0" class="pl2 i"
          >None at this time</span
        >
      </div>
      <div class="flex flex-wrap">
        <user-item-small
          v-for="(user, index) in filteredMembers"
          :key="user.id"
          class="db"
          :class="{ 'w-25': !small, 'w-100': small }"
          :index="index"
          :data="user"
        ></user-item-small>
      </div>
    </div>
    <div class="db b mb2">
      Resources associated with {{ taskforce.name }}:
      <span v-if="filteredResources.length === 0" class="pl2 i"
        >None at this time</span
      >
    </div>
    <div class="flex">
      <ul class="list flex pa0 ma0">
        <li v-for="r in filteredResources" :key="r" class="ml2 mb3">
          <router-link
            class="pa1 no-underline bb bw1 b--blue navy hover-bg-navy hover-white bg-animate flex flex-nowrap"
            :to="{ name: 'resource', params: { id: r } }"
            :v-if="getResourceById(r)"
          >
            <div class="">
              {{ getResourceById(r).name }}
            </div>
            <i class="las la-angle-right db f4 b"></i>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="pa2 bg-light-yellow w-100">
      If you would like to join this task force, please email the task force
      chair.
    </div>
    <!-- eslint-disable -->
    <div v-html="highlightedDescription"></div>
    <!-- eslint-enable -->
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import csReplace from 'preserve-case'
import UserItemSmall from '@/components/user/UserItemSmall'
import adminHelper from '@/mixins/adminHelper'
import resizeMixin from '@/mixins/resizeMixin'
import UserListExport from '@/components/user/UserListExport'
import { uniq } from 'lodash'

export default {
  components: { UserItemSmall, UserListExport },
  mixins: [adminHelper, resizeMixin],
  props: {
    taskforce: Object
  },
  computed: {
    ...mapGetters('taskforces', [
      'isTaskforceDeletionPending',
      'isTaskforceEditPending'
    ]),
    ...mapGetters('resources', ['getResourceById']),
    ...mapGetters('users', ['getUserById']),
    ...mapState('taskforces', ['taskforces']),
    ...mapState('resources', ['resources']),
    ...mapState('app', ['networkOnLine']),
    ...mapState('authentication', ['user']),
    isLocalChair() {
      return !!this.taskforce.chairs.find(chair => chair === this.user.id)
    },
    filteredChairs() {
      return this.taskforce.chairs.map(u => this.getUserById(u))
    },
    filteredMembers() {
      return this.taskforce.members.map(u => this.getUserById(u))
    },
    filteredUsers() {
      return uniq([...this.filteredChairs, ...this.filteredMembers])
    },
    filteredResources() {
      const { id } = this.taskforce
      const arr = Object.values(this.resources)
      const resrc = []

      arr.forEach(r => {
        if (r.taskforce.id === id) {
          resrc.push(r.id)
        }
      })

      return resrc
    },
    query() {
      return this.$route.params.q || ''
    },
    highlightedDescription() {
      if (this.query.length < 4) {
        return this.taskforce.description
      }

      let hDesc = csReplace.all(
        this.taskforce.description,
        this.query,
        `<::${this.query}::>`
      )

      hDesc = hDesc.replaceAll('<::', `<span class='bg-yellow'>`)

      hDesc = hDesc.replaceAll('::>', '</span>')

      return hDesc
    }
  },
  methods: {
    ...mapActions('taskforces', ['deleteTaskforce']),
    saveChanges(id) {
      this.$dialog
        .confirm('Are you sure? Deleted records cannot be recovered.')
        .then(() => {
          this.deleteTaskforce(id)
          this.$router.push({
            name: 'taskforces'
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped></style>
