import OffersDB from '@/firebase/offers-db'
import firebase from 'firebase/app'

export default {
  /**
   * Fetch offers of current loggedin user
   */
  getOffers: async ({ commit }) => {
    const OfferDb = new OffersDB()
    const now = firebase.firestore.Timestamp.fromDate(new Date())
    const offers = await OfferDb.readAll([['expiry', '>', now]])
    commit('setOffers', offers)
  },

  /**
   * Create a offer for current loggedin user
   */
  createOffer: async ({ commit }, offer) => {
    const OfferDb = new OffersDB()

    commit('setOfferCreationPending', true)
    const createdOffer = await OfferDb.create(offer)
    commit('addOffer', createdOffer)
    commit('setOfferCreationPending', false)
  },
  editOffer: async ({ dispatch, commit }, offer) => {
    const OfferDb = new OffersDB()

    commit('addOfferEditPending', offer.id)
    await OfferDb.update(offer)
    commit('removeOfferEditPending', offer.id)
    dispatch('getOffers')
  },

  /**
   * Create a new offer for current loggedin user and reset offer name input
   */
  triggerAddOfferAction: ({ dispatch, state, commit }) => {
    if (state.offerNameToCreate === '') return
    if (state.offerDescToCreate === '') return

    const offer = {
      name: state.offerNameToCreate,
      description: state.offerDescToCreate,
      topics: state.offerTopicsToCreate,
      owner: state.offerOwner,
      expiry: state.offerExpiryToCreate
    }

    commit('setOfferNameToCreate', '')
    commit('setOfferDescToCreate', '')
    commit('setOfferTopicsToCreate', '')
    commit('setOfferOwner', '')
    dispatch('createOffer', offer)
  },

  /**
   * Delete a user offer from its id
   */
  deleteOffer: async ({ commit, getters }, offerId) => {
    if (getters.isOfferDeletionPending(offerId)) return

    const OfferDb = new OffersDB()

    commit('addOfferDeletionPending', offerId)
    await OfferDb.delete(offerId)
    commit('removeOfferById', offerId)
    commit('removeOfferDeletionPending', offerId)
  }
}
