export default {
  taskforces: null,
  taskforceNameToCreate: '',
  taskforceDescToCreate: '',
  taskforceChairsToCreate: '',
  taskforceMembersToCreate: '',
  taskforceNameToEdit: '',
  taskforceDescToEdit: '',
  taskforceChairsToEdit: '',
  taskforceMembersToEdit: '',
  taskforceDeletionPending: [],
  taskforceCreationPending: false,
  taskforceEditPending: []
}
