import state from './admins.state'
import mutations from './admins.mutations'
import actions from './admins.actions'
import getters from './admins.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
