import Offers from '@/views/offer/Offers'
import Offer from '@/views/offer/Offer'
import AddOffer from '@/views/offer/AddOffer'
import EditOffer from '@/views/offer/EditOffer'
import { isAdmin, isChair } from '@/router/helpers'

const routes = [
  {
    path: '/offers',
    name: 'offers',
    component: Offers
  },
  {
    path: '/offers/:id',
    name: 'offer',
    props: true,
    component: Offer
  },
  {
    path: '/offers/edit/:id',
    name: 'edit-offer',
    props: true,
    component: EditOffer,
    beforeEnter: (to, from, next) => {
      if (isChair() || isAdmin()) return next()
      return next('/unauthorized')
    }
  },
  {
    path: '/offers/add',
    name: 'add-offer',
    props: true,
    component: AddOffer
  }
]

export default routes
