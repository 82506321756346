<template>
  <div class="mw8 center">
    <div class="flex justify-end mt3">
      <div
        v-if="canApprove"
        class="bw1 ba br-1 b--green white bg-dark-green pa2 dim pointer dib"
        @click="approveResource(resource)"
      >
        <i class="las la-check"></i> approve
      </div>
      <router-link
        v-if="networkOnLine && isTaskforceChairOrAbove"
        class="bw1 ba br-1 b--blue link white bg-navy pa2 dim pointer dib ml2"
        :to="{ name: 'edit-resource', params: { id: resource.id } }"
      >
        <i class="las la-edit"></i>
        {{
          isResourceEditPending(resource.id) ? 'edit in progress...' : 'edit'
        }}
      </router-link>
      <div
        v-if="networkOnLine && isTaskforceChairOrAbove"
        class="bw1 ba br-1 b--red link white bg-dark-red pa2 dim pointer dib ml2"
        @click="saveChanges(resource.id)"
      >
        <i class="las la-trash"></i>
        {{
          isResourceDeletionPending(resource.id)
            ? 'delete in progress...'
            : 'delete'
        }}
      </div>
    </div>
    <h1 class="">{{ resource.name }}</h1>
    <h2 class="">
      {{ keyToName(resource.type) }} for {{ keyToName(resource.audience) }}
    </h2>
    <div class="db mb2">
      <span class="b">Task Force:</span>
      <span v-if="resource.taskforce.length === 0" class="pl2 i"
        >None at this time</span
      >
      <div class="dib mb3">
        <ul class="list flex pa0 ma0">
          <li class="ml2">
            <router-link
              class="pa1 no-underline bb bw1 b--blue navy hover-bg-navy hover-white bg-animate flex flex-nowrap"
              :to="{
                name: 'taskforce',
                params: { id: resource.taskforce.id }
              }"
            >
              <div class="">
                {{ resource.taskforce.name }}
              </div>
              <i class="las la-angle-right db f4 b"></i>
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="db b mb2">
      Relevant Areas of Specialization:
      <span v-if="resource.topics.length === 0" class="pl2 i"
        >None at this time</span
      >
    </div>
    <div class="flex mb3">
      <ul class="list flex flex-wrap pa0 ma0">
        <li v-for="u in resource.topics" :key="u.id" class="ml2">
          <router-link
            class="pa1 no-underline bb bw1 b--blue navy hover-bg-navy hover-white bg-animate flex flex-nowrap"
            :to="{ name: 'topic', params: { id: u.id } }"
            :v-if="getTopicById(u.id)"
          >
            <div class="">
              {{ getTopicById(u.id).name }}
            </div>
            <i class="las la-angle-right db f4 b"></i>
          </router-link>
        </li>
      </ul>
    </div>
    <!-- eslint-disable -->
    <div v-html="highlightedDescription"></div>
    <!-- eslint-enable -->
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import csReplace from 'preserve-case'
import adminHelper from '@/mixins/adminHelper'
import { cloneDeep } from 'lodash'

export default {
  mixins: [adminHelper],
  props: {
    resource: Object
  },
  computed: {
    ...mapGetters('resources', [
      'isResourceDeletionPending',
      'isResourceEditPending'
    ]),
    ...mapGetters('topics', ['getTopicById']),
    ...mapGetters('chairs', ['getChairById']),
    ...mapState('resources', ['resources']),
    ...mapState('app', ['networkOnLine']),
    ...mapState('authentication', ['user']),
    canApprove() {
      const userId = this.user.id
      const chairs = this.getChairById(userId)

      return !this.resource.approved && (chairs || this.isAdmin)
    },
    query() {
      return this.$route.params.q || ''
    },
    highlightedDescription() {
      if (this.query.length < 4) {
        return this.resource.description
      }

      let hDesc = csReplace.all(
        this.resource.description,
        this.query,
        `<::${this.query}::>`
      )

      hDesc = hDesc.replaceAll('<::', `<span class='bg-yellow'>`)

      hDesc = hDesc.replaceAll('::>', '</span>')

      return hDesc
    }
  },
  methods: {
    ...mapActions('resources', [
      'deleteResource',
      'triggerEditResourceAction',
      'editResource'
    ]),
    keyToName(k) {
      return k.replace('_', ' ').replace(/\w\S*/g, txt => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    },
    approveResource(resource) {
      const res = cloneDeep(resource)
      res.approved = true
      this.editResource(res)
    },
    saveChanges(id) {
      this.$dialog
        .confirm('Are you sure? Deleted records cannot be recovered.')
        .then(() => {
          this.deleteResource(id)
          this.$router.push({
            name: 'resources'
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped></style>
