export default [
  {
    name: 'Task Forces',
    route: '/taskforces',
    adminOnly: false,
    desc:
      'See all the IAJES Taskforces, their members, and associated projects.'
  },
  {
    name: 'Projects',
    route: '/projects',
    adminOnly: false,
    desc: 'Browse and search through all projects.'
  },
  {
    name: 'Areas of Specialization',
    route: '/topics',
    adminOnly: false,
    desc: 'Get more information about a given area or discipline.'
  },
  {
    name: 'Members',
    route: '/members',
    adminOnly: false,
    desc: 'See a list of all members with access to IAJESdb.'
  },

  {
    name: 'Universities',
    route: '/universities',
    adminOnly: false,
    desc: 'See a list of all participating universities.'
  },
  {
    name: 'Resources',
    route: '/resources',
    adminOnly: false,
    desc:
      'See a list of all IAJESdb resources, including articles, courses, and more.'
  },
  {
    name: 'Opportunities for Collaboration',
    route: '/offers',
    adminOnly: false,
    desc:
      'See and post opportunities for colloboration with all IAJESdb members and universities.'
  },
  {
    name: 'Member Requests',
    route: '/requests',
    adminOnly: true,
    desc:
      'For administrator use only. Approve or disapprove new member requests.'
  }
]
