import { find } from 'lodash'

export default {
  /**
   * Check if a resource has deletion pending
   */
  isResourceDeletionPending: state => resourceId =>
    state.resourceDeletionPending.includes(resourceId),

  isResourceEditPending: state => resourceId =>
    state.resourceEditPending.includes(resourceId),

  /**
   * Get resource by id
   */
  getResourceById: state => resourceId => {
    const res = find(state.resources, resource => resource.id === resourceId)

    if (res) {
      return res
    }

    return false
  }
}
