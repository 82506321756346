<template>
  <div class="page-wrapper">
    <university-detail
      v-if="currentUniversity"
      :university="currentUniversity"
    ></university-detail>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UniversityDetail from '@/components/university/UniversityDetail'

export default {
  components: { UniversityDetail },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('universities', ['getUniversityById']),
    currentUniversity() {
      return this.getUniversityById(this.id)
    }
  }
}
</script>
