import { find } from 'lodash'

export default {
  /**
   * Check if a request has deletion pending
   */
  isRequestDeletionPending: state => requestId =>
    state.requestDeletionPending.includes(requestId),

  isRequestEditPending: state => requestId =>
    state.requestEditPending.includes(requestId),

  /**
   * Get request by id
   */
  getRequestById: state => requestId => {
    const res = find(state.requests, request => request.id === requestId)

    if (res) {
      return res
    }

    return false
  }
}
