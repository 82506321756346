<template>
  <div class="mw7 center">
    <edit-offer v-if="currentOffer" :offer="currentOffer"></edit-offer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditOffer from '@/components/offer/EditOffer'

export default {
  components: { EditOffer },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('offers', ['getOfferById']),
    currentOffer() {
      return this.getOfferById(this.id)
    }
  }
}
</script>
