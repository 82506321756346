import TopicsDB from '@/firebase/topics-db'

export default {
  /**
   * Fetch topics of current loggedin user
   */
  getTopics: async ({ commit }) => {
    const TopicDb = new TopicsDB()
    const topics = await TopicDb.readAll()
    const sortedTopics = topics.sort((a, b) => {
      if (a.name > b.name) return 1
      if (a.name < b.name) return -1
      return 0
    })
    commit('setTopics', sortedTopics)
  },

  /**
   * Create a topic for current loggedin user
   */
  createTopic: async ({ commit }, topic) => {
    const TopicDb = new TopicsDB()

    commit('setTopicCreationPending', true)
    const createdTopic = await TopicDb.create(topic)
    commit('addTopic', createdTopic)
    commit('setTopicCreationPending', false)
  },
  editTopic: async ({ dispatch, commit }, topic) => {
    const TopicDb = new TopicsDB()

    commit('addTopicEditPending', topic.id)
    await TopicDb.update(topic)
    commit('removeTopicEditPending', topic.id)
    dispatch('getTopics')
  },

  /**
   * Create a new topic for current loggedin user and reset topic name input
   */
  triggerAddTopicAction: ({ dispatch, state, commit }) => {
    if (state.topicNameToCreate === '') return

    const topic = {
      name: state.topicNameToCreate,
      description: state.topicDescToCreate
    }

    commit('setTopicNameToCreate', '')
    commit('setTopicDescToCreate', '')
    dispatch('createTopic', topic)
  },

  /**
   * Delete a user topic from its id
   */
  deleteTopic: async ({ commit, getters }, topicId) => {
    if (getters.isTopicDeletionPending(topicId)) return

    const TopicDb = new TopicsDB()

    commit('addTopicDeletionPending', topicId)
    await TopicDb.delete(topicId)
    commit('removeTopicById', topicId)
    commit('removeTopicDeletionPending', topicId)
  }
}
