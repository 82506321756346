<template>
  <div class="mw8 center">
    <div class="flex justify-end mt3">
      <div
        v-if="networkOnLine"
        class="bw1 ba br-1 b--green white bg-dark-green pa2 dim pointer dib ml2"
        @click="approveUser(request, request.id)"
      >
        <i class="las la-check"></i>
        {{
          isRequestEditPending(request.id) ? 'edit in progress...' : 'approve'
        }}
      </div>
      <div
        v-if="networkOnLine"
        class="bw1 ba br-1 b--red link white bg-dark-red pa2 dim pointer dib ml2"
        @click="saveChanges(request.id)"
      >
        <i class="las la-trash"></i>
        {{
          isRequestDeletionPending(request.id)
            ? 'delete in progress...'
            : 'delete'
        }}
      </div>
    </div>
    <h1 class="bw1 bb b-grey">{{ request.name }}</h1>
    <div>Email: {{ request.email }}</div>
    <div>ID: {{ request.id }}</div>
    <div>Timestamp: {{ request.createTimestamp }}</div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  props: {
    request: Object
  },
  computed: {
    ...mapGetters('requests', [
      'isRequestDeletionPending',
      'isRequestEditPending'
    ]),
    ...mapState('requests', ['requests']),
    ...mapState('app', ['networkOnLine'])
  },
  methods: {
    ...mapActions('requests', ['deleteRequest']),
    ...mapActions('users', ['createUser']),
    approveUser(user, id) {
      this.$dialog
        .confirm('Are you sure?')
        .then(() => {
          this.createUser(user)
          this.deleteRequest(id)
          this.$router.push({
            name: 'requests'
          })
        })
        .catch(() => {})
    },
    saveChanges(id) {
      this.$dialog
        .confirm('Are you sure? Deleted records cannot be recovered.')
        .then(() => {
          this.deleteRequest(id)
          this.$router.push({
            name: 'requests'
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped></style>
