<template>
  <div class="mw8 center">
    <div class="flex justify-between flex-wrap mv1">
      <h1 class="db mv3">Universities</h1>
      <div
        v-if="networkOnLine && isTaskforceChairOrAbove"
        class="bw1 ba br-1 b--blue link white bg-navy pa2 dim pointer db mv3"
        @click="$router.push({ name: 'add-university' })"
      >
        <i class="las la-plus"></i>
        University
      </div>
    </div>
    <university-list class="university-list"></university-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UniversityList from '@/components/university/UniversityList'
import adminHelper from '@/mixins/adminHelper'

export default {
  components: { UniversityList },
  mixins: [adminHelper],
  computed: mapState('app', ['networkOnLine'])
}
</script>

<style lang="scss" scoped></style>
