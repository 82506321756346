<template>
  <div v-if="users.length > 0" class="dib">
    <a
      class="link underline dim pointer pv2 mb1 dark-blue dib"
      @click="toggleExpand"
      ><i class="las la-file-export underline"></i> Export Contact List</a
    >
    <div v-if="expanded" class="db w-100">
      <div class="pa2 bg-light-yellow w-100">
        You can copy these email addresses into an email or calendar invite.
      </div>
      <div class="bw1 b--mid-gray ba mt2 pa2 w-100">{{ txtList }}</div>
      <a
        class="link underline dim pointer pa2 mv1 bg-dark-blue white dib"
        @click="downloadCSV"
        >Download CSV</a
      >
    </div>
  </div>
</template>

<script>
import { Parser } from 'json2csv'
import { cloneDeep } from 'lodash'
import { mapState } from 'vuex'

export default {
  props: {
    users: Array
  },
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    ...mapState('taskforces', ['taskforces']),
    csvData() {
      if (this.users.length === 0) return ''
      const cleanData = this.users.map(u => {
        const cleanU = cloneDeep(u)
        cleanU.university = u.university ? u.university.name : ''
        cleanU.areasOfSpecialization = u.topics
          ? u.topics.map(t => t.name).join(', ')
          : ''
        cleanU.bio = u.bio
          ? u.bio
              .replace(/(<([^>]+)>)/gi, '_')
              .replace(/_+/gi, ' ')
              .trim()
          : ''
        cleanU.taskforces = this.getUserTaskforces(u.id).join(', ')

        cleanU.email = u.contact
          ? u.contact.flatMap(c => {
              if (c.type !== 'email') {
                return ''
              }
              return c.contact
            })[0]
          : ''
        cleanU.contact = u.contact
          ? u.contact
              .flatMap(c => {
                if (c.type === 'email') {
                  return ''
                }
                return c.contact
              })
              .filter(c => c.length > 0)
              .join(', ')
          : ''
        return cleanU
      })

      const fields = [
        'name',
        'email',
        'title',
        'university',
        'taskforces',
        'areasOfSpecialization',
        'contact',
        'bio'
      ]
      const opts = { fields }

      try {
        const parser = new Parser(opts)
        const csv = parser.parse(cleanData)
        return csv
      } catch (err) {
        console.error(err)
        return ''
      }
    },
    txtList() {
      if (this.users.length === 0) return ''
      const emails = this.users.flatMap(u => {
        return u.contact.flatMap(c => {
          if (c.type !== 'email') {
            return ''
          }
          return c.contact
        })
      })
      return emails.filter(e => e.length > 0).join(', ')
    }
  },
  watch: {},
  created() {},
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded
    },
    getUserTaskforces(userId) {
      const res = this.taskforces.filter(tf => {
        const inChairs = tf.chairs.find(c => {
          return c === userId
        })
        const inMembers = tf.members.find(m => {
          return m === userId
        })

        return inChairs || inMembers
      })

      return res.map(r => r.name)
    },
    downloadCSV() {
      const hiddenElement = document.createElement('a')
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(
        this.csvData
      )}`
      hiddenElement.target = '_blank'
      hiddenElement.download = 'IAJESdb-contact-export.csv'
      hiddenElement.click()
    }
  }
}
</script>

<style lang="scss" scoped></style>
