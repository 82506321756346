<template>
  <div class="page-wrapper">
    <topic-detail v-if="currentTopic" :topic="currentTopic"></topic-detail>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TopicDetail from '@/components/topic/TopicDetail'

export default {
  components: { TopicDetail },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('topics', ['getTopicById']),
    currentTopic() {
      return this.getTopicById(this.id)
    }
  }
}
</script>
