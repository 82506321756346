<template>
  <div class="mw8 center">
    <add-taskforce></add-taskforce>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddTaskforce from '@/components/taskforce/AddTaskforce'

export default {
  components: { AddTaskforce },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('taskforces', ['getTaskforceById']),
    currentTaskforce() {
      return this.getTaskforceById(this.id)
    }
  }
}
</script>
