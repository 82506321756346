<template>
  <div class="university-action-bar">
    <save-reminder :dirty="dirty"></save-reminder>
    <h1>Edit University</h1>
    <label for="university-name" class="b mv2 dib">University Name</label>
    <validation-error :show="$v.localUniversity.name.$invalid">
      Name requires 4 or more characters.
    </validation-error>
    <input
      id="university-name"
      v-model.trim="localUniversity.name"
      placeholder="university name..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setUniversityNameToEdit($event.target.value)"
    />
    <cloudinary-upload
      :src="localUniversity.picture"
      descr="High quality PNG files with a transparent background work best."
      name="University"
      @uploaded="setPicture"
    ></cloudinary-upload>
    <label for="university-city" class="b mv2 db">University City</label>
    <validation-error :show="$v.localUniversity.city.$invalid">
      City requires 4 or more characters.
    </validation-error>
    <input
      id="university-city"
      v-model.trim="localUniversity.city"
      placeholder="university city..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setUniversityCityToEdit($event.target.value)"
    />
    <label for="university-country" class="b mv2 dib">University Country</label>
    <validation-error :show="$v.localUniversity.country.$invalid">
      Country requires 3 or more characters.
    </validation-error>
    <input
      id="university-country"
      v-model.trim="localUniversity.country"
      placeholder="university country..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setUniversityCountryToEdit($event.target.value)"
    />
    <label for="university-contact" class="b mv2 dib">University Contact</label>
    <p class="i">
      Members must be a member of this University to be a contact.
    </p>
    <multiselect
      id="university-contact"
      v-model.trim="localUniversity.contact"
      :options="userOptionsByUniversity(localUniversity.id)"
      :multiple="false"
      :close-on-select="true"
      placeholder="Contact"
      label="name"
      track-by="id"
      class="mb3"
      @input="setUniversityContactToEdit(localUniversity.contact)"
    ></multiselect>
    <label for="university-region" class="b mv2 db">University Region</label>
    <validation-error :show="$v.localUniversity.region.$invalid">
      Region is required.
    </validation-error>
    <select
      id="university-region"
      v-model.trim="localUniversity.region"
      class="mv3"
      @change="setUniversityRegionToEdit(localUniversity.region)"
    >
      <option value="">--Please choose an option--</option>
      <option v-for="r in regions" :key="r.id" :value="r.id"
        >{{ r.name }} - {{ r.desc }}</option
      >
    </select>
    <label for="university-website" class="b mv2 db">University Website</label>
    <validation-error :show="$v.localUniversity.website.$invalid">
      Website must be a valid URL starting with "http://" or "https://".
    </validation-error>
    <input
      id="university-website"
      v-model.trim="localUniversity.website"
      placeholder="university website..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setUniversityWebsiteToEdit($event.target.value)"
    />
    <label for="university-description" class="b mv2 dib"
      >University Description</label
    >
    <validation-error :show="$v.localUniversity.description.$invalid">
      Description cannot be empty
    </validation-error>
    <vue-editor
      id="university-description"
      v-model.trim="localUniversity.description"
      :editor-options="editorSettings"
      placeholder="university description..."
      class="university-name-input"
      rows="7"
      cols="40"
      use-custom-image-handler
      @image-added="handleImageAdded"
      @input="setUniversityDescToEdit(localUniversity.description)"
    ></vue-editor>
    <div class="b mv2">Areas of Specialization Covered by Degree Programs</div>
    <validation-error :show="$v.universityTopics.$invalid">
      Areas of Specialization cannot be empty
    </validation-error>
    <table class="w-100">
      <thead>
        <tr class="">
          <th class="tc"></th>
          <th class="relative tc rotate">
            <div class="absolute bottom-0 left-0 tl w-100">
              <span class="absolute left-0 bottom-0 bw1 bb b--moon-gray lh-copy"
                >Undergraduate</span
              >
            </div>
          </th>
          <th class="relative tc  rotate">
            <div class="absolute bottom-0 left-0 tl w-100">
              <span class="pabsolute left-0 bottom-0 w1 bb b--moon-gray lh-copy"
                >Graduate</span
              >
            </div>
          </th>
          <th class="relative tc  rotate">
            <div class="absolute bottom-0 left-0 tl w-100">
              <span class="pabsolute left-0 bottom-0 w1 bb b--moon-gray lh-copy"
                >Doctorate</span
              >
            </div>
          </th>
          <th class="tc"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="topic in topics"
          :key="topic.id"
          class="bw1 bt b--moon-gray bgh-grey bg-bg-animate"
        >
          <td class="">{{ topic.name }}</td>
          <td class="tc bw1 br b--moon-gray" style="padding: 10px 5px;">
            <input
              :id="topic.id + '-u'"
              type="checkbox"
              :name="topic.id + '-u'"
              value="undergraduate"
              :checked="isChecked(topic.id, 'undergraduate')"
              @input="toggleTopic(topic.id, 'undergraduate')"
            />
          </td>
          <td class="tc bw1 br b--moon-gray" style="padding: 10px 5px;">
            <input
              :id="topic.id + '-g'"
              type="checkbox"
              :name="topic.id + '-g'"
              value="graduate"
              :checked="isChecked(topic.id, 'graduate')"
              @input="toggleTopic(topic.id, 'graduate')"
            />
          </td>
          <td class="tc bw1 br b--moon-gray" style="padding: 10px 5px;">
            <input
              :id="topic.id + '-d'"
              type="checkbox"
              :name="topic.id + '-d'"
              value="doctorate"
              :checked="isChecked(topic.id, 'doctorate')"
              @input="toggleTopic(topic.id, 'doctorate')"
            />
          </td>
          <td class="tc">
            <a class="mr2 underline pointer" @click="checkAllTopics(topic.id)"
              >Check All</a
            >
            <a class="mr2 underline pointer" @click="uncheckAllTopics(topic.id)"
              >Uncheck All</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <save-changes
      :invalid="$v.$invalid"
      @save-changes="saveChanges()"
    ></save-changes>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { required, minLength, url, helpers } from 'vuelidate/lib/validators'
import CloudinaryUpload from '@/components/ImgUpload'
import imageHandler from '@/mixins/imageHandler'
import { cloneDeep, isEqual } from 'lodash'
import SaveReminder from '@/components/saveReminder'
import ValidationError from '@/components/validationError'
import SaveChanges from '@/components/saveChanges'
import Regions from '@/assets/regions'
import quillEditor from '@/mixins/quillEditor'
import { userOptionsByUniversity } from '@/mixins/options'
import Multiselect from 'vue-multiselect'

const objNotEmpty = helpers.withParams({ type: 'objNotEmpty' }, value => {
  return Object.keys(value).length > 0
})

export default {
  components: {
    CloudinaryUpload,
    SaveReminder,
    ValidationError,
    SaveChanges,
    Multiselect
  },
  mixins: [userOptionsByUniversity, imageHandler, quillEditor],
  props: {
    university: Object
  },
  data() {
    return {
      content: '',
      localUniversity: {
        name: '',
        city: '',
        country: '',
        website: '',
        region: '',
        description: '',
        contact: {},
        picture: null
      },
      universityTopics: null,
      regions: Regions
    }
  },
  computed: {
    ...mapState('universities', [
      'universityNameToEdit',
      'universityDescToEdit',
      'universityCityToEdit',
      'universityCountryToEdit',
      'universityWebsiteToEdit',
      'universityEditPending'
    ]),
    ...mapGetters('topics', ['getTopicById']),
    ...mapState('topics', ['topics']),
    ...mapGetters('users', ['getUserById']),
    dirty() {
      const tDirty = !isEqual(this.university.topics, this.universityTopics)
      const uniDirty = !isEqual(this.university, this.localUniversity)
      return tDirty || uniDirty
    }
  },
  created() {
    this.setUniversityNameToEdit(this.university.name)
    this.setUniversityDescToEdit(this.university.description)
    this.setUniversityCityToEdit(this.university.city)
    this.setUniversityCountryToEdit(this.university.country)
    this.setUniversityWebsiteToEdit(this.university.website)
    if (!Array.isArray(this.university.topics)) {
      this.universityTopics = cloneDeep(this.university.topics)
    } else {
      this.universityTopics = {}
    }
    this.content = this.university.description
    Object.assign(this.localUniversity, this.university)
    this.localUniversity.contact = this.getUserById(this.university.contact)
  },
  methods: {
    ...mapMutations('universities', [
      'setUniversityNameToEdit',
      'setUniversityDescToEdit',
      'setUniversityCityToEdit',
      'setUniversityCountryToEdit',
      'setUniversityRegionToEdit',
      'setUniversityWebsiteToEdit'
    ]),
    ...mapActions('universities', [
      'triggerEditUniversityAction',
      'editUniversity'
    ]),
    setPicture(src) {
      this.localUniversity.picture = src
    },
    isChecked(id, level) {
      if (id in this.universityTopics) {
        return this.universityTopics[id].includes(level)
      }

      return false
    },
    toggleTopic(id, level) {
      if (!(id in this.universityTopics)) {
        this.$set(this.universityTopics, id, [level])
      } else {
        const idx = this.universityTopics[id].indexOf(level)

        if (idx !== -1) {
          this.universityTopics[id].splice(idx, 1)
          if (this.universityTopics[id].length === 0) {
            this.$delete(this.universityTopics, id)
          }
        } else {
          this.universityTopics[id].push(level)
        }
      }
    },
    checkAllTopics(id) {
      this.$set(this.universityTopics, id, [
        'undergraduate',
        'graduate',
        'doctorate'
      ])
    },
    uncheckAllTopics(id) {
      this.$delete(this.universityTopics, id)
    },
    saveChanges() {
      Object.filter = (obj, predicate) =>
        Object.keys(obj)
          .filter(key => predicate(obj[key]))
          // eslint-disable-next-line no-sequences
          .reduce((res, key) => ((res[key] = obj[key]), res), {})
      const lTopics = Object.filter(this.universityTopics, t => t.length > 0)
      this.localUniversity.topics = lTopics
      this.localUniversity.contact = this.localUniversity.contact.id
      this.editUniversity(this.localUniversity)
      this.$router.push({
        name: 'university',
        params: { id: this.university.id }
      })
    }
  },
  validations: {
    localUniversity: {
      name: {
        required,
        minLength: minLength(4)
      },
      city: {
        required,
        minLength: minLength(4)
      },
      country: {
        required,
        minLength: minLength(3)
      },
      region: {
        required,
        minLength: minLength(1)
      },
      website: {
        required,
        url
      },
      description: {
        required
      }
    },
    universityTopics: {
      required,
      objNotEmpty
    }
  }
}
</script>

<style lang="scss" scoped></style>
