<template>
  <router-link
    class="db mv2 black no-underline"
    :to="{ name: 'member', params: { id: data.id } }"
  >
    <div class="flex w-100">
      <div
        class="mr2 shadow-1 bg-white hover-bg-navy hover-white bg-animate flex w-100"
      >
        <div class="br bw1 b--navy h3">
          <img
            v-if="data.picture"
            class="db h3 mw-none"
            :src="
              'https://res.cloudinary.com/iajesdb/image/upload/ar_1,c_crop,f_auto,g_face,q_auto:good,z_0.7/c_scale,w_64,ar_1/' +
                data.picture
            "
            :alt="data.name + ' - ' + 'profile picture'"
            :title="data.name + ' - ' + data.title"
          />
          <img
            v-if="!data.picture"
            class="db h3 mw-none"
            src="/img/default.png"
            :alt="data.name + ' - ' + 'profile picture'"
            :title="data.name + ' - ' + data.title"
          />
        </div>
        <div class="ph2 w-100">
          <span class="h3 dtc v-mid">
            {{ data.name }}
          </span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    data: Object,
    index: Number
  }
}
</script>

<style lang="scss" scoped></style>
