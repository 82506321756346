<template>
  <div class="project-action-bar">
    <h1>Create New Project</h1>
    <save-reminder :dirty="$v.$anyDirty"></save-reminder>

    <label for="project-name" class="b mv2 dib">Project Name</label>
    <validation-error :show="$v.name.$error">
      Name requires 4 or more characters.
    </validation-error>
    <input
      id="project-name"
      placeholder="project name..."
      type="text"
      class="pa2 w-100 mb3"
      @input="setProjectName($event.target.value)"
    />
    <label for="project-status" class="b mv2 db">Project Status</label>
    <validation-error :show="$v.selectedStatus.$invalid">
      Status is required.
    </validation-error>
    <select
      id="project-status"
      v-model.trim="selectedStatus"
      @change="setProjectStatusToCreate(selectedStatus)"
    >
      <option value="">--Please choose an option--</option>
      <option value="proposal">Proposal</option>
      <option v-if="isAllowed" value="ongoing">Ongoing</option>
      <option v-if="isAllowed" value="complete">Complete</option>
      <option v-if="isAllowed" value="inactive">Inactive</option>
    </select>

    <label
      v-if="isAllowed && selectedStatus !== 'proposal'"
      for="project-taskforces"
      class="b mv2 db"
      >Associated Task Forces (Choose 1 or more)</label
    >
    <validation-error :show="!isTaskforcesValid">
      Associated Task Forces is required
    </validation-error>
    <multiselect
      v-if="isAllowed && selectedStatus !== 'proposal'"
      id="project-taskforces"
      v-model.trim="selectedTaskforces"
      :options="taskforceOptions"
      :multiple="true"
      :close-on-select="true"
      placeholder="Task Forces"
      label="name"
      track-by="id"
      class="mb3"
      :disabled="!isAllowed"
      @input="setProjectTaskforcesToCreate(selectedTaskforces)"
    >
    </multiselect>
    <label for="project-leads" class="b mv2 db"
      >Project Leads (Choose 1 or more)</label
    >
    <validation-error :show="$v.selectedLeads.$invalid">
      Project Leads cannot be empty
    </validation-error>
    <multiselect
      id="project-leads"
      v-model.trim="selectedLeads"
      :options="userOptions"
      :multiple="true"
      :close-on-select="true"
      placeholder="People"
      label="name"
      track-by="id"
      class="mb3"
      @input="setProjectLeadsToCreate(selectedLeads)"
    >
    </multiselect>
    <label for="project-topics" class="b mv2 dib"
      >Project Areas of Specialization (Choose 1 or more)</label
    >
    <validation-error :show="$v.selectedTopics.$invalid">
      Areas of Specialization cannot be empty
    </validation-error>
    <multiselect
      id="project-topics"
      v-model.trim="selectedTopics"
      :options="topicOptions"
      :multiple="true"
      :close-on-select="true"
      placeholder="Areas of Specialization"
      label="name"
      track-by="id"
      class="mb3"
      @input="setProjectTopicsToCreate(selectedTopics)"
    >
    </multiselect>
    <label for="project-description" class="b mv2 dib"
      >Project Description</label
    >
    <validation-error :show="$v.content.$invalid">
      Description cannot be empty
    </validation-error>
    <vue-editor
      id="project-description"
      v-model.trim="content"
      :editor-options="editorSettings"
      placeholder="project description..."
      class="project-name-input mt3"
      rows="7"
      cols="40"
      @input="setProjectDescToCreate(content)"
    ></vue-editor>
    <save-changes
      :invalid="$v.$invalid || !isTaskforcesValid"
      @save-changes="saveChanges()"
    ></save-changes>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import ValidationError from '@/components/validationError'
import SaveReminder from '@/components/saveReminder'
import SaveChanges from '@/components/saveChanges'
import { taskforceOptions, topicOptions, userOptions } from '@/mixins/options'
import quillEditor from '@/mixins/quillEditor'

export default {
  components: {
    Multiselect,
    ValidationError,
    SaveReminder,
    SaveChanges
  },
  mixins: [taskforceOptions, topicOptions, userOptions, quillEditor],
  data() {
    return {
      content: '',
      selectedTaskforces: [],
      selectedLeads: [],
      selectedTopics: [],
      selectedStatus: '',
      name: ''
    }
  },
  computed: {
    ...mapState('projects', [
      'projectNameToCreate',
      'projectDescToCreate',
      'projectTaskforcesToCreate',
      'projectLeadsToCreate',
      'projectTopicsToCreate',
      'projectStatusToCreate',
      'projectCreationPending'
    ]),
    ...mapState('authentication', ['user']),
    ...mapGetters('users', ['getUserById']),
    ...mapGetters('admins', ['getAdminById']),
    ...mapState('admins', ['admins']),
    ...mapGetters('chairs', ['getChairById']),
    ...mapState('chairs', ['chairs']),
    isAllowed() {
      const isAdmin = this.getAdminById(this.user.id) !== false
      const isChair = this.getChairById(this.user.id) !== false
      const isPromoted = !(
        this.selectedStatus === '' || this.selectedStatus === 'proposal'
      )
      return isPromoted || isChair || isAdmin
    },
    isTaskforcesValid() {
      if (!this.isAllowed) return true
      if (this.selectedStatus === 'proposal') return true

      return this.selectedTaskforces.length > 0
    }
  },
  created() {
    const { id, name } = this.getUserById(this.user.id)
    this.selectedLeads.push({ id, name })
    this.setProjectLeadsToCreate(this.selectedLeads)
  },
  methods: {
    ...mapMutations('projects', [
      'setProjectNameToCreate',
      'setProjectDescToCreate',
      'setProjectLeadsToCreate',
      'setProjectStatusToCreate',
      'setProjectTopicsToCreate',
      'setProjectTaskforcesToCreate'
    ]),
    ...mapActions('projects', ['triggerAddProjectAction']),
    setProjectName(val) {
      this.name = val
      this.setProjectNameToCreate(val)
      this.$v.name.$touch()
    },
    saveChanges() {
      this.triggerAddProjectAction()
      this.$router.push({
        name: 'projects'
      })
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    selectedStatus: {
      required,
      minLength: minLength(4)
    },
    selectedLeads: {
      required,
      minLength: minLength(1)
    },
    selectedTopics: {
      required,
      minLength: minLength(1)
    },
    content: {
      required
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>
