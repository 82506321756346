import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('admins', ['getAdminById']),
    ...mapGetters('taskforces', ['isTaskforceChair']),
    ...mapState('authentication', ['user']),
    isAdmin() {
      if (this.user === null) return false

      return this.getAdminById(this.user.id) !== false
    },
    isTaskforceChairOrAbove() {
      if (this.user === null) return false

      return this.isAdmin || this.isTaskforceChair(this.user.id)
    }
  }
}
