export default {
  data() {
    return {
      small: true,
      width: null
    }
  },
  watch: {
    width() {
      if (this.width < 1000) {
        this.small = true
      } else {
        this.small = false
      }
    }
  },
  created() {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      setInterval(() => {
        this.width = window.innerWidth
      }, 100)
    }
  }
}
