import { find } from 'lodash'

export default {
  /**
   * Check if a chair has deletion pending
   */
  isChairDeletionPending: state => chairId =>
    state.chairDeletionPending.includes(chairId),

  isChairEditPending: state => chairId =>
    state.chairEditPending.includes(chairId),

  /**
   * Get chair by id
   */
  getChairById: state => chairId => {
    const res = find(state.chairs, chair => chair.id === chairId)

    if (res) {
      return res
    }

    return false
  }
}
