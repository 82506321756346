<template>
  <div class="mw8 center">
    <edit-project
      v-if="currentProject"
      :project="currentProject"
    ></edit-project>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditProject from '@/components/project/EditProject'

export default {
  components: { EditProject },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('projects', ['getProjectById']),
    currentProject() {
      return this.getProjectById(this.id)
    }
  }
}
</script>
