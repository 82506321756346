<template>
  <div>
    <a id="saveButton"></a>
    <transition
      enter-active-class="animate__animated animate__headShake animate__faster"
    >
      <div
        v-if="!invalid"
        class="bw1 ba br-1 b--blue link white bg-navy pa2 dim pointer dib mv3"
        @click="saveChanges()"
      >
        <i class="las la-save"></i>
        Save Changes
      </div>
    </transition>
    <transition
      enter-active-class="animate__animated animate__headShake animate__faster"
    >
      <div v-if="invalid" class="pa2 b-grey bw1 ba br1 o-80 dib mv3">
        <i class="las la-exclamation-triangle"></i>
        Cannot Save Changes Due to Errors
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SaveChanges',
  props: {
    invalid: Boolean
  },
  methods: {
    saveChanges() {
      this.$emit('save-changes')
    }
  }
}
</script>
