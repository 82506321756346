export default [
  {
    id: 'AJCU',
    name: 'AJCU',
    desc: 'United States and Canada'
  },
  {
    id: 'AJCU-AM',
    name: 'AJCU-AM',
    desc: 'Africa and Madagascar'
  },
  {
    id: 'AJCU-AP',
    name: 'AJCU-AP',
    desc: 'East Asia, Asia Pacific and Australia'
  },
  {
    id: 'AUSJAL',
    name: 'AUSJAL',
    desc: 'Latin America and the Carribean'
  },
  {
    id: 'JHEASA',
    name: 'JHEASA',
    desc: 'India'
  },
  {
    id: 'KIRCHER',
    name: 'Kircher Network',
    desc: 'Europe and the Middle East'
  }
]
