<template>
  <div class="offer-action-bar">
    <h1>Edit Opportunity for Collaboration</h1>
    <save-reminder :dirty="dirty"></save-reminder>
    <label for="offer-name" class="b mv2 dib"
      >Opportunity for Collaboration Name</label
    >
    <validation-error :show="$v.localOffer.name.$error">
      Name requires 4 or more characters.
    </validation-error>
    <input
      v-model.trim="localOffer.name"
      placeholder="Opportunity for Collaboration name..."
      type="text"
      class="pa2 w-100 mv3"
      @input="setOfferNameToEdit($event.target.value)"
    />
    <label for="offer-topics" class="b mv2 dib"
      >Areas of Specialization (Choose 0 or more)</label
    >
    <multiselect
      id="offer-topics"
      v-model.trim="localOfferTopics"
      :options="topicOptions"
      :multiple="true"
      :close-on-select="true"
      placeholder="Areas of Specialization"
      label="name"
      track-by="id"
      class="mb3"
      @input="setOfferTopicsToEdit(localOfferTopics)"
    >
    </multiselect>
    <label for="offer-expiry" class="b mv2 dib"
      >Opportunity for Collaboration Expiration (in Weeks)</label
    >
    <p class="i">
      Opportunities will automatically expire at given number of weeks.
    </p>
    <p v-if="!renew">
      Expires after {{ localOffer.expiry.toLocaleDateString() }}.
      <span class="link underline pointer dim" @click="toggleRenew">Renew</span>
    </p>
    <div v-if="renew" class="w-100">
      <input
        v-model="weeks"
        min="1"
        max="10"
        type="number"
        class="pa2 mr2 mb3 w3"
      />
      <span class="pr2"
        >Expires after {{ newExpiry.toLocaleDateString() }}</span
      >
      <span class="link underline pointer dim" @click="toggleRenew"
        >Cancel Renewal</span
      >
    </div>
    <label for="offer-description" class="b mv2 dib"
      >Opportunity for Collaboration Description</label
    >
    <validation-error :show="$v.localOffer.description.$invalid">
      Description cannot be empty
    </validation-error>
    <vue-editor
      v-model.trim="localOffer.description"
      placeholder="Opportunity for Collaboration description..."
      class="offer-name-input"
      rows="7"
      cols="40"
      @input="setOfferDescToEdit(localOffer.description)"
    ></vue-editor>
    <save-changes
      :invalid="$v.$invalid"
      @save-changes="saveChanges()"
    ></save-changes>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import ValidationError from '@/components/validationError'
import SaveReminder from '@/components/saveReminder'
import SaveChanges from '@/components/saveChanges'
import { topicOptions } from '@/mixins/options'
import quillEditor from '@/mixins/quillEditor'
import { isEqual } from 'lodash'
import imageHandler from '@/mixins/imageHandler'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  components: {
    Multiselect,
    ValidationError,
    SaveReminder,
    SaveChanges
  },
  mixins: [topicOptions, quillEditor, imageHandler],
  props: {
    offer: Object
  },
  data() {
    return {
      content: '',
      localOffer: {
        name: '',
        description: '',
        expiry: ''
      },
      weeks: 3,
      newExpiry: '',
      renew: false,
      localOfferTopics: []
    }
  },
  computed: {
    ...mapState('offers', [
      'offerNameToEdit',
      'offerDescToEdit',
      'offerTopicsToEdit',
      'offerExpiryToEdit',
      'offerEditPending'
    ]),
    ...mapState('topics', ['topics']),
    ...mapGetters('topics', ['getTopicById']),
    dirty() {
      const oDirty = !isEqual(this.offer, this.localOffer)
      const tDirty = !isEqual(this.offer.topics, this.localOfferTopics)
      const rDirty = this.renew === true
      return oDirty || tDirty || rDirty
    }
  },
  watch: {
    weeks: {
      immediate: true,
      handler() {
        const week = 1000 * 60 * 60 * 24 * 7
        this.newExpiry = new Date(new Date().getTime() + week * this.weeks)
      }
    }
  },
  created() {
    this.setOfferNameToEdit(this.offer.name)
    this.setOfferDescToEdit(this.offer.description)
    this.content = this.offer.description
    const localOfferTopics = this.offer.topics.flatMap(t => {
      return { name: this.getTopicById(t.id).name, id: t.id }
    })
    this.localOfferTopics = localOfferTopics.filter(o => o.name)
    Object.assign(this.localOffer, this.offer)
  },
  methods: {
    ...mapMutations('offers', [
      'setOfferNameToEdit',
      'setOfferDescToEdit',
      'setOfferTopicsToEdit',
      'setOfferExpiryToEdit'
    ]),
    ...mapActions('offers', ['triggerEditOfferAction', 'editOffer']),
    toggleRenew() {
      this.renew = !this.renew
    },
    saveChanges() {
      this.localOffer.topics = this.localOfferTopics
      if (this.renew) {
        this.setOfferExpiryToEdit(this.newExpiry)
        this.localOffer.expiry = this.newExpiry
      }
      this.editOffer(this.localOffer)
      this.$router.push({
        name: 'offer',
        params: { id: this.offer.id }
      })
    }
  },
  validations: {
    localOffer: {
      name: {
        required,
        minLength: minLength(4)
      },
      description: {
        required
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>
