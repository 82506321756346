import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/performance'
import 'firebase/analytics'

// The configuration below is not sensitive data. You can serenely add your config here
const config = {
  apiKey: 'AIzaSyBET7wgcXOtakITKC99aZraWU2f0tllGtM',
  authDomain: 'iajes-private.firebaseapp.com',
  projectId: 'iajes-private',
  storageBucket: 'iajes-private.appspot.com',
  messagingSenderId: '18456406000',
  appId: '1:18456406000:web:bad7f79851c9b7550ddf35'
}

firebase.initializeApp(config)
firebase.performance()
firebase.analytics()
