import router from '@/router'
import { isNil } from 'lodash'
import { createNewUserFromFirebaseAuthUser } from '@/misc/helpers'
import UsersDB from '@/firebase/users-db'
import firebase from 'firebase/app'

export default {
  /**
   * Callback fired when user login
   */
  login: async ({ commit, dispatch }, firebaseAuthUser) => {
    const userFromFirebase = await new UsersDB()
      .read(firebaseAuthUser.uid)
      .catch(async () => {
        const { uid, email } = firebaseAuthUser
        await firebase.auth().signOut()
        await commit('setUser', null)
        commit('taskforces/setTaskforces', null, { root: true })
        commit('projects/setProjects', null, { root: true })
        commit('universities/setUniversities', null, { root: true })
        commit('topics/setTopics', null, { root: true })
        commit('users/setUsers', null, { root: true })
        commit('requests/setRequests', [], { root: true })
        commit('chairs/setChairs', [], { root: true })
        commit('admins/setAdmins', [], { root: true })
        commit('offers/setOffers', [], { root: true })
        router.push(`baduser/${uid}/${email}`)
      })
      .then(async () => {
        const user = isNil(userFromFirebase)
          ? await createNewUserFromFirebaseAuthUser(firebaseAuthUser)
          : userFromFirebase

        commit('setUser', user)
        await dispatch('taskforces/getTaskforces', null, { root: true })
        await dispatch('projects/getProjects', null, { root: true })
        await dispatch('universities/getUniversities', null, { root: true })
        await dispatch('topics/getTopics', null, { root: true })
        await dispatch('users/getUsers', null, { root: true })
        await dispatch('requests/getRequests', null, { root: true })
        await dispatch('resources/getResources', null, { root: true })
        await dispatch('chairs/getChairs', null, { root: true })
        await dispatch('admins/getAdmins', null, { root: true })
        await dispatch('offers/getOffers', null, { root: true })
      })
  },

  /**
   * Callback fired when user logout
   */
  logout: ({ commit }) => {
    commit('setUser', null)
    commit('taskforces/setTaskforces', null, { root: true })
    commit('projects/setProjects', null, { root: true })
    commit('universities/setUniversities', null, { root: true })
    commit('topics/setTopics', null, { root: true })
    commit('users/setUsers', null, { root: true })
    commit('requests/setRequests', [], { root: true })
    commit('resources/setResources', [], { root: true })
    commit('chairs/setChairs', [], { root: true })
    commit('admins/setAdmins', [], { root: true })
    commit('offers/setOffers', [], { root: true })
    const currentRouter = router.app.$route
    if (!(currentRouter.meta && currentRouter.meta.authNotRequired)) {
      router.push('/login')
    }
  }
}
