import Taskforces from '@/views/taskforce/Taskforces'
import Taskforce from '@/views/taskforce/Taskforce'
import AddTaskforce from '@/views/taskforce/AddTaskforce'
import EditTaskforce from '@/views/taskforce/EditTaskforce'
import { isAdmin, isLocalChair } from '@/router/helpers'

const routes = [
  {
    path: '/taskforces',
    name: 'taskforces',
    component: Taskforces
  },
  {
    path: '/taskforces/:id',
    name: 'taskforce',
    props: true,
    component: Taskforce
  },
  {
    path: '/taskforces/edit/:id',
    name: 'edit-taskforce',
    props: true,
    component: EditTaskforce,
    beforeEnter: (to, from, next) => {
      if (isAdmin() || isLocalChair(to)) return next()
      return next('/unauthorized')
    }
  },
  {
    path: '/taskforces/add',
    name: 'add-taskforce',
    props: true,
    component: AddTaskforce,
    meta: {
      reqTaskforceChair: true
    }
  }
]

export default routes
