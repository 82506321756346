<template>
  <div class="mw7 center">
    <div class="flex justify-end mt3">
      <router-link
        v-if="networkOnLine && (isAdmin || isOwner)"
        class="bw1 ba br-1 b--blue link white bg-navy pa2 dim pointer dib"
        :to="{ name: 'edit-offer', params: { id: offer.id } }"
      >
        <i class="las la-edit"></i>
        {{ isOfferEditPending(offer.id) ? 'edit in progress...' : 'edit' }}
      </router-link>
      <div
        v-if="networkOnLine && (isAdmin || isOwner)"
        class="bw1 ba br-1 b--red link white bg-dark-red pa2 dim pointer dib ml2"
        @click="saveChanges(offer.id)"
      >
        <i class="las la-trash"></i>
        {{
          isOfferDeletionPending(offer.id) ? 'delete in progress...' : 'delete'
        }}
      </div>
    </div>
    <h1 class="bw1 bb b-grey">{{ offer.name }}</h1>
    <p>Expires after {{ offer.expiry.toLocaleDateString() }}</p>
    <div class="db b mb2">
      Relevant Areas of Specialization:
      <span v-if="offer.topics.length === 0" class="pl2 i"
        >None at this time</span
      >
    </div>
    <div class="flex mb3">
      <ul class="list flex flex-wrap pa0 ma0">
        <li v-for="u in offer.topics" :key="u.id" class="ml2">
          <router-link
            class="pa1 no-underline bb bw1 b--blue navy hover-bg-navy hover-white bg-animate flex flex-nowrap"
            :to="{ name: 'topic', params: { id: u.id } }"
            :v-if="getTopicById(u.id)"
          >
            <div class="">
              {{ getTopicById(u.id).name }}
            </div>
            <i class="las la-angle-right db f4 b"></i>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="db b mb2">
      Posted by:
    </div>
    <div class="">
      <div class="flex flex-wrap">
        <user-item-small
          class="db"
          :class="{ 'w-25': !small, 'w-100': small }"
          :data="getUserById(offer.owner)"
        ></user-item-small>
      </div>
    </div>
    <!-- eslint-disable -->
    <div v-html="highlightedDescription"></div>
    <!-- eslint-enable -->
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import csReplace from 'preserve-case'
import adminHelper from '@/mixins/adminHelper'
import resizeMixin from '@/mixins/resizeMixin'
import UserItemSmall from '@/components/user/UserItemSmall'

export default {
  components: { UserItemSmall },
  mixins: [adminHelper, resizeMixin],
  props: {
    offer: Object
  },
  computed: {
    ...mapGetters('offers', ['isOfferDeletionPending', 'isOfferEditPending']),
    ...mapGetters('topics', ['getTopicById']),
    ...mapGetters('users', ['getUserById']),
    ...mapState('offers', ['offers']),
    ...mapState('app', ['networkOnLine']),
    ...mapState('authentication', ['user']),
    query() {
      return this.$route.params.q || ''
    },
    highlightedDescription() {
      if (this.query.length < 4) {
        return this.offer.description
      }

      let hDesc = csReplace.all(
        this.offer.description,
        this.query,
        `<::${this.query}::>`
      )

      hDesc = hDesc.replaceAll('<::', `<span class='bg-yellow'>`)

      hDesc = hDesc.replaceAll('::>', '</span>')

      return hDesc
    },
    isOwner() {
      if (this.user === null) return false

      return this.offer.owner === this.user.id
    }
  },
  methods: {
    ...mapActions('offers', ['deleteOffer']),
    saveChanges(id) {
      this.$dialog
        .confirm('Are you sure? Deleted records cannot be recovered.')
        .then(() => {
          this.deleteOffer(id)
          this.$router.push({
            name: 'offers'
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped></style>
