import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import Home from '@/views/Home'
import CheckLogin from '@/views/CheckLogin'
import { isNil } from 'lodash'
import store from '@/store'
import UniversityRoutes from '@/router/university'
import ResourceRoutes from '@/router/resource'
import TaskforceRoutes from '@/router/taskforce'
import UserRoutes from '@/router/user'
import ProjectRoutes from '@/router/project'
import TopicRoutes from '@/router/topic'
import RequestRoutes from '@/router/request'
import OfferRoutes from '@/router/offer'
import { isAdmin, isChair } from '@/router/helpers'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/check-login',
      name: 'check-login',
      component: CheckLogin,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/profile',
      name: 'profile',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-profile" */ '@/views/Profile.vue'
        )
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-unauthorized" */ '@/views/Unauthorized.vue'
        )
    },
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ '@/views/Login.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/baduser/:id/:email',
      name: 'baduser',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-baduser" */ '@/views/BadUser.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/approval',
      name: 'pending-approval',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-pending-approval" */ '@/views/PendingApproval.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    ...UniversityRoutes,
    ...TaskforceRoutes,
    ...UserRoutes,
    ...ProjectRoutes,
    ...TopicRoutes,
    ...RequestRoutes,
    ...ResourceRoutes,
    ...OfferRoutes,
    { path: '*', redirect: '/home' }
  ]
})

/**
 * Handle user redirections
 */
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  if (
    !(to.meta && to.meta.authNotRequired) &&
    isNil(store.state.authentication.user)
  ) {
    let path = '/check-login'
    if (store.state.authentication.user === null) {
      path = '/login'
    }

    return next(`${path}?redirectUrl=${to.path}`)
  }

  if (to.meta.reqTaskforceChair) {
    if (isAdmin() || isChair()) return next()
    return next('/unauthorized')
  }

  if (to.meta.reqAdmin) {
    if (isAdmin()) return next()
    return next('/unauthorized')
  }

  next()
})

export default router
