<template>
  <div>
    <p v-if="universities === null" class="i">Loading...</p>
    <p v-if="universities && !universities.length" class="i">
      You don't have any universities yet
    </p>
    <div class="flex justify-between flex-wrap mv1">
      <div class="i f3 pt1">Filter by Region</div>
      <div class="flex w-50-l justify-end">
        <div
          class="pa2 b-grey bw1 ba br1 dib ta dim pointer mr2"
          :class="{ 'black-30': selectedRegions.length === 0 }"
          @click="selectNone"
        >
          <i class="las la-times"></i> Select None
        </div>
        <div
          class="pa2 b-grey bw1 ba br1 dib ta dim pointer"
          :class="{ 'black-30': selectedRegions.length === 6 }"
          @click="selectAll"
        >
          <i class="las la-check"></i> Select All
        </div>
      </div>
    </div>
    <div class="flex flex-wrap justify-center">
      <div
        v-for="r in regions"
        :key="r.id"
        class="flex ma2"
        :class="{ 'w-100': small, 'w-30': !small }"
        @click="toggleId(r.id)"
      >
        <div
          class="mr2 shadow-1 bg-white hover-bg-navy hover-white bg-animate pointer flex w-100"
        >
          <div class="br bw1 b--navy dtc tc ph3 pt1">
            <i
              class="las la-check b f1 ta"
              :class="{ 'o-0': !showCheck(r.id) }"
            ></i>
          </div>
          <div class="tl ph2">
            <span class="h3 dtc v-mid w-80">
              {{ r.name }} <br />
              <span class="i f5"> {{ r.desc }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="mb0 relative">
      <i
        class="las la-search absolute left-1 black z-max mt3 pt1 f3 dn db-ns"
      ></i>
      <input
        id="university-search"
        v-model.trim="query"
        type="text"
        placeholder="Search all universities"
        class="1 b--mid-gray pl5 pa3 w-100 f3"
      />
      <i
        v-show="query.length > 0"
        class="las la-backspace absolute right-1 highlight pointer dim z-max mt3 pt1 f3 dn db-ns"
        @click="clearQuery()"
      ></i>
      <label for="university-search"></label>
    </div>
    <filter-bar
      :current="filteredUniversities.length"
      :total="universities.length"
      name="university"
      plural="universities"
    ></filter-bar>
    <university-item
      v-for="(university, index) in filteredUniversities"
      :key="university.id"
      class="university-row"
      :query="query"
      :index="index"
      :is-university-deletion-pending="
        isUniversityDeletionPending(university.id)
      "
      :disable-actions="!networkOnLine"
      :data="university"
      @deleteUniversity="deleteUniversity"
    ></university-item>
    <div
      v-if="filteredUniversities.length === 0"
      class="mid-gray i center tc f3"
    >
      <i class="las la-exclamation-triangle"></i> No Search Results Found
    </div>
  </div>
</template>

<script>
import UniversityItem from '@/components/university/UniversityItem'
import { mapState, mapActions, mapGetters } from 'vuex'
import Regions from '@/assets/regions'
import elasticlunr from 'elasticlunr'
import FilterBar from '@/components/filterBar'
import resizeMixin from '@/mixins/resizeMixin'

export default {
  components: {
    UniversityItem,
    FilterBar
  },
  mixins: [resizeMixin],
  data() {
    return {
      query: '',
      searchIndex: null,
      searchRes: [],
      regions: [...Regions],
      selectedRegions: [...Regions]
    }
  },
  computed: {
    ...mapGetters('universities', ['isUniversityDeletionPending']),
    ...mapState('universities', ['universities']),
    ...mapState('app', ['networkOnLine']),
    selectedIds() {
      return this.selectedRegions.map(r => r.id)
    },
    filteredUniversities() {
      if (this.universities) {
        const arr = this.universities.filter(u => this.visible(u))
        return arr.sort((a, b) => {
          if (a.name > b.name) {
            return 1
          }

          if (a.name < b.name) {
            return -1
          }

          return 0
        })
      }

      return []
    }
  },
  watch: {
    universities() {
      this.buildIndex()
    },
    query() {
      if (this.searchIndex) {
        this.searchRes = this.searchIndex.search(this.query, { expand: true })
      }
    }
  },
  created() {
    this.buildIndex()
  },
  methods: {
    ...mapActions('universities', ['deleteUniversity']),
    showCheck(id) {
      return this.selectedIds.includes(id)
    },
    toggleId(id) {
      const idx = this.selectedIds.indexOf(id)
      if (idx === -1) {
        const r = this.regions.find(rs => rs.id === id)
        this.selectedRegions.push(r)
      } else {
        this.selectedRegions.splice(idx, 1)
      }
    },
    selectAll() {
      this.selectedRegions = [...Regions]
    },
    selectNone() {
      this.selectedRegions = []
    },
    buildIndex() {
      if (this.universities) {
        this.searchIndex = elasticlunr(function f() {
          this.addField('name')
          this.addField('description')
          this.addField('city')
          this.addField('country')
          this.addField('region')
          this.setRef('id')
        })

        this.searchIndex.saveDocument(false)
        const i = this.universities.map(x => {
          return {
            name: x.name,
            description: x.description,
            city: x.city,
            country: x.country,
            region: x.region,
            id: x.id
          }
        })

        i.forEach(x => {
          this.searchIndex.addDoc(x)
        })
      }
    },
    clearQuery() {
      this.query = ''
    },
    visible(u) {
      let inSearch = false

      if (this.query.length < 3) {
        inSearch = true
      } else {
        this.searchRes.forEach(r => {
          if (r.ref === u.id) {
            inSearch = true
          }
        })
      }
      return inSearch && this.showCheck(u.region)
    }
  }
}
</script>

<style lang="scss" scoped></style>
