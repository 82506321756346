import { find } from 'lodash'

export default {
  /**
   * Check if a user has deletion pending
   */
  isUserDeletionPending: state => userId =>
    state.userDeletionPending.includes(userId),

  isUserEditPending: state => userId => state.userEditPending.includes(userId),

  /**
   * Get user by id
   */
  getUserById: state => userId => {
    const res = find(state.users, user => user.id === userId)

    if (res) {
      return res
    }

    return false
  }
}
