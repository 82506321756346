export default {
  /* Chairs */
  setChairs: (state, chairs) => (state.chairs = chairs),
  addChair: (state, chair) => state.chairs.push(chair),
  removeChairById: (state, chairId) => {
    const index = state.chairs.findIndex(chair => chair.id === chairId)
    state.chairs.splice(index, 1)
  },

  /* Chairs deletion */
  addChairDeletionPending: (state, chairId) =>
    state.chairDeletionPending.push(chairId),
  removeChairDeletionPending: (state, chairId) => {
    const index = state.chairDeletionPending.indexOf(chairId)
    state.chairDeletionPending.splice(index, 1)
  },

  /* Chairs edit */
  addChairEditPending: (state, chairId) => state.chairEditPending.push(chairId),
  removeChairEditPending: (state, chairId) => {
    const index = state.chairEditPending.indexOf(chairId)
    state.chairEditPending.splice(index, 1)
  }
}
