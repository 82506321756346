<template>
  <div class="resource-action-bar">
    <save-reminder :dirty="dirty"></save-reminder>
    <h1>Edit Resource</h1>
    <label for="resource-name" class="b mv2 dib">Resource Name</label>
    <validation-error :show="$v.localResource.name.$invalid">
      Name requires 4 or more characters.
    </validation-error>
    <input
      id="resource-name"
      v-model.trim="localResource.name"
      placeholder="resource name..."
      type="text"
      class="pa2 w-100 mt1 mb3"
      @input="setResourceNameToEdit($event.target.value)"
    />
    <label for="resource-audience" class="b mv2 dib">Resource Audience</label>
    <validation-error :show="$v.localResource.audience.$invalid">
      Audience is required
    </validation-error>
    <select
      id="resource-audience"
      v-model.trim="localResource.audience"
      @change="setResourceAudienceToEdit(localResource.audience)"
    >
      <option value="">--Please choose an option--</option>
      <option v-for="a in audiences" :key="a" :value="a">{{
        keyToName(a)
      }}</option>
    </select>
    <label for="resource-type" class="b mv2 db">Resource Type</label>
    <validation-error :show="$v.localResource.type.$invalid">
      Type is required.
    </validation-error>
    <select
      id="resource-type"
      v-model.trim="localResource.type"
      @change="setResourceTypeToEdit(localResource.type)"
    >
      <option value="">--Please choose an option--</option>
      <option v-for="t in types" :key="t" :value="t">{{ keyToName(t) }}</option>
    </select>

    <label for="resource-taskforce" class="b mv2 db">Task Force</label>
    <validation-error :show="$v.localResource.taskforce.$invalid">
      Please select a Task Force
    </validation-error>
    <multiselect
      id="resource-taskforce"
      v-model.trim="localResource.taskforce"
      :options="taskforceOptions"
      :multiple="false"
      :close-on-select="true"
      placeholder="Task Rorce"
      label="name"
      track-by="id"
      class="mb3"
      @input="setResourceTaskforceToEdit(localResource.taskforce)"
    >
    </multiselect>
    <label for="resource-language" class="b mv2 dib">Resource Language</label>
    <validation-error :show="$v.localResource.language.$invalid">
      Resource Language cannot be empty
    </validation-error>
    <multiselect
      id="resource-language"
      v-model.trim="localResource.language"
      :options="languageOptions"
      :multiple="false"
      :close-on-select="true"
      placeholder="Language"
      :custom-label="nameWithNative"
      track-by="id"
      class="mb3"
      @input="setResourceLanguageToEdit(localResource.language)"
    >
    </multiselect>
    <label for="resource-topics" class="b mv2 dib"
      >Resource Areas of Specialization (Choose 1 or more)</label
    >
    <validation-error :show="$v.localResourceTopics.$invalid">
      Areas of Specialization cannot be empty
    </validation-error>
    <multiselect
      id="resource-topics"
      v-model.trim="localResourceTopics"
      :options="topicOptions"
      :multiple="true"
      :close-on-select="true"
      placeholder="Areas of Specialization"
      label="name"
      track-by="id"
      class="mb3"
      @input="setResourceTopicsToEdit(localResourceTopics)"
    >
    </multiselect>
    <label for="resource-description" class="b mv2 dib"
      >Resource Description</label
    >
    <validation-error :show="$v.localResource.description.$invalid">
      Description cannot be empty
    </validation-error>
    <vue-editor
      id="resource-description"
      v-model.trim="localResource.description"
      :editor-options="editorSettings"
      placeholder="resource description..."
      class="resource-name-input mt3"
      rows="7"
      cols="40"
      use-custom-image-handler
      @image-added="handleImageAdded"
      @input="setResourceDescToEdit(localResource.description)"
    ></vue-editor>
    <save-changes
      :invalid="$v.$invalid"
      @save-changes="saveChanges()"
    ></save-changes>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import imageHandler from '@/mixins/imageHandler'
import { cloneDeep, isEqual } from 'lodash'
import SaveReminder from '@/components/saveReminder'
import ValidationError from '@/components/validationError'
import SaveChanges from '@/components/saveChanges'
import Languages from '@/assets/languages'
import Types from '@/assets/resourceTypes'
import Audiences from '@/assets/resourceAudiences'
import quillEditor from '@/mixins/quillEditor'
import { taskforceOptions, topicOptions } from '@/mixins/optionsWithUser'

export default {
  components: {
    Multiselect,
    SaveReminder,
    ValidationError,
    SaveChanges
  },
  mixins: [imageHandler, taskforceOptions, topicOptions, quillEditor],
  props: {
    resource: Object
  },
  data() {
    return {
      content: '',
      languageOptions: Languages,
      types: Types,
      audiences: Audiences,
      localResource: {
        name: '',
        type: '',
        description: ''
      },
      localResourceTopics: []
    }
  },
  computed: {
    ...mapState('resources', [
      'resourceNameToEdit',
      'resourceAudienceToEdit',
      'resourceDescToEdit',
      'resourceTaskforceToEdit',
      'resourceLanguageToEdit',
      'resourceTopicsToEdit',
      'resourceTypeToEdit',
      'resourceEditPending'
    ]),
    dirty() {
      const rDirty = !isEqual(this.resource, this.localResource)
      const tDirty = !isEqual(this.resource.topics, this.localResourceTopics)
      return rDirty || tDirty
    }
  },
  created() {
    this.setResourceNameToEdit(this.resource.name)
    this.setResourceDescToEdit(this.resource.description)
    this.setResourceTaskforceToEdit(this.resource.taskforce)
    this.setResourceLanguageToEdit(this.resource.language)
    this.setResourceTypeToEdit(this.resource.type)
    this.setResourceAudienceToEdit(this.resource.audience)
    this.content = this.resource.description
    const localResourceTopics = this.resource.topics.flatMap(t => {
      return { name: this.getTopicById(t.id).name, id: t.id }
    })
    this.localResourceTopics = localResourceTopics.filter(o => o.name)
    this.localResource = cloneDeep(this.resource)
  },

  methods: {
    ...mapMutations('resources', [
      'setResourceNameToEdit',
      'setResourceAudienceToEdit',
      'setResourceDescToEdit',
      'setResourceTaskforceToEdit',
      'setResourceTypeToEdit',
      'setResourceTopicsToEdit',
      'setResourceLanguageToEdit'
    ]),
    ...mapActions('resources', ['triggerEditResourceAction', 'editResource']),
    nameWithNative({ name, nativeName }) {
      return `${name} — ${nativeName}`
    },
    keyToName(k) {
      return k.replace('_', ' ').replace(/\w\S*/g, txt => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    },
    saveChanges() {
      this.localResource.topics = this.localResourceTopics
      this.editResource(this.localResource)
      this.$router.push({
        name: 'resource',
        params: { id: this.resource.id }
      })
    }
  },
  validations: {
    localResource: {
      name: {
        required,
        minLength: minLength(4)
      },
      type: {
        required,
        minLength: minLength(4)
      },
      audience: {
        required,
        minLength: minLength(4)
      },
      description: {
        required
      },
      taskforce: {
        required,
        minLength: minLength(1)
      },
      language: {
        required,
        minLength: minLength(1)
      }
    },

    localResourceTopics: {
      required,
      minLength: minLength(1)
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>
