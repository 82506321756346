import { render, staticRenderFns } from "./AddUniversity.vue?vue&type=template&id=0bc39da0&scoped=true&"
import script from "./AddUniversity.vue?vue&type=script&lang=js&"
export * from "./AddUniversity.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bc39da0",
  null
  
)

export default component.exports