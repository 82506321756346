export default {
  requests: null,
  requestNameToCreate: '',
  requestNameToEdit: '',
  requestEmailToCreate: '',
  requestEmailToEdit: '',
  requestDeletionPending: [],
  requestCreationPending: false,
  requestEditPending: []
}
