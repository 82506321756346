import { find, isNil } from 'lodash'

export default {
  /**
   * Check if a university has deletion pending
   */
  isUniversityDeletionPending: state => universityId =>
    state.universityDeletionPending.includes(universityId),

  isUniversityEditPending: state => universityId =>
    state.universityEditPending.includes(universityId),

  /**
   * Get university by id
   */
  getUniversityById: state => universityId => {
    const res = find(
      state.universities,
      university => university.id === universityId
    )

    if (res) {
      return res
    }

    return false
  },

  getUniversityContactIds: state => {
    const res = []
    const unisWithContact = state.universities.filter(u => !isNil(u.contact))
    unisWithContact.map(u => res.push(u.contact))
    return res
  }
}
