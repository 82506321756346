<template>
  <div class="mw8 center">
    <div class="flex justify-between flex-wrap mv1">
      <h1 class="db mv3">Projects</h1>
      <div
        v-if="networkOnLine"
        class="bw1 ba br-1 b--blue link white bg-navy pa2 dim pointer db mv3"
        @click="$router.push({ name: 'add-project' })"
      >
        <i class="las la-plus"></i>
        Project
      </div>
    </div>
    <project-list class="project-list"></project-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProjectList from '@/components/project/ProjectList'

export default {
  components: { ProjectList },
  computed: mapState('app', ['networkOnLine'])
}
</script>

<style lang="scss" scoped></style>
