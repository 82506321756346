export default {
  topics: null,
  topicNameToCreate: '',
  topicDescToCreate: '',
  topicNameToEdit: '',
  topicDescToEdit: '',
  topicDeletionPending: [],
  topicCreationPending: false,
  topicEditPending: []
}
