export default {
  /* Admins */
  setAdmins: (state, admins) => (state.admins = admins),
  addAdmin: (state, admin) => state.admins.push(admin),
  removeAdminById: (state, adminId) => {
    const index = state.admins.findIndex(admin => admin.id === adminId)
    state.admins.splice(index, 1)
  },

  /* Admins deletion */
  addAdminDeletionPending: (state, adminId) =>
    state.adminDeletionPending.push(adminId),
  removeAdminDeletionPending: (state, adminId) => {
    const index = state.adminDeletionPending.indexOf(adminId)
    state.adminDeletionPending.splice(index, 1)
  },

  /* Admins edit */
  addAdminEditPending: (state, adminId) => state.adminEditPending.push(adminId),
  removeAdminEditPending: (state, adminId) => {
    const index = state.adminEditPending.indexOf(adminId)
    state.adminEditPending.splice(index, 1)
  }
}
