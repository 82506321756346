<template>
  <router-link
    class="flex justify-between w-100 mv2 pa2 f4 bgh-light-grey pointerdb black no-underline"
    :to="{ name: 'request', params: { id: data.id } }"
  >
    <div class="">{{ data.name }}</div>
    <div>{{ data.createTimestamp }}</div>
    <i class="las la-angle-right db f4 b dark-blue"></i>
  </router-link>
</template>

<script>
export default {
  props: {
    data: Object,
    index: Number
  }
}
</script>

<style lang="scss" scoped></style>
