<template>
  <div>
    <p v-if="projects === null" class="i">Loading...</p>
    <p v-if="projects && !projects.length" class="i">
      You don't have any projects yet
    </p>
    <div class="flex flex-wrap justify-between mv1">
      <div class="i f3 pt1">Filter by Status</div>
      <div class="flex w-50-l justify-end">
        <div
          class="pa2 b-grey bw1 ba br1 dib ta dim pointer mr2"
          :class="{ 'black-30': selectedStatus.length === 0 }"
          @click="selectNone"
        >
          <i class="las la-times"></i> Select None
        </div>
        <div
          class="pa2 b-grey bw1 ba br1 dib ta dim pointer"
          :class="{ 'black-30': selectedStatus.length === 4 }"
          @click="selectAll"
        >
          <i class="las la-check"></i> Select All
        </div>
      </div>
    </div>
    <div class="flex flex-wrap justify-start">
      <div
        v-for="s in status"
        :key="s"
        class="flex flex-wrap ma2 ml0"
        @click="toggleStatus(s)"
      >
        <div
          class="mr2 shadow-1 bg-white hover-bg-navy hover-white bg-animate pointer flex w-100"
        >
          <div class="br bw1 b--navy dtc tc ph3 pt1">
            <i
              class="las la-check b f2 ta"
              :class="{ 'o-0': !showCheck(s) }"
            ></i>
          </div>
          <div class="tl ph2 flex flex-column justify-center">
            {{ capitalizeStatus(s) }}
          </div>
        </div>
      </div>
    </div>
    <div class="mb0 relative">
      <i
        class="las la-search absolute left-1 black z-max mt3 pt1 f3 dn db-ns"
      ></i>
      <input
        id="project-search"
        v-model.trim="query"
        type="text"
        placeholder="Search all projects"
        class="1 b--mid-gray pl5 pa3 w-100 f3"
      />
      <i
        v-show="query.length > 0"
        class="las la-backspace absolute right-1 highlight pointer dim z-max mt3 pt1 f3 dn db-ns"
        @click="clearQuery()"
      ></i>
      <label for="project-search"></label>
    </div>
    <filter-bar
      :current="filteredProjects.length"
      :total="projects.length"
      name="project"
    ></filter-bar>

    <project-item
      v-for="(project, index) in filteredProjects"
      :key="project.id"
      class="project-row"
      :query="query"
      :index="index"
      :is-project-deletion-pending="isProjectDeletionPending(project.id)"
      :disable-actions="!networkOnLine"
      :data="project"
      @deleteProject="deleteProject"
    ></project-item>
    <div v-if="filteredProjects.length === 0" class="mid-gray i center tc f3">
      <i class="las la-exclamation-triangle"></i> No Search Results Found
    </div>
  </div>
</template>
<script>
import ProjectItem from '@/components/project/ProjectItem'
import { mapState, mapActions, mapGetters } from 'vuex'
import elasticlunr from 'elasticlunr'
import FilterBar from '@/components/filterBar'

export default {
  components: { ProjectItem, FilterBar },
  data() {
    return {
      query: '',
      searchIndex: null,
      searchRes: [],
      status: ['proposal', 'ongoing', 'complete', 'inactive'],
      selectedStatus: ['proposal', 'ongoing', 'complete', 'inactive']
    }
  },
  computed: {
    ...mapGetters('projects', ['isProjectDeletionPending']),
    ...mapState('projects', ['projects']),
    ...mapState('app', ['networkOnLine']),
    filteredProjects() {
      if (this.projects) {
        const arr = this.projects.filter(p => {
          return this.visible(p)
        })

        return arr
      }

      return []
    }
  },
  watch: {
    projects() {
      this.buildIndex()
    },
    query() {
      if (this.searchIndex) {
        this.searchRes = this.searchIndex.search(this.query, { expand: true })
      }
    }
  },
  created() {
    this.buildIndex()
  },
  methods: {
    ...mapActions('projects', ['deleteProject']),
    capitalizeStatus(s) {
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    showCheck(s) {
      return this.selectedStatus.includes(s)
    },
    toggleStatus(s) {
      const idx = this.selectedStatus.indexOf(s)
      if (idx === -1) {
        this.selectedStatus.push(s)
      } else {
        this.selectedStatus.splice(idx, 1)
      }
    },
    selectAll() {
      this.selectedStatus = [...this.status]
    },
    selectNone() {
      this.selectedStatus = []
    },
    buildIndex() {
      if (this.projects) {
        this.searchIndex = elasticlunr(function f() {
          this.addField('name')
          this.addField('description')
          this.addField('status')
          this.addField('topics')
          this.addField('taskforces')
          this.addField('createTimestamp')
          this.addField('leads')
          this.setRef('id')
        })

        this.searchIndex.saveDocument(false)
        const i = this.projects.map(x => {
          const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }
          return {
            name: x.name,
            description: x.description,
            status: x.status,
            taskforces: this.joinNames(x.taskforces),
            topics: this.joinNames(x.topics),
            leads: this.joinNames(x.leads),
            createTimestamp: x.createTimestamp.toLocaleDateString(
              'en-US',
              options
            ),
            id: x.id
          }
        })

        i.forEach(x => {
          this.searchIndex.addDoc(x)
        })
      }
    },
    clearQuery() {
      this.query = ''
    },
    visible(prj) {
      let inSearch = false

      if (this.query.length < 4) {
        inSearch = true
      } else {
        this.searchRes.forEach(r => {
          if (r.ref === prj.id) {
            inSearch = true
          }
        })
      }

      return inSearch && this.showCheck(prj.status)
    },
    joinNames(arr) {
      if (!arr) return ''
      const reducerFun = (acc, curr) => `${acc} ${curr.name}`

      return arr.reduce(reducerFun, '')
    }
  }
}
</script>

<style lang="scss" scoped></style>
