<template>
  <div class="flex justify-between mv2">
    <router-link
      class="flex justify-between w-100 mv2 pa2 f4 bgh-light-grey pointer db black no-underline items-center"
      :to="{ name: 'offer', params: { id: data.id, q: query } }"
    >
      <div class="">
        <div class="">{{ data.name }}</div>
        <div class="mid-gray i db f5">
          Posted by {{ postedBy }} - Expires
          {{ data.expiry.toLocaleDateString() }}
        </div>
      </div>
      <i class="las la-angle-right db f4 b dark-blue"></i>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    data: Object,
    index: Number,
    query: String
  },
  computed: {
    ...mapGetters('users', ['getUserById']),
    postedBy() {
      const res = this.getUserById(this.data.owner)
      if (res) {
        return res.name
      }

      return 'Deleted User'
    }
  }
}
</script>

<style lang="scss" scoped></style>
