export default {
  users: null,
  userNameToCreate: '',
  userBioToCreate: '',
  userNameToEdit: '',
  userBioToEdit: '',
  userEmailToEdit: '',
  userEmailToCreate: '',
  userPictureToEdit: '',
  userPictureToCreate: '',
  userUniversityToCreate: '',
  userUniversityToEdit: '',
  userTitleToCreate: '',
  userTitleToEdit: '',
  userContactToCreate: [{ type: '', contact: '', id: 0 }],
  userContactToEdit: [],
  userTopicsToCreate: [],
  userTopicsToEdit: [],
  userDeletionPending: [],
  userCreationPending: false,
  userEditPending: []
}
