import { VueEditor, Quill } from 'vue2-editor'
import MagicUrl from 'quill-magic-url'
import ImageResize from 'quill-image-resize-module'

Quill.debug('error')
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/magicUrl', MagicUrl)

export default {
  components: {
    VueEditor
  },
  data() {
    return {
      editorSettings: {
        modules: {
          imageResize: {},
          magicUrl: true
        }
      }
    }
  }
}
