import { find } from 'lodash'

export default {
  /**
   * Check if a admin has deletion pending
   */
  isAdminDeletionPending: state => adminId =>
    state.adminDeletionPending.includes(adminId),

  isAdminEditPending: state => adminId =>
    state.adminEditPending.includes(adminId),

  /**
   * Get admin by id
   */
  getAdminById: state => adminId => {
    const res = find(state.admins, admin => admin.id === adminId)

    if (res) {
      return res
    }

    return false
  }
}
