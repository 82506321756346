<template>
  <div class="mw8 center">
    <add-resource></add-resource>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddResource from '@/components/resource/AddResource'

export default {
  components: { AddResource },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('resources', ['getResourceById']),
    currentResource() {
      return this.getResourceById(this.id)
    }
  }
}
</script>
