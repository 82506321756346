<template>
  <router-link
    class="db black no-underline flex justify-between w-100 mv2 pa2 f4 bgh-light-grey pointer"
    :to="{ name: 'taskforce', params: { id: data.id, q: query } }"
  >
    <div class="">
      {{ data.name }}
    </div>
    <i class="las la-angle-right db f4 b dark-blue"></i>
  </router-link>
</template>

<script>
export default {
  props: {
    data: Object,
    index: Number,
    query: String
  }
}
</script>

<style lang="scss" scoped></style>
