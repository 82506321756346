<template>
  <router-link
    class="flex justify-between w-100 mv2 pa2 f4 bgh-light-grey pointer db black no-underline"
    :to="{ name: 'university', params: { id: data.id, q: query } }"
  >
    <div class="w-100">
      {{ data.name }} -
      <span class="mid-gray i"> {{ data.city }}, {{ data.country }}</span>
    </div>
    <i class="las la-angle-right db f4 b dark-blue"></i>
  </router-link>
</template>

<script>
export default {
  props: {
    data: Object,
    index: Number,
    query: String
  }
}
</script>

<style lang="scss" scoped></style>
