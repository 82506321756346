import state from './projects.state'
import mutations from './projects.mutations'
import actions from './projects.actions'
import getters from './projects.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
