import AdminsDB from '@/firebase/admins-db'

export default {
  /**
   * Fetch admins of current loggedin admin
   */
  getAdmins: async ({ commit }) => {
    const AdminDb = new AdminsDB()
    const admins = await AdminDb.readAll()
    commit('setAdmins', admins)
  },

  /**
   * Create a admin for current loggedin admin
   */
  createAdmin: async ({ commit }, admin) => {
    const AdminDb = new AdminsDB()

    const createdAdmin = await AdminDb.create(admin, admin.id)
    commit('addAdmin', createdAdmin)
  },

  /**
   * Delete a admin admin from its id
   */
  deleteAdmin: async ({ commit, getters }, adminId) => {
    if (getters.isAdminDeletionPending(adminId)) return

    const AdminDb = new AdminsDB()

    commit('addAdminDeletionPending', adminId)
    await AdminDb.delete(adminId)
    commit('removeAdminDeletionPending', adminId)
    commit('removeAdminById', adminId)
  }
}
