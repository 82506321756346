<template>
  <div class="taskforce-action-bar">
    <save-reminder :dirty="dirty"></save-reminder>
    <h1>Edit Task Force</h1>
    <label for="taskforce-name" class="b mv2 dib">Task Force Name</label>
    <validation-error :show="$v.localTaskforce.name.$invalid">
      Name requires 4 or more characters.
    </validation-error>
    <input
      id="taskforce-name"
      v-model.trim="localTaskforce.name"
      placeholder="task force name..."
      type="text"
      class="pa2 w-100 mb3"
      @input="setTaskforceNameToEdit($event.target.value)"
    />
    <label for="taskforce-chairs" class="b mv2 dib"
      >Task Force Chairs (Choose 1 or more)</label
    >
    <validation-error :show="$v.localTaskforceChairs.$invalid">
      Chairs cannot be empty
    </validation-error>
    <multiselect
      id="taskforce-chairs"
      v-model.trim="localTaskforceChairs"
      :options="userOptions"
      :multiple="true"
      :close-on-select="true"
      placeholder="Chairs"
      label="name"
      track-by="id"
      class="mb3"
      @input="setTaskforceChairsToEdit(localTaskforceChairs)"
    >
    </multiselect>
    <label for="taskforce-members" class="b mv2 dib"
      >Task Force Members (Choose 1 or more)</label
    >
    <validation-error :show="$v.localTaskforceMembers.$invalid">
      Members cannot be empty
    </validation-error>
    <multiselect
      id="taskforce-members"
      v-model.trim="localTaskforceMembers"
      :options="userOptions"
      :multiple="true"
      :close-on-select="true"
      placeholder="Members"
      label="name"
      track-by="id"
      class="mb3"
      @input="setTaskforceMembersToEdit(localTaskforceMembers)"
    >
    </multiselect>
    <label for="taskforce-description" class="b mv2 dib"
      >Task Force Description</label
    >
    <validation-error :show="$v.localTaskforce.description.$invalid">
      Description cannot be empty
    </validation-error>
    <vue-editor
      id="taskforce-description"
      v-model.trim="localTaskforce.description"
      :editor-options="editorSettings"
      placeholder="task force description..."
      class="taskforce-name-input"
      rows="7"
      cols="40"
      use-custom-image-handler
      @image-added="handleImageAdded"
      @input="setTaskforceDescToEdit(localTaskforce.description)"
    ></vue-editor>
    <save-changes
      :invalid="$v.$invalid"
      @save-changes="saveChanges()"
    ></save-changes>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import imageHandler from '@/mixins/imageHandler'
import { isEqual } from 'lodash'
import SaveReminder from '@/components/saveReminder'
import ValidationError from '@/components/validationError'
import SaveChanges from '@/components/saveChanges'
import quillEditor from '@/mixins/quillEditor'
import { userOptions } from '@/mixins/optionsWithUser'
import isChairUpdater from '@/mixins/isChairUpdater'

export default {
  components: {
    Multiselect,
    SaveReminder,
    ValidationError,
    SaveChanges
  },
  mixins: [imageHandler, userOptions, quillEditor, isChairUpdater],
  props: {
    taskforce: Object
  },
  data() {
    return {
      content: '',
      localTaskforce: {
        name: '',
        description: ''
      },
      localTaskforceChairs: [],
      localTaskforceMembers: []
    }
  },
  computed: {
    ...mapState('taskforces', [
      'taskforceNameToEdit',
      'taskforceDescToEdit',
      'taskforceChairsToEdit',
      'taskforceMembersToEdit',
      'taskforceEditPending'
    ]),
    dirty() {
      const tfDirty = !isEqual(this.taskforce, this.localTaskforce)
      const tfcDirty = !isEqual(
        this.taskforce.chairs,
        this.localTaskforceChairs
      )
      const tfmDirty = !isEqual(
        this.taskforce.members,
        this.localTaskforceMembers
      )
      return tfDirty || tfcDirty || tfmDirty
    }
  },
  created() {
    this.setTaskforceNameToEdit(this.taskforce.name)
    this.setTaskforceDescToEdit(this.taskforce.description)
    this.setTaskforceChairsToEdit(this.taskforce.chairs)
    this.setTaskforceMembersToEdit(this.taskforce.members)
    this.content = this.taskforce.description
    const localTaskforceChairs = this.taskforce.chairs.map(c =>
      this.getUserById(c)
    )
    const localTaskforceMembers = this.taskforce.members.map(m =>
      this.getUserById(m)
    )
    this.localTaskforceChairs = localTaskforceChairs.filter(o => o.name)
    this.localTaskforceMembers = localTaskforceMembers.filter(o => o.name)
    Object.assign(this.localTaskforce, this.taskforce)
  },

  methods: {
    ...mapMutations('taskforces', [
      'setTaskforceNameToEdit',
      'setTaskforceDescToEdit',
      'setTaskforceChairsToEdit',
      'setTaskforceMembersToEdit'
    ]),
    ...mapActions('taskforces', [
      'triggerEditTaskforceAction',
      'editTaskforce'
    ]),

    saveChanges() {
      this.localTaskforce.chairs = this.localTaskforceChairs.map(x => x.id)
      this.localTaskforce.members = this.localTaskforceMembers.map(x => x.id)
      this.editTaskforce(this.localTaskforce)
      this.checkChairs(this.localTaskforce.chairs)
      this.$router.push({
        name: 'taskforce',
        params: { id: this.taskforce.id }
      })
    }
  },
  validations: {
    localTaskforce: {
      name: {
        required,
        minLength: minLength(4)
      },
      description: {
        required
      }
    },
    localTaskforceChairs: {
      required,
      minLength: minLength(1)
    },
    localTaskforceMembers: {
      required,
      minLength: minLength(1)
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped></style>
