import ResourcesDB from '@/firebase/resources-db'

export default {
  /**
   * Fetch resources of current loggedin user
   */
  getResources: async ({ commit }) => {
    const ResourceDb = new ResourcesDB()
    const resources = await ResourceDb.readAll()
    commit('setResources', resources)
  },

  /**
   * Create a resource for current loggedin user
   */
  createResource: async ({ commit }, resource) => {
    const ResourceDb = new ResourcesDB()

    commit('setResourceCreationPending', true)
    const createdResource = await ResourceDb.create(resource)
    commit('addResource', createdResource)
    commit('setResourceCreationPending', false)
  },
  editResource: async ({ dispatch, commit }, resource) => {
    const ResourceDb = new ResourcesDB()

    commit('addResourceEditPending', resource.id)
    await ResourceDb.update(resource)
    commit('removeResourceEditPending', resource.id)
    dispatch('getResources')
  },

  /**
   * Create a new resource for current loggedin user and reset resource name input
   */
  triggerAddResourceAction: ({ dispatch, state, commit }) => {
    if (state.resourceNameToCreate === '') return
    if (state.resourceLanguageToCreate === '') return
    if (state.resourceDescToCreate === '') return
    if (state.resourceTaskforceToCreate === []) return
    if (state.resourceAudienceToCreate === '') return
    if (state.resourceTopicsToCreate === []) return
    if (state.resourceTypeToCreate === '') return

    const resource = {
      name: state.resourceNameToCreate,
      language: state.resourceLanguageToCreate,
      description: state.resourceDescToCreate,
      taskforce: state.resourceTaskforceToCreate,
      audience: state.resourceAudienceToCreate,
      topics: state.resourceTopicsToCreate,
      type: state.resourceTypeToCreate,
      approved: false
    }

    commit('setResourceNameToCreate', '')
    commit('setResourceLanguageToCreate', '')
    commit('setResourceDescToCreate', '')
    commit('setResourceTaskforceToCreate', [])
    commit('setResourceAudienceToCreate', [])
    commit('setResourceTopicsToCreate', [])
    commit('setResourceTypeToCreate', '')
    dispatch('createResource', resource)
  },

  /**
   * Delete a user resource from its id
   */
  deleteResource: async ({ commit, getters }, resourceId) => {
    if (getters.isResourceDeletionPending(resourceId)) return

    const ResourceDb = new ResourcesDB()

    commit('addResourceDeletionPending', resourceId)
    await ResourceDb.delete(resourceId)
    commit('removeResourceById', resourceId)
    commit('removeResourceDeletionPending', resourceId)
  }
}
