export default {
  /* Request input name */
  setRequestNameToCreate: (state, requestNameToCreate) =>
    (state.requestNameToCreate = requestNameToCreate),
  setRequestEmailToCreate: (state, requestEmailToCreate) =>
    (state.requestEmailToCreate = requestEmailToCreate),
  setRequestNameToEdit: (state, requestNameToEdit) =>
    (state.requestNameToEdit = requestNameToEdit),
  setRequestEmailToEdit: (state, requestEmailToEdit) =>
    (state.requestEmailToEdit = requestEmailToEdit),

  /* Requests */
  setRequests: (state, requests) => (state.requests = requests),
  addRequest: (state, request) => state.requests.push(request),
  removeRequestById: (state, requestId) => {
    const index = state.requests.findIndex(request => request.id === requestId)
    state.requests.splice(index, 1)
  },

  /* Requests deletion */
  addRequestDeletionPending: (state, requestId) =>
    state.requestDeletionPending.push(requestId),
  removeRequestDeletionPending: (state, requestId) => {
    const index = state.DeletionPending.indexOf(requestId)
    state.requestDeletionPending.splice(index, 1)
  },

  /* Requests edit */
  addRequestEditPending: (state, requestId) =>
    state.requestEditPending.push(requestId),
  removeRequestEditPending: (state, requestId) => {
    const index = state.requestEditPending.indexOf(requestId)
    state.requestEditPending.splice(index, 1)
  }
}
