<template>
  <div class="mw8 center">
    <edit-university
      v-if="currentUniversity"
      :university="currentUniversity"
    ></edit-university>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditUniversity from '@/components/university/EditUniversity'

export default {
  components: { EditUniversity },
  props: {
    id: String
  },
  computed: {
    ...mapGetters('universities', ['getUniversityById']),
    currentUniversity() {
      return this.getUniversityById(this.id)
    }
  }
}
</script>
