import UniversitiesDB from '@/firebase/universities-db'

export default {
  /**
   * Fetch universities of current loggedin user
   */
  getUniversities: async ({ commit }) => {
    const UniversityDb = new UniversitiesDB()
    const universities = await UniversityDb.readAll()
    commit('setUniversities', universities)
  },

  /**
   * Create a university for current loggedin user
   */
  createUniversity: async ({ commit }, university) => {
    const UniversityDb = new UniversitiesDB()

    commit('setUniversityCreationPending', true)
    const createdUniversity = await UniversityDb.create(university)
    commit('addUniversity', createdUniversity)
    commit('setUniversityCreationPending', false)
  },
  editUniversity: async ({ dispatch, commit }, university) => {
    const UniversityDb = new UniversitiesDB()

    commit('addUniversityEditPending', university.id)
    await UniversityDb.update(university)
    commit('removeUniversityEditPending', university.id)
    dispatch('getUniversities')
  },

  /**
   * Create a new university for current loggedin user and reset university name input
   */
  triggerAddUniversityAction: ({ dispatch, state, commit }) => {
    if (state.universityNameToCreate === '') return
    if (state.universityDescToCreate === '') return

    const university = {
      name: state.universityNameToCreate,
      description: state.universityDescToCreate,
      city: state.universityCityToCreate,
      country: state.universityCountryToCreate,
      region: state.universityRegionToCreate,
      website: state.universityWebsiteToCreate,
      topics: state.universityTopicsToCreate,
      picture: state.universityPictureToCreate,
      contact: state.universityContactToCreate.id
    }
    commit('setUniversityPictureToCreate', '')
    commit('setUniversityNameToCreate', '')
    commit('setUniversityDescToCreate', '')
    commit('setUniversityCityToCreate', '')
    commit('setUniversityCountryToCreate', '')
    commit('setUniversityRegionToCreate', '')
    commit('setUniversityWebsiteToCreate', '')
    commit('setUniversityTopicsToCreate', '')
    commit('setUniversityContactToCreate', '')
    dispatch('createUniversity', university)
  },

  /**
   * Delete a user university from its id
   */
  deleteUniversity: async ({ commit, getters }, universityId) => {
    if (getters.isUniversityDeletionPending(universityId)) return

    const UniversityDb = new UniversitiesDB()

    commit('addUniversityDeletionPending', universityId)
    await UniversityDb.delete(universityId)
    commit('removeUniversityById', universityId)
    commit('removeUniversityDeletionPending', universityId)
  }
}
